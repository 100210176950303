import {toastOneError, toastOneSuccess, toastOneWarn, updateFailureToast, updateSuccessToast} from "../../toast.js";
import {useAuthProvider, useFetchProvider} from "../../providers/provider-hooks.jsx";
import {delay, findChangedPath} from "../../utils.js";
import {useNavigate} from "react-router-dom";
import {Slide, toast} from "react-toastify";
import {useEffect, useState} from "react";
import _ from "lodash";
import {ECOTIC_ID} from "../../constants/config.js";

export default function useSchoolDetailsV2(authorized) {
    const [actionsDisabled, setActionsDisabled] = useState(false)
    const [renderFullForm, setRenderFullForm] = useState(false)
    const [dropdownData, setDropdownData] = useState([])
    const [originalData, setOriginalData] = useState({})
    const [oldFormData, setOldFormData] = useState({})
    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({})

    const {
        getOwnSchool, getDefaultSchool,
        getUnauthorizedDefaultSchoolForm, patchUnauthorizedSchoolForm,
        postUnauthorizedSchoolForm, getUnauthorizedOwnSchoolForm,
        patchSchool, getCuiCheckDataForSchool, getSchoolsReport
    } = useFetchProvider()
    const {getNewToken, refreshToken} = useAuthProvider()

    const navigate = useNavigate()

    function handleManualSIIIRCodeChange(SIIIRCode) {
        setActionsDisabled(true)
        setRenderFullForm(false)
        try {
            const newFormData = {...originalData}
            newFormData["codSiiirUnitate"] = SIIIRCode
            setFormData(newFormData)
        } catch (error) {
            console.error(error)
        } finally {
            setActionsDisabled(false)
            setRenderFullForm(true)
        }
    }

    async function handleSIIIRCodeChange(e) {
        setActionsDisabled(true)
        setRenderFullForm(false)
        const newFormData = {...originalData}
        const toastId = toast.loading("Datele scolii se incarca...", {transition: Slide})
        try {
            await delay(1000)
            const {target: {name, value}} = e
            newFormData[name] = value
            const officialData = dropdownData.find(el => el["codSiiirUnitate"] === value)

            try {
                const {content} = await getSchoolsReport({codSiiirUnitate: value})
                if (!content || !Array.isArray(content) || content.length !== 1) throw new Error("New school with no previous participation attempted to register.")
                newFormData["codSe"] = content[0]["codSe"]
                toastOneSuccess("Se pare ca scoala cu care doriti sa va inscrieti a mai participat in trecut in proiectul Scoala Ecoterrienilor")
            } catch (error) {
                console.error(error)
                toastOneWarn("Se pare ca scoala cu care doriti sa va inscrieti nu a mai participat in trecut in proiectul Scoala Ecoterrienilor")
            }
            const {
                judet, oras, comuna, sat, strada, codPostal,
                telefon, numeInstitutieInvatamant, cui, numar,
            } = officialData

            cui ? newFormData["cui"] = cui : null
            sat ? newFormData["adresa"]["sat"] = sat : null
            telefon ? newFormData["telefon"] = telefon : null
            oras ? newFormData["adresa"]["oras"] = oras : null
            numar ? newFormData["adresa"]["numar"] = numar : null
            judet ? newFormData["adresa"]["judet"] = judet : null
            comuna ? newFormData["adresa"]["comuna"] = comuna : null
            strada ? newFormData["adresa"]["strada"] = strada : null
            codPostal ? newFormData["adresa"]["codPostal"] = codPostal : null
            numeInstitutieInvatamant ? newFormData["numeInstitutieInvatamant"] = numeInstitutieInvatamant : null
            setFormData(newFormData)
            toast.dismiss(toastId)
        } catch (error) {
            console.error(error)
        } finally {
            setActionsDisabled(false)
            setRenderFullForm(true)
        }
    }

    async function resetForm() {
        setActionsDisabled(true)
        setRenderFullForm(false)
        setOriginalData({})
        setOldFormData({})
        setFormData({})
        setErrors({})
        await renderSchoolDetails(true)
    }

    async function saveSchoolForm(method) {
        setActionsDisabled(true)
        if (!method) throw new Error("School form save method param was not specified!");
        if (typeof method !== "string") throw new Error("School form save method param must be a string!");

        document.getElementById('main-scrollable-content').scrollTo({top: 0, behavior: 'smooth'});

        const id = toast.loading("Formularul se trimite...", {transition: Slide});
        try {
            await delay(1000);
            const newFormData = {...formData}
            if (newFormData["adresaLivrareAceeasiCuScoala"] === true) {
                newFormData["adresaLivrare"]["judet"] = newFormData["adresa"]["judet"]
                newFormData["adresaLivrare"]["oras"] = newFormData["adresa"]["oras"]
                newFormData["adresaLivrare"]["sat"] = newFormData["adresa"]["sat"]
                newFormData["adresaLivrare"]["numar"] = newFormData["adresa"]["numar"]
                newFormData["adresaLivrare"]["comuna"] = newFormData["adresa"]["comuna"]
                newFormData["adresaLivrare"]["strada"] = newFormData["adresa"]["strada"]
                newFormData["adresaLivrare"]["bloc"] = newFormData["adresa"]["bloc"]
                newFormData["adresaLivrare"]["scara"] = newFormData["adresa"]["scara"]
                newFormData["adresaLivrare"]["etaj"] = newFormData["adresa"]["etaj"]
                newFormData["adresaLivrare"]["apartament"] = newFormData["adresa"]["apartament"]
                newFormData["adresaLivrare"]["codPostal"] = newFormData["adresa"]["codPostal"]
            }
            newFormData["companyId"] = ECOTIC_ID
            if (authorized) {
                await patchSchool(newFormData)
            } else {
                if (method === "SAVE") await postUnauthorizedSchoolForm(newFormData);
                if (method === "UPDATE") await patchUnauthorizedSchoolForm(newFormData);
            }
            updateSuccessToast("Formularul a fost trimis", id)
            if (!authorized) {
                await getNewToken(refreshToken)
                navigate("/")
            }
        } catch (error) {
            console.log(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setErrors(error.errors);
                if (error.message) updateFailureToast(error.message, id);
                return;
            }
            updateFailureToast("Formularul nu a putut fi trimis.", id);
        } finally {
            setActionsDisabled(false)
        }
    }

    async function renderSchoolDetails(withToast = true) {
        setActionsDisabled(true)
        const loadError = "Formularul nu s-au putut incarca."
        const toastId = withToast ? toast.loading("Formularul se incarca...", {transition: Slide}) : null
        try {
            await delay(1000)
            let newUserData
            if (authorized) {
                try {
                    newUserData = await getOwnSchool()
                } catch (error) {
                    newUserData = await getDefaultSchool()
                }
            } else {
                try {
                    newUserData = await getUnauthorizedOwnSchoolForm()
                } catch (e) {
                    newUserData = await getUnauthorizedDefaultSchoolForm()
                }
            }
            const {content} = await getCuiCheckDataForSchool({size: 20000})
            if (content && Array.isArray(content) && content.length) setDropdownData(content)

            setOriginalData(newUserData)
            setOldFormData(newUserData)
            setFormData(newUserData)
            setErrors({})
            toast.dismiss(toastId)
        } catch (error) {
            toastId ? updateFailureToast(error.message || loadError, toastId) : toastOneError(loadError)
        } finally {
            setActionsDisabled(false)
        }
    }

    function handleSimpleFieldChange(e) {
        const {name, value} = e.target
        const updatedData = {...formData}
        if (name === "adresaLivrareAceeasiCuScoala" && value === false) {
            updatedData["adresaLivrare"]["judet"] = null
            updatedData["adresaLivrare"]["oras"] = null
            updatedData["adresaLivrare"]["comuna"] = null
            updatedData["adresaLivrare"]["sat"] = null
            updatedData["adresaLivrare"]["strada"] = null
            updatedData["adresaLivrare"]["numar"] = null
        }
        updatedData[name] = value
        setFormData(updatedData)
    }

    function handleComplexFieldChange(e) {
        const {name, value} = e.target
        const updatedData = {...formData}
        updatedData[name.split(".")[0]][name.split(".")[1]] = value
        setFormData(updatedData)
    }


    useEffect(() => {
        const changedPath = findChangedPath(formData, oldFormData);
        if (changedPath) {
            const errorPath = Object.keys(errors).find(errorKey => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                delete newErrors[errorPath];
                setErrors(newErrors);
            }
            setOldFormData(_.cloneDeep(formData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, errors]);

    useEffect(() => {
        renderSchoolDetails(true).then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        actionsDisabled,
        handleSimpleFieldChange, handleComplexFieldChange,
        formData, saveSchoolForm, handleManualSIIIRCodeChange,
        dropdownData, resetForm, renderFullForm,
        handleSIIIRCodeChange, errors
    }
}