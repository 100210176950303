import PropTypes from "prop-types";
import {cn} from "../../../utils.js";

export default function HeadRow({customStyles, children}) {

    return (
        <tr className={cn("transition-colors rounded-t-md", customStyles)}>
            {children}
        </tr>
    )
}
HeadRow.propTypes = {
    customStyles: PropTypes.string,
    children: PropTypes.node
};