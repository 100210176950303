import TextInputBox from "../../components/forms/final-input-boxes/text-input-box.jsx";
import {Button} from "../../components/schadcn-ui/button.jsx";
import PageWrap from "../../components/auth/page-wrap.jsx";
import useLogIn from "../../hooks/auth/use-log-in.js";
import H1 from "../../components/layout/h1.jsx";
import PropTypes from "prop-types";
import {useRoutesProvider} from "../../providers/provider-hooks.jsx";
import {useNavigate} from "react-router-dom";

export default function LogIn({forRole}) {
    const {PATHS, PAGES} = useRoutesProvider()
    const navigate = useNavigate()
    const {
        handleSubmit, handleChange, redirectToRegister,
        loading, logInData, errors
    } = useLogIn()

    return (
        <PageWrap>
            <div className="p-4 mb-4 rounded-2xl bg-light/70 shadow">
                <div className="z-10 flex flex-1 flex-col w-full justify-center max-w-md">
                    <Button type={"button"} size={"lg"} disabled={loading} className={"m-2 bg-magenta text-white"}
                            onClick={() => redirectToRegister(forRole)}>
                        Vreau sa ma inscriu in editie!
                    </Button>
                </div>
            </div>
            <div className="p-4 rounded-2xl bg-light/70 shadow">
                <div className="z-10 flex flex-1 flex-col w-full justify-center max-w-md">
                    <H1 customStyles="text-center" text="Am deja cont"/>
                    <form onSubmit={handleSubmit} className="flex w-full flex-col" autoComplete="off">
                        <TextInputBox handleChange={handleChange} name={"email"} label={'Email'}
                                      value={logInData["email"]} errors={errors}/>
                        <TextInputBox handleChange={handleChange} name={"parola"} label={'Parola'}
                                      value={logInData["parola"]} errors={errors} isPassword={true}/>
                        <Button size={"lg"} disabled={loading} className={"m-2"}>
                            Conectare!
                        </Button>
                    </form>
                </div>
            </div>
            <div className="p-4 mt-4 rounded-2xl bg-light/70 shadow">
                <div className="z-10 flex flex-1 flex-col w-full justify-center max-w-md">
                    <Button type={"button"} size={"lg"} disabled={loading} className={"m-2"}
                            onClick={() => navigate(PATHS[PAGES.HOME])}>
                        Inapoi la pagina principala
                    </Button>
                </div>
            </div>
        </PageWrap>
    )
}
LogIn.propTypes = {
    forRole: PropTypes.string.isRequired
}
