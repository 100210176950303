import SelectInputBox from "../../components/forms/final-input-boxes/select-input-box.jsx";
import TextInputBox from "../../components/forms/final-input-boxes/text-input-box.jsx";
import {useAuthProvider, useRoutesProvider} from "../../providers/provider-hooks.jsx";
import PreviewTextBox from "../../components/quizzes/creation/preview-text-box.jsx";
import {checkAnyErrorForPath, cn, retrieveErrorForPath} from "../../utils.js";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import FieldWrap from "../../components/forms/wraps/field-wrap.jsx";
import ErrorSpan from "../../components/layout/error-span.jsx";
import {Button} from "../../components/schadcn-ui/button.jsx";
import useProfile from "../../hooks/forms/use-profile.js";
import {CLASSES} from "../../constants/classes.js";
import {FaXmark} from "react-icons/fa6";
import {Fragment, useRef} from "react";

export default function Profile() {
    const fileInputRef = useRef(null);
    const {authState} = useAuthProvider()
    const {ROLES} = useRoutesProvider()
    const {
        userData, errors, hasProfileDataChanged,
        saveProfileData, handleFieldChange, removePhoto,
        actionsDisabled, handleImageUpload
    } = useProfile()

    const triggerFileInput = () => fileInputRef.current.click();


    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap>
                    <div>
                        <PreviewTextBox text={"Formular de actualizare a datelor personale"}/>
                    </div>

                    {Object.keys(userData).length > 0 && (
                        <Fragment>
                            {authState.userInfo.role === ROLES.PROFESOR && (
                                <TextInputBox name={"codProfesor"}
                                              label={"Codul cu care elevii tai isi pot crea contul"}
                                              value={userData["codProfesor"] || ""}
                                              errors={errors} handleChange={handleFieldChange} disabled={true}/>
                            )}
                            {authState.userInfo.role === ROLES.ELEV && (
                                <Fragment>
                                    <TextInputBox name={"nume"} label={"Nickname"} value={userData["nume"] || ""}
                                                  errors={errors} handleChange={handleFieldChange}/>
                                    <SelectInputBox name={"clasa"} label={"Clasa"}
                                                    display={{value: "value", keys: ["label"]}}
                                                    emptyValueLabel={"Selectati clasa in care sunteti"}
                                                    value={userData["clasa"] || ""} options={[...CLASSES]}
                                                    errors={errors} handleChange={handleFieldChange}/>
                                </Fragment>
                            )}
                            {[ROLES.PROFESOR, ROLES.ELEV].includes(authState.userInfo.role) && (
                                <Fragment>
                                    <TextInputBox name={"codSe"} label={"Codul SE al scolii tale"}
                                                  value={userData["codSe"] || ""}
                                                  errors={errors} handleChange={handleFieldChange} disabled={true}/>
                                </Fragment>
                            )}
                            {authState.userInfo.role !== ROLES.ELEV && (
                                <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                    <TextInputBox name={"nume"} label={"Nume"} value={userData["nume"] || ""}
                                                  errors={errors} handleChange={handleFieldChange}/>
                                    <TextInputBox name={"telefon"} label={"Telefon"} value={userData["telefon"] || ""}
                                                  errors={errors} handleChange={handleFieldChange}/>
                                </div>
                            )}
                            <TextInputBox name={"email"} label={"Email"} value={userData["email"] || ""}
                                          errors={errors} handleChange={handleFieldChange} disabled={true}/>
                            {authState.userInfo.role === ROLES.PROFESOR && (
                                <Fragment>
                                    {((userData["protocolDeColaborare"] && Array.isArray(userData["protocolDeColaborare"]) && userData["protocolDeColaborare"].length > 0) || checkAnyErrorForPath("protocolDeColaborare", errors)) &&
                                        <div className={"p-2 flex-col space-y-4"}>
                                            {userData["protocolDeColaborare"].map((pv, pvIdx) => (
                                                <div className={"flex items-center space-x-2"} key={pvIdx}>
                                                    <Button onClick={() => removePhoto(pvIdx)}
                                                            variant={"destructive"} disabled={actionsDisabled}>
                                                        <FaXmark/>
                                                    </Button>
                                                    <Button variant={"link"} className={"px-0 h-auto flex flex-wrap"}
                                                            onClick={() => window.open(pv, "_blank")}>
                                                        <span className={"flex flex-wrap break-all text-wrap"}>{pv}</span>
                                                    </Button>
                                                </div>
                                            ))}
                                            <ErrorSpan text={retrieveErrorForPath("protocolDeColaborare", errors)}
                                                       customStyles={cn(checkAnyErrorForPath("proceseVerbale", errors) ? "flex" : "hidden")}/>
                                        </div>
                                    }
                                    <div className={"p-2 flex flex-row justify-between space-x-4"}>
                                        <Button className={"w-full"} disabled={actionsDisabled}
                                                onClick={triggerFileInput}>
                                            Adauga fisiere pentru protocolul de colaborare
                                        </Button>
                                        <input
                                            type="file" ref={fileInputRef} className={"hidden"}
                                            accept="image/*,application/pdf"
                                            onChange={(e) => handleImageUpload(e.target.files[0])}/>
                                    </div>
                                </Fragment>
                            )}
                            <FieldWrap>
                                <div
                                    className={"flex w-full flex-col sm:justify-end sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4"}>
                                    <Button size={"lg"} onClick={saveProfileData} disabled={!hasProfileDataChanged || actionsDisabled}>
                                        Salvare modificari
                                    </Button>
                                </div>
                            </FieldWrap>
                        </Fragment>
                    )}
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    )
}