import {
    diffSourcePlugin, frontmatterPlugin, headingsPlugin, linkPlugin,
    listsPlugin, markdownShortcutPlugin, MDXEditor, quotePlugin, tablePlugin
} from "@mdxeditor/editor";
import '@mdxeditor/editor/style.css';
import PropTypes from "prop-types";
import {cn, generateUUID} from "../../../utils.js";

export default function MarkdownPreview({text}) {

    return (
        <MDXEditor disabled={true} name={name} markdown={text || ""}
                   onChange={() => true} spellCheck={false} key={generateUUID()}
                   plugins={[
                       headingsPlugin(), listsPlugin(), linkPlugin(), quotePlugin(), tablePlugin(), markdownShortcutPlugin(),
                       diffSourcePlugin({diffMarkdown: "An older version", viewMode: "rich-text",}),
                       frontmatterPlugin()
                   ]} contentEditableClassName={cn(!text ? "" : "", "p-0 markdown p-0 rounded-md font-roboto-mono")}/>
    )
}
MarkdownPreview.propTypes = {
    text: PropTypes.string,
}