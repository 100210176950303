import {
    BoldItalicUnderlineToggles, linkPlugin, MDXEditor,
    diffSourcePlugin, frontmatterPlugin, headingsPlugin,
    listsPlugin, ListsToggle, markdownShortcutPlugin,
    quotePlugin, tablePlugin, toolbarPlugin, UndoRedo
} from "@mdxeditor/editor";
import '@mdxeditor/editor/style.css';
import PropTypes from "prop-types";
import {cn} from "../../../utils.js";

export default function MarkdownEditor({name, content, changeContent, componentKey}) {

    return (
        <MDXEditor name={name} markdown={content || ""} key={componentKey}
                   onChange={changeContent} spellCheck={false}
                   plugins={[
                       headingsPlugin(), listsPlugin(), linkPlugin(), quotePlugin(), tablePlugin(), markdownShortcutPlugin(),
                       diffSourcePlugin({diffMarkdown: "An older version", viewMode: "rich-text",}),
                       frontmatterPlugin(), toolbarPlugin({
                           toolbarContents: () => (
                               <div
                                   className={"flex rounded-md justify-around overflow-x-auto self-center pt-[3px] pb-[1.5px] items-center space-x-4 bg-white w-full"}>
                                   <UndoRedo/>
                                   <BoldItalicUnderlineToggles/>
                                   <ListsToggle/>
                               </div>
                           )
                       })
                   ]}
                   contentEditableClassName={cn(!content ? "" : "", "p-0 markdown p-0 rounded-md")}/>
    )
}
MarkdownEditor.propTypes = {
    name: PropTypes.string,
    componentKey: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    changeContent: PropTypes.func.isRequired,
}