import {useFetchProvider} from "../../providers/provider-hooks.jsx";
import {toastOneError, updateFailureToast} from "../../toast.js";
import {delay, findChangedPath} from "../../utils.js";
import {Slide, toast} from "react-toastify";
import {useEffect, useState} from "react";
import _ from "lodash";

export default function useProfile() {
    const {getProfileData, patchProfileData, uploadImage} = useFetchProvider()

    const [actionsDisabled, setActionsDisabled] = useState(false)
    const [originalData, setOriginalData] = useState({})
    const [oldUserData, setOldUserData] = useState({})
    const [userData, setUserData] = useState({})
    const [errors, setErrors] = useState({})

    const hasProfileDataChanged = !(JSON.stringify(originalData) === JSON.stringify(userData))

    async function handleImageUpload(file) {
        try {
            setActionsDisabled(true)
            const uploadedPhoto = await uploadImage(file)
            setUserData(prevState => ({
                ...prevState,
                protocolDeColaborare: [...prevState.protocolDeColaborare, uploadedPhoto]
            }));
        } catch (error) {
            console.error(error)
        } finally {
            setActionsDisabled(false)
        }
    }

    function removePhoto(index) {
        setUserData(prevState => ({
            ...prevState,
            protocolDeColaborare: prevState.protocolDeColaborare.filter((_, i) => i !== index)
        }));
    }

    async function renderProfile(withToast = true) {
        setActionsDisabled(true)
        const loadError = "Detaliile profilului nu s-au putut incarca."
        const toastId = withToast ? toast.loading("Detaliile profilului se incarca...", {transition: Slide}) : null
        try {
            await delay(1000)
            const newUserData = await getProfileData()
            setOriginalData(newUserData)
            setOldUserData(newUserData)
            setUserData(newUserData)
            setErrors({})
            toast.dismiss(toastId)
        } catch (error) {
            toastId ? updateFailureToast(error.message || loadError, toastId) : toastOneError(loadError)
        } finally {
            setActionsDisabled(false)
        }
    }

    function handleFieldChange(e) {
        const {name, value} = e.target
        const updatedData = {...userData}
        updatedData[name] = value
        setUserData(updatedData)
    }

    async function saveProfileData () {
        setActionsDisabled(true)
        const loadError = "Detaliile profilului nu s-au putut actualiza."
        const id = toast.loading("Detaliile profilului se salveaza...", {transition: Slide})
        try {
            await delay(1000)
            await patchProfileData(userData)
            await renderProfile(false)
            toast.dismiss(id)
        } catch (error) {
            console.log(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setErrors(error.errors);
                if (error.message) updateFailureToast(error.message, id);
                return;
            }
            updateFailureToast(loadError, id);
        } finally {
            setActionsDisabled(false)
        }
    }

    useEffect(() => {
        const changedPath = findChangedPath(userData, oldUserData);
        if (changedPath) {
            const errorPath = Object.keys(errors).find(errorKey => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                delete newErrors[errorPath];
                setErrors(newErrors);
            }
            setOldUserData(_.cloneDeep(userData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData, errors]);

    useEffect(() => {
        renderProfile(true).then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        userData, errors, hasProfileDataChanged, saveProfileData,
        handleFieldChange, actionsDisabled, removePhoto, handleImageUpload
    }
}