import {useState, useEffect} from 'react';

export default function useIsMobileViewPort() {
    const [isMobileViewPort, setIsMobileViewPort] = useState(window.innerWidth < 640);

    useEffect(() => {
        const handleResize = () => setIsMobileViewPort(window.innerWidth < 640);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return {isMobileViewPort};
}