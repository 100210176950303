import useTextAreaResize from "../../../../hooks/use-text-area-resize.js";
import TextareaInput from "../../../forms/raw-inputs/textarea-input.jsx";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import {cn, getQuizPlaceholder} from "../../../../utils.js";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import ErrorSpan from "../../../layout/error-span.jsx";
import PreviewTextBox from "../preview-text-box.jsx";
import {FaImage} from "react-icons/fa6";
import QuizWrap from "../quiz-wrap.jsx";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import {useEffect} from "react";

export default function SettingsSlide({
                                          formData, isInPreview, changeLessonTitle, checkAnyErrorForPath,
                                          changeLessonDescription, retrieveErrorForPath, quizType,
                                          changeLessonImage
                                      }) {
    const {ref, resizeTextarea} = useTextAreaResize()

    const handleImageClick = () => document.getElementById("question-image-upload").click();


    useEffect(() => {
        if (!isInPreview) resizeTextarea(ref)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.title, isInPreview])

    return (
        <QuizWrap>
            <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 h-full justify-center"}>
                <div className={"flex flex-col col-span-1 h-full justify-center"}>
                    <FieldWrap customStyles={cn("")}>
                        <InputWrap customStyles={cn(checkAnyErrorForPath("title") ? "border-red-600" : "", "pb-2")}>
                            {!isInPreview && (
                                <TextareaInput name={`title-${formData.id}`} value={formData.title || ""} ref={ref}
                                               handleChange={(e) => changeLessonTitle(e.target.value)}
                                               label={`Denumirea ${getQuizPlaceholder(quizType, 1)} (Obligatoriu)`}
                                               customStyles={formData.title ? "font-black" : "sr-only"}/>
                            )}
                            {isInPreview && <PreviewTextBox text={formData.title}/>}
                            <ErrorSpan text={retrieveErrorForPath("title")}
                                       customStyles={cn(checkAnyErrorForPath("title") ? "flex" : "hidden")}/>
                        </InputWrap>
                    </FieldWrap>

                    <FieldWrap customStyles={""}>
                        <InputWrap customStyles={cn(checkAnyErrorForPath("description") ? "border-red-600" : "", "pb-2")}>
                            {!isInPreview && (
                                <TextareaInput name={`description-${formData.id}`} value={formData.description || ""}
                                               rows={6} handleChange={(e) => changeLessonDescription(e.target.value)}
                                               label={`Descrierea ${getQuizPlaceholder(quizType, 1)} (Obligatoriu)`}
                                               customStyles={formData.description ? "font-black" : "sr-only"}/>
                            )}
                            {isInPreview && <PreviewTextBox text={formData.description}/>}
                            <ErrorSpan text={retrieveErrorForPath("description")}
                                       customStyles={cn(checkAnyErrorForPath("description") ? "flex" : "hidden")}/>
                        </InputWrap>
                    </FieldWrap>
                </div>
                <div className={"col-span-1 flex flex-col w-full h-full justify-start sm:justify-center"}>
                    <FieldWrap customStyles={"min-w-full"}>
                        <InputWrap
                            customStyles={cn(checkAnyErrorForPath(`image`) ? "border-red-600" : "", "bg-white flex items-center justify-center w-full", "px-0")}>
                            {!isInPreview && (
                                <div onClick={handleImageClick}
                                     className={cn(checkAnyErrorForPath(`image`) ? "rounded-t-[5px]" : "rounded-[5px]", "group relative cursor-pointer flex flex-1 flex-col items-center justify-center w-full text-magenta/80 hover:bg-magenta/80 hover:text-white transition duration-500 text-center")}>
                                    <Dropzone
                                        onDrop={async (acceptedFiles) => await changeLessonImage(acceptedFiles[0])}>
                                        {({getRootProps, getInputProps}) => (
                                            <div className="dz-message needsclick;" {...getRootProps()}>
                                                <input id="question-image-upload" {...getInputProps()} />
                                            </div>
                                        )}
                                    </Dropzone>
                                    {formData.image ? (
                                        <img src={formData.image} alt="Selected File"
                                             className={cn(checkAnyErrorForPath(`image`) ? "rounded-t-[5px]" : "rounded-[5px]", "flex object-cover cursor-pointer w-full")}/>
                                    ) : (
                                        <div className={"flex flex-col space-x-4 p-4 items-center"}>
                                            <FaImage style={{fontSize: "2.3em"}}/>
                                            <p className="m-0 text-lg text-gray-400 font-semibold group-hover:text-white transition duration-500">
                                                Atasati o poza acestei intrebari?
                                            </p>
                                        </div>
                                    )}
                                </div>
                            )}
                            {isInPreview && formData.image && (
                                <img src={formData.image} alt="Imagine incarcata"
                                     className={cn(checkAnyErrorForPath(`image`) ? "rounded-t-[8.1px]" : "rounded-[8.1px]", "object-contain aspect-[16/9] flex")}/>
                            )}
                            <ErrorSpan text={retrieveErrorForPath(`image`)}
                                       customStyles={cn(checkAnyErrorForPath(`image`) ? "flex" : "hidden")}/>
                        </InputWrap>
                    </FieldWrap>
                </div>
            </div>
        </QuizWrap>
    )
}
SettingsSlide.propTypes = {
    quizType: PropTypes.string.isRequired,
    formData: PropTypes.object.isRequired,
    isInPreview: PropTypes.bool.isRequired,
    changeLessonImage: PropTypes.func.isRequired,
    changeLessonTitle: PropTypes.func.isRequired,
    checkAnyErrorForPath: PropTypes.func.isRequired,
    retrieveErrorForPath: PropTypes.func.isRequired,
    changeLessonDescription: PropTypes.func.isRequired,
}