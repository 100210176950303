import {useAuthProvider, useRoutesProvider} from "../../../providers/provider-hooks.jsx";
import AuthorizedButtons from "./authorized-buttons.jsx";
import TeacherButtons from "./teacher-buttons.jsx";
import PupilButtons from "./pupil-buttons.jsx";
import {cn} from "../../../utils.js";
import PropTypes from "prop-types";

export const QUIZ_START_TYPES = Object.freeze({
    PREVIEW: "PREVIEW",
    NEW: "NEW",
    OLD: "OLD"
})

export default function QuizBox({quiz, redirectToQuiz, startQuizCompletion, changeQuizVisibility}) {
    const {authState} = useAuthProvider()
    const {ROLES} = useRoutesProvider()

    return (
        <div
            className={cn(quiz.isHidden === true ? [ROLES.DBEST, ROLES.ADMIN].includes(authState.userInfo.role) ? "opacity-60" : "hidden" : "visible", "flex justify-center")}>
            <div className={"max-w-sm w-full bg-white border border-dark rounded-lg shadow"}>
                <img className="rounded-t-lg min-h-[220px] max-h-[220px] w-full object-cover"
                     src={quiz.image || "/quiz-bg.jpg"} alt=""/>
                <div className="p-5 flex-col justify-around">
                    <div>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                            {quiz.title}
                        </h5>
                    </div>
                    <div className={"flex flex-col"}>
                        <p className="mb-3 font-normal text-gray-700 min-h-[72px] max-h-[72px] line-clamp-3">
                            {quiz.description}
                        </p>
                        <div className={"flex flex-col w-full"}>
                            <AuthorizedButtons quiz={quiz} redirectToQuiz={redirectToQuiz}
                                               changeQuizVisibility={changeQuizVisibility}
                                               startQuizCompletion={startQuizCompletion}/>
                            <TeacherButtons quiz={quiz} redirectToQuiz={redirectToQuiz}
                                            startQuizCompletion={startQuizCompletion}/>
                            <PupilButtons quiz={quiz} redirectToQuiz={redirectToQuiz}
                                          startQuizCompletion={startQuizCompletion}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
QuizBox.propTypes = {
    quiz: PropTypes.object.isRequired,
    redirectToQuiz: PropTypes.func.isRequired,
    startQuizCompletion: PropTypes.func.isRequired,
    changeQuizVisibility: PropTypes.func.isRequired
}

