export const ENVIRONMENTS = Object.freeze({
    PRODUCTION: "PRODUCTION",
    DEVELOPMENT: "DEVELOPMENT"
})
export const ACTUAL_ENVIRONMENT = Object.freeze(ENVIRONMENTS.PRODUCTION)
export const PRODUCTION_BACKEND_URL = Object.freeze("https://backend-ecotic.azurewebsites.net/api")
export const LOCAL_BACKEND_URL = Object.freeze("https://backend-ecotic-dev.azurewebsites.net/api");
export const BASE_FETCH_URL = Object.freeze(ACTUAL_ENVIRONMENT === ENVIRONMENTS.PRODUCTION ? PRODUCTION_BACKEND_URL : ACTUAL_ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT ? LOCAL_BACKEND_URL : null)
export const ECOTIC_TOKEN_KEY = Object.freeze("ecotic-token")
export const ECOTIC_REFRESH_TOKEN_KEY = Object.freeze("ecotic-refresh-token")

export const ECOTIC_ID = Object.freeze("ec410f81-05ea-4c89-8961-7e473a30c89d")