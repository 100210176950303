import FiltersResetButton from "../../components/reports/tables/filters-reset-button.jsx";
import {useNotificationsReport} from "../../hooks/reports/use-notifications-report.js";
import RefreshButton from "../../components/reports/tables/refresh-button.jsx";
import ExportButton from "../../components/reports/tables/export-button.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import {useFinalReport} from "../../hooks/reports/use-final-report.js";
import TableHeaders from "../../components/reports/final/table-headers.jsx";
import Pagination from "../../components/reports/tables/pagination.jsx";
import TableBody from "../../components/reports/final/table-body.jsx";
import ReportDetails from "../../components/reports/tables/report-details.jsx";

export default function Final() {
    const {
        tableData, printRef, resetFilters,
        renderFinalReport, pagination, actionsDisabled,
        params, changeParams, exportTable, currentEdition
    } = useFinalReport()

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap customStyles={"pr-0 rounded-none overflow-hidden"}>
                    <div
                        className={"pb-4 w-full flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 lg:justify-end lg:items-end"}>
                        <ReportDetails pagination={pagination}/>
                        <FiltersResetButton handleRefresh={resetFilters}
                                            disabled={Object.keys(params).length <= 1 || actionsDisabled}/>
                        <RefreshButton handleRefresh={renderFinalReport} disabled={actionsDisabled}/>
                        <ExportButton handleExport={exportTable} disabled={actionsDisabled}/>
                    </div>
                    <div className="relative flex-1 pr-4 overflow-auto">
                        <div className={"absolute top-0 left-0 w-full"}>
                            <table ref={printRef} className="w-full h-full relative">
                                <TableHeaders actionsDisabled={actionsDisabled}
                                              changeParams={changeParams} params={params}/>
                                <TableBody tableData={tableData} currentEdition={currentEdition}/>
                            </table>
                        </div>
                    </div>
                    <Pagination pagination={pagination} changeParams={changeParams}
                                actionsDisabled={actionsDisabled} tableData={tableData}/>
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    )
}