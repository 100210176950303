import {FaImage} from "react-icons/fa6";
import Dropzone from 'react-dropzone';
import PropTypes from "prop-types";
import {cn} from "../../../../../utils.js";

export default function ImageUpload({changeSlideImage, slide, slideIdx, checkAnyErrorForPath}) {
    const handleImageClick = () => document.getElementById("question-image-upload").click();

    return (
        <div onClick={handleImageClick} className={cn(checkAnyErrorForPath(`slides[${slideIdx}].image`) ? "rounded-t-[5px]" : "rounded-[5px]", "group relative cursor-pointer flex flex-1 flex-col items-center justify-center w-full h-full text-magenta/80 hover:bg-magenta/80 hover:text-white transition duration-500 text-center")}>
            <Dropzone onDrop={(acceptedFiles) => changeSlideImage(acceptedFiles[0])}>
                {({getRootProps, getInputProps}) => (
                    <div className="dz-message needsclick;" {...getRootProps()}>
                        <input id="question-image-upload" {...getInputProps()} />
                    </div>
                )}
            </Dropzone>
            {slide.image ? (
                <img src={slide.image} alt="Selected File" className={cn(checkAnyErrorForPath(`slides[${slideIdx}].image`) ? "rounded-t-[5px]" : "rounded-[5px]", "flex object-contain aspect-[16/9]  cursor-pointer w-full h-full")}/>
            ) : (
                <div className={"flex flex-col space-x-4 p-4 items-center"}>
                    <FaImage style={{fontSize: "2.3em"}}/>
                    <p className="m-0 text-lg text-gray-400 font-semibold group-hover:text-white transition duration-500">
                        Atasati o poza acestei intrebari?
                    </p>
                </div>
            )}
        </div>
    );
}

ImageUpload.propTypes = {
    checkAnyErrorForPath: PropTypes.func.isRequired,
    changeSlideImage: PropTypes.func.isRequired,
    slide: PropTypes.object.isRequired,
    slideIdx: PropTypes.number.isRequired,
};
