import {SLIDE_TYPES} from "../../../../pages/quizzes/quiz-creation.jsx";
import PreviewSlideBox from "./preview-slide-box.jsx";
import {cn} from "../../../../utils.js";
import PropTypes from "prop-types";
import {useMemo} from "react";

export default function PreviewSlidesBar({quizForm, quizAnswers, currentSlide, isQuizFinished}) {
    const sortedSlides = useMemo(() => JSON.parse(JSON.stringify(quizForm)).slides.sort((a, b) => a.number - b.number), [quizForm]);

    return (
        <div
            style={{backgroundSize: "400px"}}
            className={"mt-4 sm:mt-0 sm:mr-4 min-h-[64px] max-h-[64px] sm:max-h-full sm:min-h-full w-full sm:min-w-[64px] sm:max-w-[64px] bg-contain bg-center bg-repeat bg-[url('/bg-banner.jpg')] rounded-xl border border-lime-700"}>
            <div className={"w-full h-full rounded-xl bg-white bg-opacity-70 bg-clip-border px-2 pb-2"}>
                <div
                    className={"w-full h-full flex flex-row sm:flex-col space-x-2 pt-2 sm:space-x-0 sm:space-y-2 overflow-x-scroll pb-2 sm:overflow-x-hidden sm:overflow-y-scroll sm:pr-2 sm:pb-0"}>
                    <PreviewSlideBox content={"☆"}
                                     customStyles={cn((![undefined, null].includes(currentSlide) && !isQuizFinished) || currentSlide === null && isQuizFinished ? "bg-blue-300 border-blue-500" : "border-dark")}/>
                    {sortedSlides.map((slide, index) => (
                        <PreviewSlideBox key={index} content={(index + 1).toString()}
                                         withClock={slide.type === SLIDE_TYPES.QUESTION}
                                         customStyles={cn(currentSlide === slide.id ? "border-dark" : "", quizAnswers.find(answer => answer.slideId === slide.id) ? "bg-blue-300 border-blue-500" : "")}/>
                    ))}
                    <PreviewSlideBox content={"★"}
                                     customStyles={cn(currentSlide === null && isQuizFinished ? "bg-blue-300 border-blue-500" : "")}/>
                </div>
            </div>
        </div>
    );
}

PreviewSlidesBar.propTypes = {
    currentSlide: PropTypes.string,
    isQuizFinished: PropTypes.bool,
    quizForm: PropTypes.object.isRequired,
    quizAnswers: PropTypes.array.isRequired,
};
