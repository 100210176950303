import PreviewTitle from "./preview-title.jsx";
import PreviewWrap from "./preview-wrap.jsx";
import {FaPlay} from "react-icons/fa6";
import PropTypes from "prop-types";

export default function VideoPreview({selectSlide, selectedSlide, slide, checkAnyErrorForPath, slideIdx}) {

    return (
        <PreviewWrap selectSlide={selectSlide} selectedSlide={selectedSlide} slide={slide}
                     checkAnyErrorForPath={checkAnyErrorForPath} slideIdx={slideIdx}>
            <PreviewTitle title={slide.text} titlePlaceholder={"Prezentare"}/>
            <div className="h-full"/>
            <div className="flex justify-center items-center h-full p-1 w-full self-end">
                <div className="flex items-center justify-center w-[45px] h-[27px] bg-red-500 rounded-md">
                    <FaPlay className={"text-white"}/>
                </div>
            </div>
        </PreviewWrap>
    )
}
VideoPreview.propTypes = {
    checkAnyErrorForPath: PropTypes.func.isRequired,
    selectSlide: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    slide: PropTypes.object.isRequired,
    slideIdx: PropTypes.number.isRequired
}