import {useFetchProvider} from "../../providers/provider-hooks.jsx";
import useTableParams from "./use-table-params.js";
import {updateFailureToast} from "../../toast.js";
import {useEffect, useRef, useState} from "react";
import {Slide, toast} from "react-toastify";
import {delay} from "../../utils.js";

export function useBigSchoolsReport() {
    const [actionsDisabled, setActionsDisabled] = useState(false)
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({})


    const {params, changeParams, setParams} = useTableParams(renderBigSchoolsReport, {page: 0})
    const printRef = useRef(null)
    const {getCuiCheckDataForSchool} = useFetchProvider()

    async function renderBigSchoolsReport(freshParams) {
        setActionsDisabled(true)
        let errorMessage = "Tabelul nu s-a putut incarca"
        const id = toast.loading("Tabelul se incarca...", {transition: Slide})

        try {
            await delay(1000)
            const data = await getCuiCheckDataForSchool(freshParams || params)
            setTableData(data.content)
            setPagination({totalPages: data.totalPages, totalElements:data.totalElements, ...data["pageable"], first: data.first, last: data.last})
            toast.dismiss(id)
        } catch (error) {
            console.error(error)
            updateFailureToast(error.message || errorMessage, id)
        } finally {
            setActionsDisabled(false)
        }
    }

    async function resetFilters() {
        setParams({page: params.page});
        await renderBigSchoolsReport({page: params.page})
    }

    useEffect(() => {
        renderBigSchoolsReport().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        params, changeParams, resetFilters, tableData, printRef,
        actionsDisabled, renderBigSchoolsReport, pagination
    }
}