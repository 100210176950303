import SignatureInputBox from "../../components/forms/final-input-boxes/signature-input-box.jsx";
import BooleanInputBox from "../../components/forms/final-input-boxes/boolean-input-box.jsx";
import PreviewTextBox from "../../components/quizzes/creation/preview-text-box.jsx";
import {useParentalApproval} from "../../hooks/forms/use-parental-approval.js";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import {Button} from "../../components/schadcn-ui/button.jsx";

export default function ParentalApproval() {
    const {
        actionsDisabled, saveParentalData,
        parentalData, handleChange, errors,
    } = useParentalApproval()

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap withoutNav={true} withoutSideMenu={true}>
                <BgContentWrap>
                    <div><PreviewTextBox text={"Formular de colectare al acordului parental"}/></div>
                    <BooleanInputBox handleChange={handleChange} errors={errors}
                                     name={"parentApproval"}
                                     label={"In calitate de tutore al elevului, imi dau aprobarea pentru participarea acestuia in cadrul programului educational Scoala Ecoterrienilor."}
                                     value={parentalData["parentApproval"]}/>

                    <BooleanInputBox handleChange={handleChange} errors={errors}
                                     name={"prinPrezentaConfirmamInscriereaMinorului"}
                                     label={"Prin prezenta confirmam înscrierea minorului in Campania „Scoala Ecoterrienilor” si acceptarea deplina si necondiționata a Regulamentului disponibil pe www.ecotic.ro."}
                                     value={parentalData["prinPrezentaConfirmamInscriereaMinorului"]}/>

                    <BooleanInputBox handleChange={handleChange} errors={errors}
                                     name={"ecoticPoateFolosiImagini"}
                                     label={"ECOTIC poate folosi imagini in care pot aparea participantii in urmatoarele scopuri: ca dovada ca s-au realizat activitatile propuse in Concurs, pentru promovarea rezultatelor Concursului pe site-ul ECOTIC si/sau paginile de social media ale ECOTIC, pentru a fi incluse in materiale de prezentare a activitatii ECOTIC (tip retrospectiva, newsletter etc.). Cu toate acestea, înțeleg că Organizatorii recomandă transmiterea de imagini fără identificarea facială a persoanelor."}
                                     value={parentalData["ecoticPoateFolosiImagini"]}/>

                    <BooleanInputBox handleChange={handleChange} errors={errors}
                                     name={"amFostInformati"}
                                     label={"Am fost informați despre modul de desfășurare al campaniei, procedura de colectare si regulamentul campaniei si suntem de acord cu toate acestea. Confirmăm ca ni s-a adus la cunoștință și acceptăm posibilitatea modificării, suspendării sau încetării Campaniei care ni se aplică, caz în care aceste sunt postate pe www.ecotic.ro."}
                                     value={parentalData["amFostInformati"]}/>

                    <BooleanInputBox handleChange={handleChange} errors={errors}
                                     name={"prinPrezentaConfirmCaAmCititInformarea"}
                                     label={"Prin prezenta confirm că am citit Informarea (Notă de informare Școala Ecoterrienilor - https://www.ecotic.ro/informare-scoala-ecoterrienilor/ și Notă de informare înscriere elevi în Școala Ecoterrienilor - https://www.ecotic.ro/nota-de-informare-inscriere-elevi-in-scoala-ecoterrienilor-2/) cu privire la prelucrarea datelor mele cu caracter personal și înțeleg ca prelucrarea datelor mele personale (email) este strict necesara pentru înscrierea si participarea la competiție, conform Regulamentului, care este acordul părților (eu, pe de o parte, ECOTIC si ECOTIC BAT, pe de alta parte). Înțeleg că, în cazul în care nu doresc ca ECOTIC sa îmi prelucreze datele cu caracter personal, pot sa nu bifez acest câmp, iar acest formular nu va transmite datele mele către ASOCIATIA ECOTIC si ECOTIC BAT și nu voi putea fi înscris în competiția Școala Ecoterrienilor, relația contractuala dintre părți neputându-se încheia."}
                                     value={parentalData["prinPrezentaConfirmCaAmCititInformarea"]}/>

                    <BooleanInputBox handleChange={handleChange} errors={errors}
                                     name={"confirmCaInCazulInCareProiectul"}
                                     label={"Confirm că în cazul în care proiectul înscris include imagini cu persoane (majore sau minore), acestea si-au dat consimțământul expres, direct sau prin reprezentantul legal, pentru utilizarea imaginilor de către Organizator."}
                                     value={parentalData["confirmCaInCazulInCareProiectul"]}/>

                    <BooleanInputBox handleChange={handleChange} errors={errors}
                                     name={"consimtPentruPastrareaDatelorPersonale"}
                                     label={"Consimt pentru păstrarea datelor personale ale minorului in baza de date a ASOCIATIEI ECOTIC pentru o perioada de maxim 5 ani cu scopul de a primi materiale informative pe tema protecției mediului si invitație de înscriere la ediția următoare a proiectului sau la alte proiecte pe tema protecției mediului."}
                                     value={parentalData["consimtPentruPastrareaDatelorPersonale"]}/>

                    <BooleanInputBox handleChange={handleChange} errors={errors}
                                     name={"amCititSiSuntDeAcord"}
                                     label={"Am citit și sunt de acord cu Nota de Informare - https://www.ecotic.ro/nota-de-informare-inscriere-elevi-in-scoala-ecoterrienilor-2/ și Regulamentul Campaniei - https://www.ecotic.ro/wp-content/uploads/2024/09/REGULAMENTUL-OFICIAL-SCOALA-ECOTERRIENILOR-2024-2025.pdf"}
                                     value={parentalData["amCititSiSuntDeAcord"]}/>

                    <SignatureInputBox label={"Semnatura parintelui (dupa completare, apasati bifa verde)"}
                                       handleChange={handleChange} name={"signature"}
                                       value={parentalData["signature"]} errors={errors}/>
                    <Button size={"lg"} disabled={actionsDisabled} className={"m-2"}
                            onClick={() => saveParentalData()}>
                        Salvare
                    </Button>
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    )
}