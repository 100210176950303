import {ACTUAL_ENVIRONMENT, ENVIRONMENTS} from "./constants/config.js";
import {RoutesProvider} from "./providers/routes-provider.jsx";
import {InboxProvider} from "./providers/inbox-provider.jsx";
import {FetchProvider} from "./providers/fetch-provider.jsx";
import {AuthProvider} from "./providers/auth-provider.jsx";
import {BrowserRouter} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import * as Sentry from "@sentry/react";
import ReactDOM from 'react-dom/client';
import App from "./app.jsx";
import './main.css';

const allowedDomains = [
    "scoalaecoterrienilor.ecotic.ro",
    "thankful-desert-0d01ab203.5.azurestaticapps.net"
];
if (allowedDomains.find(domain => (window.location.hostname).includes(domain)) && ACTUAL_ENVIRONMENT === ENVIRONMENTS.PRODUCTION) {
    Sentry.init({
        dsn: "https://62af4e56a07f2d19ef29a9ab69e7ce36@o4507971926425600.ingest.de.sentry.io/4507971932192848",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.browserProfilingIntegration(),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
            "scoalaecoterrienilor.ecotic.ro",
            "thankful-desert-0d01ab203.5.azurestaticapps.net"
        ],
        profilesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <RoutesProvider>
            <AuthProvider>
                <FetchProvider>
                    <InboxProvider>
                        <App/>
                        <ToastContainer style={{pointerEvents: 'auto'}}/>
                    </InboxProvider>
                </FetchProvider>
            </AuthProvider>
        </RoutesProvider>
    </BrowserRouter>
);