import PropTypes from "prop-types";
import {cn} from "../../utils.js";

export default function BgContentWrap({children, customStyles}) {

    return (
        <main style={{backgroundSize: "450px"}} className="flex rounded-md border border-lime-700 flex-grow space-y-4 w-full bg-light bg-contain bg-center bg-repeat bg-[url('/bg-banner.jpg')]">
            <div className={"rounded-md h-full bg-white bg-opacity-40 w-full p-4"}>
                <div id={"main-scrollable-content"} className={cn("rounded-md h-full w-full flex flex-col overflow-y-scroll pr-4", customStyles)}>
                    {children}
                </div>
            </div>
        </main>
    )
}
BgContentWrap.propTypes = {
    children: PropTypes.node.isRequired,
    customStyles: PropTypes.string
}