import {useFetchProvider} from "../../providers/provider-hooks.jsx";
import {updateFailureToast, updateSuccessToast} from "../../toast.js";
import {delay, findChangedPath, generateFutureDate, setValueAtPath} from "../../utils.js";
import {Slide, toast} from "react-toastify";
import {useEffect, useState} from "react";
import _ from "lodash";

export default function useWasteCollectionForm() {
    const [ownSchoolData, setOwnSchoolData] = useState({})
    const [oldFormData, setOldFormData] = useState({})
    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({})
    const {getDefaultWasteForm, postWasteForm, uploadImage, getOwnSchool} = useFetchProvider()

    async function renderSchools() {
        try {
            const newSchoolData = await getOwnSchool()
            setOwnSchoolData(newSchoolData)
        } catch (error) {
            console.error(error)
        }
    }

    const handleDZClick = () => document.getElementById("question-image-upload").click();

    async function renderWasteForm() {
        const id = toast.loading(`Formularul se incarca...`, {transition: Slide})
        try {
            await delay(1000)
            setFormData({...await getDefaultWasteForm(),dataOrientativaColectare:generateFutureDate(15)})
            setErrors({})
            return toast.dismiss(id)
        } catch (error) {
            console.error(error)
            updateFailureToast(error.message || "Formularul nu s-a putut incarca.", id)
            await delay(1000)
        }
    }

    function handleStandardFieldChange(e) {
        const {name, value} = e.target
        const updatedData = {...formData}
        if (name === "pickupAddressSameWithSchoolAddress" && value === true) {
            updatedData["pickupAddress"]["judet"] = ownSchoolData["adresa"]["judet"]
            updatedData["pickupAddress"]["oras"] = ownSchoolData["adresa"]["oras"]
            updatedData["pickupAddress"]["comuna"] = ownSchoolData["adresa"]["comuna"]
            updatedData["pickupAddress"]["sat"] = ownSchoolData["adresa"]["sat"]
            updatedData["pickupAddress"]["strada"] = ownSchoolData["adresa"]["strada"]
            updatedData["pickupAddress"]["numar"] = ownSchoolData["adresa"]["numar"]
            updatedData["pickupAddress"]["bloc"] = ownSchoolData["adresa"]["bloc"]
            updatedData["pickupAddress"]["scara"] = ownSchoolData["adresa"]["scara"]
            updatedData["pickupAddress"]["etaj"] = ownSchoolData["adresa"]["etaj"]
            updatedData["pickupAddress"]["codPostal"] = ownSchoolData["adresa"]["codPostal"]
        }
        updatedData[name] = value
        setFormData(updatedData)
    }

    function handleAddressFieldChange(e) {
        const {name, value} = e.target;
        const updatedData = {...formData};
        setValueAtPath(updatedData, name, value)
        setFormData(updatedData)
    }

    const handleImageUpload = async (acceptedFiles) => {
        const uploadedPhotos = await Promise.all(
            acceptedFiles.map(async (file) => {
                return uploadImage(file);
            })
        );

        setFormData(prevState => ({
            ...prevState,
            photos: prevState.photos && Array.isArray(prevState.photos) ? [...prevState.photos, ...uploadedPhotos] : [...uploadedPhotos]
        }));
    };

    const removePhoto = (index) => {
        setFormData(prevState => ({
            ...prevState,
            photos: prevState.photos.filter((_, i) => i !== index)
        }));
    };

    async function saveWasteCollectionForm() {
       document.getElementById('main-scrollable-content').scrollTo({top: 0, behavior: 'smooth'});
        const id = toast.loading("Formularul se trimite...", {transition: Slide});

        try {
            await delay(1000);
            await postWasteForm(formData);
            updateSuccessToast("Formularul a fost trimis", id)
            await renderWasteForm()
        } catch (error) {
            console.log(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setErrors(error.errors);
                if (error.message) updateFailureToast(error.message, id);
                return;
            }
            updateFailureToast("Formularul nu a putut fi trimis.", id);
        }
    }

    useEffect(() => {
        const changedPath = findChangedPath(formData, oldFormData);
        if (changedPath) {
            const errorPath = Object.keys(errors).find(errorKey => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                delete newErrors[errorPath];
                setErrors(newErrors);
            }
            setOldFormData(_.cloneDeep(formData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, errors]);

    useEffect(() => {
        renderWasteForm().then()
        renderSchools().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        renderWasteForm, handleStandardFieldChange, handleDZClick,
        handleImageUpload, errors, formData, saveWasteCollectionForm,
        handleAddressFieldChange, removePhoto
    }
}