import {useAuthProvider, useRoutesProvider} from "../../providers/provider-hooks.jsx";
import useIsDesktopViewPort from "../../hooks/use-is-desktop-viewport.js";
import SideLink from "./side-link.jsx";
import PropTypes from "prop-types";
import {cn} from "../../utils.js";
import {Link} from "react-router-dom";

export default function SideMenu({navVisible, closeNav}) {
    const {isDesktopViewPort} = useIsDesktopViewPort();
    const {PROFILE_LINKS, ROLES} = useRoutesProvider();
    const {authState} = useAuthProvider()
    const {role} = authState.userInfo

    return (
        <div className={"h-full"}>
            <div className={cn(!isDesktopViewPort ? "" : "pr-2", "flex h-full flex-col space-y-4")}>
                <div className="flex h-full flex-col space-y-2 text-small pr-4 overflow-y-scroll">
                    {PROFILE_LINKS.map((LINK, idx) => {
                        if (LINK.ALLOWED_ROLES.includes(role))
                            return <SideLink
                            key={idx} path={LINK.PATH} idx={idx} closeNav={closeNav}
                            displayName={LINK.TEXT === "Raport Conturi" && role === ROLES.PROFESOR ? "Elevii mei" : LINK.TEXT} navVisible={navVisible}/>
                    })}
                </div>
                <Link to={"/"}
                      className="flex items-center justify-center min-w-[140px] md:min-w-[160px] lg:min-w-[190px]">
                    <img src="/logo-ecotic.png" alt="ECOTIC logo" className="max-h-[75px] h-full"/>
                </Link>
            </div>
        </div>
    );
}
SideMenu.propTypes = {
    navVisible: PropTypes.bool.isRequired,
    closeNav: PropTypes.func.isRequired,
};
