import MarkdownPreview from "../../../forms/raw-inputs/preview-markdown.jsx";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import {Button} from "../../../schadcn-ui/button.jsx";
import {cn} from "../../../../utils.js";
import PropTypes from "prop-types";
import {useMemo} from "react";

export default function InfoSlide({quizForm, currentSlide, saveAndMoveToAvailableSlide, actionsDisabled}) {
    const slide = useMemo(() => quizForm.slides.find(slide => slide.id === currentSlide), [currentSlide, quizForm]);

    return (
        <div className={"w-full h-full flex flex-col items-center"}>
            <div className={cn(slide.image ? "grid-cols-1 sm:grid-cols-2 " : "grid-cols-1", "grid w-full h-full")}>
                <div className={"flex flex-col col-span-1 w-full h-full justify-center"}>
                    <FieldWrap customStyles={"w-auto animate-fade"}>
                        <InputWrap customStyles={"w-auto p-4"}>
                            <span className={"font-semibold text-center text-large"}>{slide.text}</span>
                        </InputWrap>
                    </FieldWrap>

                    <FieldWrap customStyles={"w-full animate-fade"}>
                        <InputWrap customStyles={"w-auto p-4"}>
                            <MarkdownPreview text={slide.description}/>
                        </InputWrap>
                    </FieldWrap>
                </div>
                {slide.image && (
                    <div className={"col-span-1 flex flex-col h-full justify-start sm:justify-center"}>
                        <FieldWrap customStyles={"w-auto animate-fade"}>
                            <InputWrap customStyles={"w-auto p-0"}>
                                <img src={slide.image} alt={""} className={"rounded-md"}/>
                            </InputWrap>
                        </FieldWrap>
                    </div>
                )}
                <div className={"flex w-full justify-center col-span-1 sm:col-span-2 p-2 animate-fade"}>
                    <Button className={"max-w-md"} disabled={actionsDisabled}
                            onClick={async () => await saveAndMoveToAvailableSlide(slide["id"])}>
                        <span className={"text-wrap"}>
                            Continua
                        </span>
                    </Button>
                </div>
            </div>
        </div>
    )
}
InfoSlide.propTypes = {
    actionsDisabled: PropTypes.bool,
    quizForm: PropTypes.object.isRequired,
    currentSlide: PropTypes.string.isRequired,
    saveAndMoveToAvailableSlide: PropTypes.func.isRequired
}