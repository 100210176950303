import PreviewTitle from "./preview-title.jsx";
import PreviewWrap from "./preview-wrap.jsx";
import PropTypes from "prop-types";

export default function InfoPreview({selectSlide, selectedSlide, slide, checkAnyErrorForPath, slideIdx}) {

    return (
        <PreviewWrap selectSlide={selectSlide} selectedSlide={selectedSlide} slide={slide}
                     checkAnyErrorForPath={checkAnyErrorForPath} slideIdx={slideIdx}>
            <PreviewTitle title={slide.text} titlePlaceholder={"Info"}/>
            <div className="h-full"/>
            <div className="flex flex-col justify-center items-center h-full p-1 w-full self-end space-y-1">
                <div className={"flex w-full justify-center items-center space-x-1"}>
                    <div className={"rounded-full w-1.5 h-1.5 max-w-1.5 max-h-1.5 bg-green-600"}/>
                    <div className={"rounded-full flex flex-1 h-2 bg-green-600"}/>
                </div>
                <div className={"flex w-full justify-center items-center space-x-1"}>
                    <div className={"rounded-full w-1.5 h-1.5 max-w-1.5 max-h-1.5 bg-orange-600"}/>
                    <div className={"rounded-full flex flex-1 h-2 bg-orange-600"}/>
                </div>
                <div className={"flex w-full justify-center items-center space-x-1"}>
                    <div className={"rounded-full w-1.5 h-1.5 max-w-1.5 max-h-1.5 bg-cyan-600"}/>
                    <div className={"rounded-full flex flex-1 h-2 bg-cyan-600"}/>
                </div>
            </div>
        </PreviewWrap>
    )
}
InfoPreview.propTypes = {
    checkAnyErrorForPath: PropTypes.func.isRequired,
    selectSlide: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    slide: PropTypes.object.isRequired,
    slideIdx: PropTypes.number.isRequired
}