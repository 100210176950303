import {useAuthProvider, useFetchProvider, useRoutesProvider} from "../../providers/provider-hooks.jsx";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {jwtDecode} from "jwt-decode";

export default function useAccountWaiting() {
    const {getProfileData} = useFetchProvider()
    const {getNewToken, refreshToken} = useAuthProvider()
    const {ROLES, PATHS, PAGES} = useRoutesProvider()
    const navigate = useNavigate()

    async function checkAccount() {
        try {
            const newToken = await getNewToken(refreshToken)
            const newAuthState = {token: newToken, userInfo: jwtDecode(newToken.toString()) || null}
            const {userInfo: {role, isEnabled}} = newAuthState;
            if (isEnabled) return navigate("/")
            if (role === ROLES.PROFESOR) {
                const profileData = await getProfileData()
                if (profileData.rejectReason) {
                    return navigate(PATHS[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3])
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        checkAccount().then()
        const intervalId = setInterval(checkAccount, 10000)
        return () => clearInterval(intervalId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}