import {cn} from "../../../utils.js";
import PropTypes from "prop-types";
import {Fragment} from "react";

export default function TextInput({
                                      name, value, disabled = false, handleChange,
                                      label, customStyles, isPassword = false
                                  }) {

    return (
        <Fragment>
            <label htmlFor={name} className={cn("text-gray-400 w-full text-center font-bold", disabled? "opacity-50":"", customStyles)}>
                {label}
            </label>
            <input type={isPassword ? "password" : "text"} id={name} name={name} value={value || ""}
                   onChange={handleChange} placeholder={label} disabled={disabled} autoComplete={"new-password"}
                   className="disabled:cursor-not-allowed disabled:opacity-50 font-normal placeholder:font-bold w-full text-center text-[16px] h-full p-2 rounded-md appearance-none focus:outline-none"/>
        </Fragment>
    )
}
TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    customStyles: PropTypes.string,
    isPassword: PropTypes.bool,
    disabled: PropTypes.bool
}