import {QUESTION_SLIDE_COLORS} from "../../../../constants/question-slide-colors.js";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import {Button} from "../../../schadcn-ui/button.jsx";
import {cn} from "../../../../utils.js";
import PropTypes from "prop-types";
import {useMemo} from "react";

export default function QuestionSlide({
                                          quizForm, currentSlide, saveAndMoveToAvailableSlide, currentAnswer,
                                          addOrRemoveOption, actionsDisabled
                                      }) {
    const slide = useMemo(() => quizForm.slides.find(slide => slide.id === currentSlide), [currentSlide, quizForm]);

    return (
        <div className={"my-auto flex flex-col items-center"}>
            <FieldWrap customStyles={"w-auto max-w-md animate-fade"}>
                <InputWrap customStyles={"w-auto p-4"}>
                    <span className={"font-semibold text-center text-large"}>{slide.text}</span>
                </InputWrap>
            </FieldWrap>

            {slide.image && (
                <FieldWrap customStyles={"w-auto max-w-sm animate-fade"}>
                    <InputWrap customStyles={"w-auto p-0"}>
                        <img src={slide.image} alt={""}/>
                    </InputWrap>
                </FieldWrap>
            )}

            <div className={"grid grid-cols-1 lg:grid-cols-2 gap-4 py-2 w-full px-2 animate-fade"}>
                {slide.options.map((option, index) => {
                    return (
                        <InputWrap key={index}
                                   customStyles={cn(QUESTION_SLIDE_COLORS[index].completionStyles, "w-full shadow-2xl px-0")}>
                            <div
                                className={cn(QUESTION_SLIDE_COLORS[index].completionStyles, "flex flex-row flex-1 w-full p-2 space-x-2 rounded-[5px]")}>
                                <div className="h-full flex items-center justify-center">
                                    <div className={"bg-white flex items-center justify-center rounded-full"}>
                                        <button onClick={() => addOrRemoveOption(option.id)} disabled={actionsDisabled}
                                                className={cn("appearance-none rounded-full h-6 w-6 text-magenta transition duration-300 ease-in-out ring-2 ring-dark ring-offset-2 bg-white", currentAnswer["selectedOptionsIds"].includes(option.id) ? "bg-dark" : "")}/>
                                    </div>
                                </div>

                                {option.image && (
                                    <img src={option.image} alt="Uploaded"
                                         className="max-h-[130px] max-w-[180px] sm:max-h-[125px] sm:max-w-[150px] md:max-h-[125px] md:max-w-[170px] xl:max-h-[150px] xl:max-w-[280px] object-contain shrink rounded-xl"/>
                                )}

                                {option.text && (
                                    <div
                                        className={"text-white p-2 w-full text-xl font-bold flex items-center justify-center min-h-[50px] h-full"}>
                                        <span className={"text-center"}>{option.text || ""}</span>
                                    </div>
                                )}
                            </div>
                        </InputWrap>
                    )
                })}
            </div>

            <div className={"p-2 flex justify-center animate-fade"}>
                <Button className={"h-auto max-w-md"} disabled={actionsDisabled}
                        onClick={() => saveAndMoveToAvailableSlide(slide["id"])}>
                    <span className={"text-wrap"}>
                        Salveaza raspunsul
                    </span>
                </Button>
            </div>
        </div>
    )
}
QuestionSlide.propTypes = {
    actionsDisabled: PropTypes.bool,
    quizForm: PropTypes.object.isRequired,
    currentSlide: PropTypes.string.isRequired,
    currentAnswer: PropTypes.object.isRequired,
    addOrRemoveOption: PropTypes.func.isRequired,
    saveAndMoveToAvailableSlide: PropTypes.func.isRequired,
}