import {APPLICATION_REPORTS, useTableExports} from "./use-table-exports.js";
import {useFetchProvider} from "../../providers/provider-hooks.jsx";
import useTableParams from "./use-table-params.js";
import {useEffect, useRef, useState} from "react";
import {updateFailureToast} from "../../toast.js";
import {useEditions} from "../use-editions.js";
import {Slide, toast} from "react-toastify";
import {delay} from "../../utils.js";

export default function useSchoolsReport() {
    const [actionsDisabled, setActionsDisabled] = useState(false)
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({})

    const {params, changeParams, setParams} = useTableParams(renderSchoolReport, {page: 0})
    const {exportTable} = useTableExports(APPLICATION_REPORTS.SCHOOLS, params, setActionsDisabled)
    const {editions, currentEdition, loadEditions} = useEditions(params, setParams)
    const printRef = useRef(null)
    const {getSchoolsReport} = useFetchProvider()


    async function renderSchoolReport(freshParams) {
        setActionsDisabled(true)
        let errorMessage = "Tabelul nu s-a putut incarca"
        const id = toast.loading("Tabelul se incarca...", {transition: Slide})

        try {
            await delay(1000)
            const data = await getSchoolsReport(freshParams || params)
            setTableData(data.content)
            setPagination({totalPages: data.totalPages, totalElements:data.totalElements, ...data["pageable"], first: data.first, last: data.last})
            toast.dismiss(id)
        } catch (error) {
            console.error(error)
            updateFailureToast(error.message || errorMessage, id)
        } finally {
            setActionsDisabled(false)
        }
    }

    async function resetFilters() {
        setParams({page: params.page, editionId: params.editionId});
        await renderSchoolReport({page: params.page, editionId: params.editionId})
    }

    useEffect(() => {
        loadEditions().then(res =>
            renderSchoolReport(res ? {...params, ...res} : {...params}).then())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        tableData, printRef, actionsDisabled, resetFilters,
        params, changeParams, renderSchoolReport, pagination,
        currentEdition, editions, exportTable
    }
}