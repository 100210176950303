import useYoutubeEmbed from "../../../../hooks/use-youtube-embed.js";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import {Button} from "../../../schadcn-ui/button.jsx";
import YouTube from "react-youtube";
import PropTypes from "prop-types";
import {useMemo} from "react";

export default function VideoSlide({quizForm, currentSlide, saveAndMoveToAvailableSlide, actionsDisabled}) {
    const slide = useMemo(() => quizForm.slides.find(slide => slide.id === currentSlide), [currentSlide, quizForm]);
    const {opts, onPlayerStateChange} = useYoutubeEmbed(slide, quizForm, undefined, currentSlide)

    return (
        <div className={"my-auto flex flex-col items-center"}>
            <FieldWrap customStyles={"w-auto max-w-md animate-fade"}>
                <InputWrap customStyles={"w-auto p-4"}>
                    <span className={"font-semibold text-center text-large"}>{slide.text}</span>
                </InputWrap>
            </FieldWrap>

            {slide.video && (
                <FieldWrap customStyles={"animate-fade p-2"}>
                    <YouTube videoId={slide.video} opts={opts} onStateChange={onPlayerStateChange}/>
                </FieldWrap>
            )}

            <div className={"p-2 flex justify-center animate-fade"}>
                <Button className={"h-auto max-w-md"} disabled={actionsDisabled}
                        onClick={() => saveAndMoveToAvailableSlide(slide["id"])}>
                    <span className={"text-wrap"}>
                        Continua
                    </span>
                </Button>
            </div>
        </div>
    )
}
VideoSlide.propTypes = {
    actionsDisabled: PropTypes.bool,
    quizForm: PropTypes.object.isRequired,
    currentSlide: PropTypes.string.isRequired,
    saveAndMoveToAvailableSlide: PropTypes.func.isRequired,
}