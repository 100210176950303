import PreviewTitle from "./preview-title.jsx";
import {cn} from "../../../../utils.js";
import PropTypes from "prop-types";

export default function SettingsPreview({selectSlide, selectedSlide, checkAnyErrorForPath}) {
    const errors = checkAnyErrorForPath("id") || checkAnyErrorForPath("title") || checkAnyErrorForPath("description") || checkAnyErrorForPath("companyId") || checkAnyErrorForPath("timestamp")

    return (
        <div className={"group flex flex-row max-h-[60px] min-h-[60px] sm:max-h-[30px] sm:min-h-[30px] max-w-[132.82px] space-x-1 select-none"}>
            <div className={cn("opacity-0 w-[15px] flex flex-col space-y-2 justify-end min-w-[15px] h-full cursor-pointer")}/>
            <div onClick={() => selectSlide(null)} className={cn(errors ? "ring-2 ring-red-600" : selectedSlide === null ? "ring-2 ring-magenta/80" : "group-hover:ring-2 ring-magenta/80", "relative cursor-pointer text-very-small flex flex-1 items-center w-[91.18px] h-full bg-gray-200 rounded-md")}>
                <PreviewTitle titlePlaceholder={"Setari"}/>
            </div>
        </div>
    )
}
SettingsPreview.propTypes = {
    selectSlide: PropTypes.func.isRequired,
    checkAnyErrorForPath: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
}