import {Fragment, useEffect, useRef, useState} from "react";
import {Button} from "../../schadcn-ui/button.jsx";
import {FaXmark} from "react-icons/fa6";
import PropTypes from "prop-types";
import {cn} from "../../../utils.js";

export default function DoubleCheckAction({spanMessage, buttonMessage, buttonStyles, yesAction, actionsDisabled}) {
    const dialogRef = useRef(null)
    const [isDialogVisible, setIsDialogVisible] = useState(false)

    useEffect(() => {
        const handleClickOutside = (event) => dialogRef.current && !dialogRef.current.contains(event.target) ? setIsDialogVisible(false) : null
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Fragment>
            <button disabled={actionsDisabled} onClick={() => setIsDialogVisible(true)}
                    className={cn(buttonStyles)}>
                {buttonMessage}
            </button>
            {isDialogVisible && (
                <div
                    className="animate-fade animate-duration-200 cursor-default fixed left-0 top-0 w-screen h-screen flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm">
                    <div ref={dialogRef}
                         className="bg-light flex flex-col p-4 overflow-hidden rounded-lg shadow-lg relative min-w-[40vw] max-w-[80vw] max-h-[80vh]">
                        <div className={"relative flex justify-end pb-4"}>
                            <button disabled={actionsDisabled} onClick={() => setIsDialogVisible(false)}>
                                <FaXmark/>
                            </button>
                        </div>
                        <div
                            className={"flex flex-col relative select-text h-full w-full overflow-hidden min-w-[40vw] max-w-[80vw] max-h-[80vh]"}>
                            <span className={"p-2 text-large font-bold"}>{spanMessage}</span>
                            <div className={"px-2 pt-4 flex flex-row justify-between space-x-4"}>
                                <Button variant={"destructive"} className={"w-full"} disabled={actionsDisabled}
                                        onClick={() => setIsDialogVisible(false)}>NU</Button>
                                <Button className={"w-full"} disabled={actionsDisabled}
                                        onClick={async () => {
                                            setIsDialogVisible(false)
                                            await yesAction()
                                        }}>Da</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
}
DoubleCheckAction.propTypes = {
    spanMessage: PropTypes.string.isRequired,
    buttonMessage: PropTypes.string.isRequired,
    buttonStyles: PropTypes.string,
    yesAction: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
}