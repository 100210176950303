import {useEffect, useRef, useState} from "react";
import {toastOneWarn} from "../toast.js";

const DEFAULT_EMBED_OPTS = Object.freeze({
    playerVars: {autoplay: 1, controls: 1, start: 0, end: 0, rel: 0, modestbranding: 1},
})

export default function useYoutubeEmbed(slide, formData, setFormData, selectedQuestion) {
    const [startMinutes, setStartMinutes] = useState(Math.floor(slide.videoStart / 60));
    const [startSeconds, setStartSeconds] = useState(slide.videoStart % 60);
    const [endMinutes, setEndMinutes] = useState(Math.floor(slide.videoEnd / 60));
    const [endSeconds, setEndSeconds] = useState(slide.videoEnd % 60);
    const [firstRender, setFirstRender] = useState(true)
    const [opts, setOpts] = useState(JSON.parse(JSON.stringify({
        ...DEFAULT_EMBED_OPTS,
        playerVars: {...DEFAULT_EMBED_OPTS.playerVars, start: slide.videoStart, end: slide.videoEnd}
    })))

    const debounceTimerRef = useRef(null);

    const validateTimes = (shouldWarn = true) => {

        if (startMinutes < 0 || [undefined, null, ""].includes(startMinutes.toString())) {
            if (shouldWarn) {
                return toastOneWarn("Minutul de pornire trebuie să fie mai mare sau egal cu 0.");
            } else return false;
        }

        if (startSeconds < 0 || [undefined, null, ""].includes(startSeconds.toString())) {
            if (shouldWarn) {
                return toastOneWarn("Secunda de pornire trebuie să fie mai mare sau egală cu 0.");
            } else return false;
        }

        if (endMinutes < 0 || [undefined, null, ""].includes(endMinutes.toString())) {
            if (shouldWarn) {
                return toastOneWarn("Minutul de final trebuie să fie mai mare sau egal cu 0.");
            } else return false;
        }

        if (endSeconds < 0 || [undefined, null, ""].includes(endSeconds.toString())) {
            if (shouldWarn) {
                return toastOneWarn("Secunda de final trebuie să fie mai mare sau egală cu 0.");
            } else return false;
        }

        if (startSeconds < 0 || startSeconds > 59) {
            if (shouldWarn) {
                return toastOneWarn("Secunda de pornire trebuie să fie între 0 și 59.");
            } else return false;
        }

        if (endSeconds < 0 || endSeconds > 59) {
            if (shouldWarn) {
                return toastOneWarn("Secunda de final trebuie să fie între 0 și 59.");
            } else return false;
        }

        if (startMinutes < 0 || startMinutes > 999) {
            if (shouldWarn) {
                return toastOneWarn("Minutul de pornire trebuie să fie între 0 și 999.");
            } else return false;
        }

        if (endMinutes < 0 || endMinutes > 999) {
            if (shouldWarn) {
                return toastOneWarn("Minutul de final trebuie să fie între 0 și 999.");
            } else return false;
        }

        const startTime = startMinutes * 60 + startSeconds;
        const endTime = endMinutes * 60 + endSeconds;

        if (startTime >= endTime) {
            if (shouldWarn) {
                return toastOneWarn("Intervalul de început al videoclipului trebuie să fie mai mic decât cel de final.");
            } else return false;
        }

        if (!shouldWarn) return true;
    };

    const debounceValidate = () => {
        if (debounceTimerRef.current) clearTimeout(debounceTimerRef.current);
        debounceTimerRef.current = setTimeout(validateTimes, 2000);
    };

    const handleVideoIdChange = (e) => {
        updateQuestionData('video', e.target.value);
    };

    const handleStartMinutesChange = (e) => {
        setStartMinutes(e.target.value * 1);
        updateQuestionData('videoStart', e.target.value * 60 + startSeconds.toString() * 1);
    };

    const handleStartSecondsChange = (e) => {
        setStartSeconds(e.target.value * 1);
        updateQuestionData('videoStart', startMinutes.toString() * 60 + e.target.value * 1);
    };

    const handleEndMinutesChange = (e) => {
        setEndMinutes(e.target.value * 1);
        updateQuestionData('videoEnd', e.target.value * 60 + endSeconds.toString() * 1);
    };

    const handleEndSecondsChange = (e) => {
        setEndSeconds(e.target.value * 1);
        updateQuestionData('videoEnd', endMinutes.toString() * 60 + e.target.value * 1);
    };

    function updateQuestionData (field, value) {
        const updatedSlides = formData.slides.map((slide) =>
            slide.id === selectedQuestion ? {...slide, [field]: value} : slide
        );
        setFormData({...formData, slides: updatedSlides});
    }

    const onPlayerStateChange = (event) => {
        const player = event.target;
        const currentTime = player.getCurrentTime();

        if (event.data === 0) {
            player["seekTo"](slide.videoStart);
            player["pauseVideo"]();
        }

        if (currentTime < slide.videoStart) {
            player["seekTo"](slide.videoStart);
        }

        if (currentTime > slide.videoEnd) {
            player["seekTo"](slide.videoStart);
            player["pauseVideo"]();
        }
    };

    useEffect(() => {
        setStartMinutes(Math.floor(slide.videoStart / 60));
        setStartSeconds(slide.videoStart % 60);
        setEndMinutes(Math.floor(slide.videoEnd / 60));
        setEndSeconds(slide.videoEnd % 60);
    }, [slide])

    useEffect(() => {
        setOpts(
            JSON.parse(JSON.stringify({
                ...DEFAULT_EMBED_OPTS, playerVars: {
                    ...DEFAULT_EMBED_OPTS.playerVars,
                    start: startMinutes * 60 + startSeconds.toString() * 1,
                    end: endMinutes * 60 + endSeconds.toString() * 1
                }
            })))
        if (!firstRender) debounceValidate()
        setFirstRender(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startMinutes, startSeconds, endMinutes, endSeconds]);

    return {
        handleVideoIdChange, startMinutes, handleStartMinutesChange, startSeconds,
        handleStartSecondsChange, endMinutes, handleEndMinutesChange, endSeconds,
        handleEndSecondsChange, opts, onPlayerStateChange, validateTimes
    }
}