import PreviewTitle from "./preview-title.jsx";
import PreviewWrap from "./preview-wrap.jsx";
import {FaImage} from "react-icons/fa6";
import PropTypes from "prop-types";

export default function ImageUploadPreview({selectSlide, selectedSlide, slide, checkAnyErrorForPath, slideIdx}) {

    return(
        <PreviewWrap selectSlide={selectSlide} selectedSlide={selectedSlide} slide={slide}
                     checkAnyErrorForPath={checkAnyErrorForPath} slideIdx={slideIdx}>
            <PreviewTitle title={slide.text} titlePlaceholder={"Upload Imagini"}/>
            <div className="h-full"/>
            <div className="flex justify-center items-center h-full p-1 w-full self-end">
                    <FaImage className={"text-magenta text-[3em]"}/>
            </div>
        </PreviewWrap>
    )
}
ImageUploadPreview.propTypes = {
    checkAnyErrorForPath: PropTypes.func.isRequired,
    selectSlide: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    slide: PropTypes.object.isRequired,
    slideIdx: PropTypes.number.isRequired
}