import {DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger} from "../schadcn-ui/dropdown-menu.jsx";
import {useAuthProvider, useRoutesProvider} from "../../providers/provider-hooks.jsx"
import {Button} from "../schadcn-ui/button.jsx"
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

export default function UserIcon({openPasswordModal, profileData}) {
    const {NAV_LINKS_V2, PAGES, getMainPresentationRole, ROLES} = useRoutesProvider()
    const {logout, authState} = useAuthProvider()
    const {role} = authState.userInfo

    const navigate = useNavigate()

    const protocolDeColaborare = 'https://res.cloudinary.com/ddrkurepu/image/upload/v1726474399/inblysh77gjojbsxs23r.pdf';
    const editionRules = "https://www.ecotic.ro/wp-content/uploads/2024/09/REGULAMENTUL-OFICIAL-SCOALA-ECOTERRIENILOR-2024-2025.pdf"
    const mechanism = "https://www.ecotic.ro/wp-content/uploads/2024/09/Mecanism-SE-editia-a-VII-a.pdf"

    return (
        <DropdownMenu classname={"font-roboto-mono"}>
            <DropdownMenuTrigger asChild>
                <Button variant="secondary" size="icon" className="group rounded-full">
                    <img src={"/the-ecoterrian.jpg"} alt={""}
                         className={"p-0.5 bg-white min-w-[44px] rounded-full aspect-square border-2 border-dark"}/>
                    <span className="sr-only">Profil</span>
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className={"mx-1 font-roboto-mono"}>
                <DropdownMenuLabel>{getMainPresentationRole(role)}{profileData["nume"] ? `: ${profileData["nume"]}` : ""}</DropdownMenuLabel>
                {[ROLES.PROFESOR, ROLES.ELEV].includes(role) && ![undefined, null, ""].includes(profileData["points"]) && (
                    <DropdownMenuLabel>Punctele Scolii: {profileData["points"]}</DropdownMenuLabel>
                )}
                {ROLES.PROFESOR === role && profileData["codProfesor"] && (
                    <DropdownMenuLabel>Cod unic inregistrare: {profileData["codProfesor"]}</DropdownMenuLabel>
                )}
                <DropdownMenuSeparator/>
                {NAV_LINKS_V2[PAGES.PROFILE].ALLOWED_ROLES.includes(role) && (
                    <DropdownMenuItem
                        onClick={() => {
                            console.log(NAV_LINKS_V2[PAGES.PROFILE].PATH)
                            navigate(NAV_LINKS_V2[PAGES.PROFILE].PATH)
                        }}>
                        {NAV_LINKS_V2[PAGES.PROFILE].TEXT}
                    </DropdownMenuItem>
                )}
                {NAV_LINKS_V2[PAGES.SCHOOL_FORM_V2].ALLOWED_ROLES.includes(role) && (
                    <DropdownMenuItem
                        onClick={() => navigate(NAV_LINKS_V2[PAGES.SCHOOL_FORM_V2].PATH)}>
                        {NAV_LINKS_V2[PAGES.SCHOOL_FORM_V2].TEXT}
                    </DropdownMenuItem>
                )}
                <DropdownMenuItem onClick={openPasswordModal}>Schimbare Parola</DropdownMenuItem>
                {ROLES.PROFESOR === role && (
                    <DropdownMenuItem onClick={() => window.open(protocolDeColaborare, "_blank")}>
                        Protocol de Colaborare
                    </DropdownMenuItem>
                )}
                <DropdownMenuItem onClick={() => window.open(editionRules, "_blank")}>
                    Regulament
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => window.open(mechanism, "_blank")}>
                    Mecanism
                </DropdownMenuItem>
                <DropdownMenuSeparator/>
                <DropdownMenuItem onClick={() => logout()}>Deconectare</DropdownMenuItem>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
    UserIcon.propTypes = {
        openPasswordModal: PropTypes.func.isRequired,
        profileData: PropTypes.object.isRequired,
    }