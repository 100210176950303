import useAccountAtuhorizationReport from "../../hooks/reports/use-account-atuhorization-report.js";
import ApprovalDialog from "../../components/reports/account-authorization/approval-dialog.jsx";
import TableHeaders from "../../components/reports/account-authorization/table-headers.jsx";
import FiltersResetButton from "../../components/reports/tables/filters-reset-button.jsx";
import FormDialog from "../../components/reports/account-authorization/form-dialog.jsx";
import TableBody from "../../components/reports/account-authorization/table-body.jsx";
import RefreshButton from "../../components/reports/tables/refresh-button.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import Pagination from "../../components/reports/tables/pagination.jsx";
import ReportDetails from "../../components/reports/tables/report-details.jsx";

export default function AccountAuthorization() {
    const {
        tableData, changeParams, actionsDisabled,
        params, printRef, authorizeAccount, resetFilters,
        renderAtuhorizationReport, isDialogOpened,
        closeRejectDialog, openRejectDialog, changeRejectReason,
        unauthorizeAccount, rejectReason, dialogRef,
        isFormDialogOpened, formElementInDialog,
        formDialogRef, closeFormDialog, openDialogForm,
        dialogFormData, pagination
    } = useAccountAtuhorizationReport()

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap customStyles={"pr-0 rounded-none overflow-hidden"}>
                    <div
                        className={"pb-4 w-full flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 lg:justify-end lg:items-end"}>
                        <ReportDetails pagination={pagination}/>
                        <FiltersResetButton handleRefresh={resetFilters}
                                            disabled={Object.keys(params).length <= 1 || actionsDisabled}/>
                        <RefreshButton handleRefresh={renderAtuhorizationReport} disabled={actionsDisabled}/>
                    </div>
                    <div className="relative flex-1 pr-4 overflow-auto">
                        <div className={"absolute top-0 left-0 w-full"}>
                            <table ref={printRef} className="w-full h-full relative">
                                <TableHeaders actionsDisabled={actionsDisabled} params={params}
                                              changeParams={changeParams}/>
                                <TableBody tableData={tableData} authorizeAccount={authorizeAccount}
                                           actionsDisabled={actionsDisabled} openDialogForm={openDialogForm}
                                           openRejectDialog={openRejectDialog}/>
                            </table>
                        </div>
                    </div>
                    <Pagination pagination={pagination} changeParams={changeParams}
                                actionsDisabled={actionsDisabled} tableData={tableData}/>
                    <ApprovalDialog unauthorizeAccount={unauthorizeAccount} changeRejectReason={changeRejectReason}
                                    actionsDisabled={actionsDisabled} rejectReason={rejectReason} ref={dialogRef}
                                    isDialogOpened={isDialogOpened} closeRejectDialog={closeRejectDialog}/>
                    <FormDialog isFormDialogOpened={isFormDialogOpened} closeFormDialog={closeFormDialog}
                                authorizeAccount={authorizeAccount} dialogFormData={dialogFormData}
                                ref={formDialogRef} openRejectDialog={openRejectDialog}
                                formElementInDialog={formElementInDialog}/>
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    )
}