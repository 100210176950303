import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "../../schadcn-ui/select.jsx";
import {checkAnyErrorForPath, cn, retrieveErrorForPath} from "../../../utils.js";
import ErrorSpan from "../../layout/error-span.jsx";
import InputWrap from "../wraps/input-wrap.jsx";
import FieldWrap from "../wraps/field-wrap.jsx";
import PropTypes from "prop-types";

export default function SelectInputBox({label, emptyValueLabel, value, name, errors, options, display, customErrorStyles, customLabelStyles, handleChange}) {

    return (
        <FieldWrap>
            <InputWrap customStyles={cn("px-0")}>
                <label htmlFor={name} className={cn(value ? "font-black" : "sr-only", "text-gray-400 w-full text-center font-bold", customLabelStyles)}>
                    {label}
                </label>
                <Select onValueChange={(v) => handleChange({target: {name: name, value: v}})} value={value}>
                    <SelectTrigger className={"bg-white text-wrap h-auto border-0 text-[16px] [&>span]:line-clamp-none"}>
                        <SelectValue placeholder={emptyValueLabel} className={"font-bold text-wrap h-auto w-full line-clamp-none"}/>
                    </SelectTrigger>
                    <SelectContent
                        className={"bg-white -ml-[2px] text-[16px] rounded-lg shadow-2xl border-[3px] border-gray-300"}>
                        <SelectGroup>
                            {options.map((option, optionIdx) =>
                                <SelectItem className={"text-wrap h-auto"}
                                            key={optionIdx}
                                            value={option[display.value]}>
                                    <div className={"text-wrap"}>
                                        {display.keys.map(key => option[key]).join(" - ")}
                                    </div>
                                </SelectItem>
                            )}
                        </SelectGroup>
                    </SelectContent>
                </Select>
                <ErrorSpan text={retrieveErrorForPath(name, errors)}
                           customStyles={cn(checkAnyErrorForPath(name, errors) ? "flex" : "hidden", customErrorStyles)}/>
            </InputWrap>
        </FieldWrap>
    )
}
SelectInputBox.propTypes = {
    value: PropTypes.string,
    handleChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    customErrorStyles: PropTypes.string,
    options: PropTypes.array.isRequired,
    customLabelStyles: PropTypes.string,
    display: PropTypes.object.isRequired,
    emptyValueLabel: PropTypes.string.isRequired,
}
