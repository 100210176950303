import {useFetchProvider, useRoutesProvider} from "../../providers/provider-hooks.jsx";
import {QUIZ_TYPES} from "../../pages/quizzes/quiz-creation.jsx";
import {useLocation, useNavigate} from "react-router-dom";
import {delay, getQuizPlaceholder} from "../../utils.js";
import {toastOneWarn} from "../../toast.js";
import {Slide, toast} from "react-toastify";
import {useEffect, useState} from "react";
import {useParams} from "react-router";

export default function useQuizCompletion(quizType) {
    const location = useLocation();
    const {isFreshCompletion} = location.state || {};

    const [actionsDisabled, setActionsDisabled] = useState(false)
    const [freshCompletion, setFreshCompletion] = useState(isFreshCompletion)
    const [currentSlide, setCurrentSlide] = useState(undefined)
    const [isQuizFinished, setIsQuizFinished] = useState(null)
    const [quizAnswers, setQuizAnswers] = useState([])
    const [isQuizIdValid, setIsQuizIdValid] = useState(null)
    const [currentAnswer, setCurrentAnswer] = useState(null)
    const [quizForm, setQuizForm] = useState({})

    const {getLessonById, getAnswersForLesson, getDefaultAnswer, postAnswer, uploadImage} = useFetchProvider()
    const navigate = useNavigate()
    const {PATHS, PAGES} = useRoutesProvider()
    const {id} = useParams()

    const handleDZClick = () => document.getElementById("question-image-upload").click();

    async function redirectToListing() {
        switch (quizType) {
            case QUIZ_TYPES.LESSON:
                return navigate(`${PATHS[PAGES.LESSON_QUIZ_LISTING]}`)
            case QUIZ_TYPES.HOMEWORK:
                return navigate(`${PATHS[PAGES.HOMEWORK_LISTING]}`)
            case QUIZ_TYPES.PRACTICAL_ACTIVITY:
                return navigate(`${PATHS[PAGES.PRACTICAL_ACTIVITY_LISTING]}`)
        }
    }

    async function renderQuiz() {
        setActionsDisabled(true)
        const toastId = toast.loading(`${getQuizPlaceholder(quizType, 4)} se incarca...`, {transition: Slide})
        try {
            await delay(1000)
            const newQuiz = await getLessonById(id)
            const newQuizAnswers = location.pathname.includes("preview") ? [] : freshCompletion ? [] : await getAnswersForLesson(id)

            setIsQuizFinished(newQuiz.slides.length === newQuizAnswers.length)
            setCurrentSlide(null)
            setQuizForm(newQuiz)
            setQuizAnswers(newQuizAnswers)
            setIsQuizIdValid(true)
        } catch (error) {
            console.error(error)
            setIsQuizIdValid(false)
        } finally {
            toast.dismiss(toastId)
            setActionsDisabled(false)
        }
    }

    async function addOrRemoveOption(optionId) {
        setActionsDisabled(true)
        try {
            const newAnswer = !currentAnswer ? await getDefaultAnswer() : {...currentAnswer}
            if (!newAnswer || !currentAnswer.selectedOptionsIds || !Array.isArray(currentAnswer.selectedOptionsIds)) return
            if (currentAnswer.selectedOptionsIds.includes(optionId)) {
                newAnswer.selectedOptionsIds = newAnswer.selectedOptionsIds.filter(id => id !== optionId);
            } else currentAnswer["selectedOptionsIds"].push(optionId)
            setCurrentAnswer(newAnswer)
        } catch (error) {
            console.error(error)
        } finally {
            setActionsDisabled(false)
        }
    }

    async function handleImageUpload(acceptedFiles) {
        setActionsDisabled(true)
        try {
            const currentPhotos = currentAnswer.photos || [];
            let currentPhotoCount = currentPhotos.length;

            for (const file of acceptedFiles) {
                if (currentPhotoCount >= 4) return toastOneWarn("Ne cerem scuze dar pot fi adaugate maxim 4 poze.");

                try {
                    const uploadedPhoto = await uploadImage(file);
                    setCurrentAnswer(prevState => ({
                        ...prevState,
                        photos: prevState.photos && Array.isArray(prevState.photos) ? [...prevState.photos, uploadedPhoto] : [uploadedPhoto]
                    }));
                    currentPhotoCount++;
                } catch (error) {
                    console.error(error)
                } finally {
                    setActionsDisabled(false)
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setActionsDisabled(false)
        }
    }

    const removePhoto = (index) =>
        setCurrentAnswer(prevState => ({...prevState, photos: prevState.photos.filter((_, i) => i !== index)}));

    async function sendAnswer() {
        await postAnswer(currentAnswer)
    }

    async function saveAndMoveToAvailableSlide(currentSlideId) {
        setActionsDisabled(true)
        try {
            if (currentSlideId && !location.pathname.includes("preview")) await sendAnswer();
            const newQuizAnswers =
                location.pathname.includes("preview")
                    ? currentSlideId ? [...quizAnswers, JSON.parse(JSON.stringify(currentAnswer))] : [] : freshCompletion ? currentSlideId ? [JSON.parse(JSON.stringify(currentAnswer))] : [] : await getAnswersForLesson(id);
            const newAnswer = {...await getDefaultAnswer(), version: null};

            const isFinished = quizForm.slides.length === newQuizAnswers.length;
            if (isFinished) {
                setIsQuizFinished(true);
                setCurrentAnswer(null);
                setQuizAnswers(newQuizAnswers);
                setCurrentSlide(null);
                return;
            }

            const sortedSlides = JSON.parse(JSON.stringify(quizForm)).slides.sort((a, b) => a.number - b.number);
            for (const slide of sortedSlides) {
                console.log(newQuizAnswers)
                const isAnswered = newQuizAnswers.find(answer => answer["slideId"] === slide.id);
                if (!isAnswered) {
                    setQuizAnswers(newQuizAnswers);
                    setCurrentAnswer({...newAnswer, slideId: slide.id});
                    setCurrentSlide(slide.id);
                    if (location.pathname.includes("preview")) return
                    if (freshCompletion && currentSlideId) setFreshCompletion(false)
                    switch (quizType) {
                        case QUIZ_TYPES.LESSON:
                            window.history.replaceState(null, null, PATHS[PAGES.LESSON_COMPLETION].replace(":id", id))
                            break
                        case QUIZ_TYPES.HOMEWORK:
                            window.history.replaceState(null, null, PATHS[PAGES.HOMEWORK_COMPLETION].replace(":id", id))
                            break
                        case QUIZ_TYPES.PRACTICAL_ACTIVITY:
                            window.history.replaceState(null, null, PATHS[PAGES.PRACTICAL_ACTIVITY_COMPLETION].replace(":id", id))
                            break
                    }
                    return
                }
            }
        } catch (error) {
            console.error(error)
        } finally {
            setActionsDisabled(false)
        }
    }

    useEffect(() => {
        renderQuiz().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        isQuizIdValid, redirectToListing, quizForm, quizAnswers,
        currentSlide, saveAndMoveToAvailableSlide, currentAnswer,
        addOrRemoveOption, isQuizFinished, handleImageUpload,
        handleDZClick, removePhoto, actionsDisabled
    }
}