import PropTypes from "prop-types";

export default function QuizWrap({children}) {

    return (
        <div className={"relative w-full h-full rounded-md border border-lime-700 overflow-x-hidden "}>
            <div className={"absolute inset-0 z-0"}>
                <img src="/quiz-bg.jpg" alt="imaginea de fundal a chestionarului"
                     className="w-full h-full object-cover rounded-md" loading="lazy"/>
            </div>
            <div className={"absolute w-full h-full z-10 bg-white/40 rounded-md p-2"}>
                <div className={"flex flex-col items-center w-full h-full pr-2 overflow-y-scroll"}>
                    {children}
                </div>
            </div>
        </div>
    )
}
QuizWrap.propTypes = {
    children: PropTypes.node.isRequired,
}