import PropTypes from "prop-types";

export default function  H1 ({ customStyles = "", text }) {
    return (
        <h1 className={`${customStyles} standard-text-color scroll-m-20 text-very-large font-bold`}>
            {text}
        </h1>
    );
}
H1.propTypes = {
    customStyles: PropTypes.string,
    text: PropTypes.string.isRequired
}