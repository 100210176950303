import TextInputBox from "../../forms/final-input-boxes/text-input-box.jsx";
import {Button} from "../../schadcn-ui/button.jsx";
import {Fragment, forwardRef} from "react";
import {FaXmark} from "react-icons/fa6";
import PropTypes from "prop-types";

const LogisticsDialog = forwardRef(({
                                        isLogisticsDialogOpened, closeLogisticsDialog, sendLogisticsData,
                                        actionsDisabled, changeLogisticsData, logisticsData, logisticsErrors,
                                    }, ref) => {

    return (
        <Fragment>
            {isLogisticsDialogOpened && (
                <div
                    className="animate-fade animate-duration-200 cursor-default fixed left-0 top-0 w-screen h-screen flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm">
                    <div ref={ref}
                         className="bg-light flex flex-col p-4 overflow-hidden rounded-lg shadow-lg relative min-w-[40vw] max-w-[80vw] max-h-[80vh]">
                        <div className={"relative flex justify-end pb-4"}>
                            <button onClick={() => closeLogisticsDialog()} className="">
                                <FaXmark/>
                            </button>
                        </div>
                        <div
                            className={"flex flex-col relative select-text h-full w-full overflow-hidden min-w-[40vw] max-w-[80vw] max-h-[80vh]"}>
                            <TextInputBox handleChange={changeLogisticsData} name={"realDeeeKg"}
                                          label={"Kg DEEE confirmate"} value={logisticsData["realDeeeKg"] || ""}
                                          disabled={actionsDisabled} errors={logisticsErrors}/>
                            <TextInputBox handleChange={changeLogisticsData} name={"realDbaKg"}
                                          label={"Kg DBA confirmate"} value={logisticsData["realDbaKg"] || ""}
                                          disabled={actionsDisabled} errors={logisticsErrors}/>
                            <TextInputBox handleChange={changeLogisticsData} name={"realBecuriSiNeoaneKg"}
                                          label={"Kg becuri si neoane confirmate"}
                                          value={logisticsData["realBecuriSiNeoaneKg"] || ""}
                                          disabled={actionsDisabled} errors={logisticsErrors}/>

                            <div className={"px-2 pt-4 flex text-black font-bold"}>
                                Atentie! Aceste modificari nu se vor sincroniza cu datele din ERP. Asigurati-va de fiecare data ca noile date introduse se regasesc in ERP.
                            </div>

                            <div className={"px-2 pt-4 flex flex-row justify-between space-x-4"}>
                                <Button variant={"destructive"} className={"w-full"} disabled={actionsDisabled}
                                        onClick={() => closeLogisticsDialog()}>
                                    Anulare
                                </Button>
                                <Button className={"w-full"} disabled={actionsDisabled}
                                        onClick={() => sendLogisticsData()}>
                                    Salvare
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
});
LogisticsDialog.displayName = 'LogisticsDialog';
LogisticsDialog.propTypes = {
    removePhoto: PropTypes.func.isRequired,
    logisticsData: PropTypes.object.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    logisticsErrors: PropTypes.object.isRequired,
    sendLogisticsData: PropTypes.func.isRequired,
    handleImageUpload: PropTypes.func.isRequired,
    changeLogisticsData: PropTypes.func.isRequired,
    closeLogisticsDialog: PropTypes.func.isRequired,
    isLogisticsDialogOpened: PropTypes.bool.isRequired,
};

export default LogisticsDialog;
