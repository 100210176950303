import {QUESTION_SLIDE_COLORS} from "../../../../constants/question-slide-colors.js";
import PreviewTitle from "./preview-title.jsx";
import PreviewWrap from "./preview-wrap.jsx";
import {cn} from "../../../../utils.js";
import PropTypes from "prop-types";

export default function QuestionPreview({selectSlide, selectedSlide, slide, checkAnyErrorForPath, slideIdx}) {

    return (
        <PreviewWrap selectSlide={selectSlide} selectedSlide={selectedSlide} slide={slide}
                     checkAnyErrorForPath={checkAnyErrorForPath} slideIdx={slideIdx}>
            <PreviewTitle titlePlaceholder={"Intrebare"} title={slide.text}/>
            <div className={"h-full"}/>
            <div className={"h-full p-1 w-full self-end grid grid-cols-2 gap-1"}>
                {slide.options.map((option, optIdx) => (
                    <div key={optIdx} className={cn(option.correct ? QUESTION_SLIDE_COLORS[optIdx].completionStyles : "bg-gray-200 ring-gray-400 border-gray-400 text-white", "self-center rounded-sm grid-cols-1 h-[9px] border")}/>
                ))}
            </div>
        </PreviewWrap>
    )
}
QuestionPreview.propTypes = {
    checkAnyErrorForPath: PropTypes.func.isRequired,
    selectSlide: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    slide: PropTypes.object.isRequired,
    slideIdx: PropTypes.number.isRequired
}