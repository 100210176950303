import TextInputBox from "../../forms/final-input-boxes/text-input-box.jsx";
import {Button} from "../../schadcn-ui/button.jsx";
import {Fragment, forwardRef} from "react";
import {FaXmark} from "react-icons/fa6";
import PropTypes from "prop-types";

const ApprovalDialog = forwardRef(({
                                       isDialogOpened, closeRejectDialog, invalidateOrder,
                                       actionsDisabled, changeRejectReason, rejectReason,
                                   }, ref) => {
    return (
        <Fragment>
            {isDialogOpened && (
                <div
                    className="animate-fade animate-duration-200 cursor-default fixed left-0 top-0 w-screen h-screen flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm">
                    <div ref={ref}
                         className="bg-light flex flex-col p-4 overflow-hidden rounded-lg shadow-lg relative min-w-[40vw] max-w-[80vw] max-h-[80vh]">
                        <div className={"relative flex justify-end pb-4"}>
                            <button onClick={() => closeRejectDialog()} className="">
                                <FaXmark/>
                            </button>
                        </div>
                        <div
                            className={"flex flex-col relative select-text h-full w-full overflow-hidden min-w-[40vw] max-w-[80vw] max-h-[80vh]"}>
                            <TextInputBox handleChange={changeRejectReason} name={"rejectReason"}
                                          label={"Motiv respingere"} value={rejectReason}
                                          disabled={actionsDisabled}/>
                            <div className={"px-2 pt-4 flex flex-row justify-between space-x-4"}>
                                <Button className={"w-full"} disabled={actionsDisabled}
                                        onClick={() => closeRejectDialog()}>
                                    Anulare
                                </Button>
                                <Button variant={"destructive"} className={"w-full"} disabled={actionsDisabled}
                                        onClick={() => invalidateOrder()}>
                                    Respingere
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
});
ApprovalDialog.displayName = 'ApprovalDialog';
ApprovalDialog.propTypes = {
    rejectReason: PropTypes.string.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    invalidateOrder: PropTypes.func.isRequired,
    closeRejectDialog: PropTypes.func.isRequired,
    changeRejectReason: PropTypes.func.isRequired,
    isDialogOpened: PropTypes.bool.isRequired,
};

export default ApprovalDialog;
