import {useAuthProvider, useRoutesProvider} from "../../../providers/provider-hooks.jsx";
import {Button} from "../../schadcn-ui/button.jsx";
import PropTypes from "prop-types";
import {Fragment} from "react";
import {QUIZ_START_TYPES} from "./quiz-box.jsx";

export default function AuthorizedButtons({redirectToQuiz, quiz, startQuizCompletion, changeQuizVisibility}) {
    const {authState} = useAuthProvider()
    const {ROLES} = useRoutesProvider()

    if ([ROLES.DBEST, ROLES.ADMIN].includes(authState.userInfo.role)) return (
        <Fragment>
            <Button variant={"link"} className={"w-full sm:w-auto"}
                    onClick={() => changeQuizVisibility(quiz["id"], !quiz["isHidden"])}>
                <span className={"text-wrap"}>{quiz["isHidden"] ? "Afisare" : "Ascundere"}</span>
            </Button>
            <Button variant={"link"} className={"w-full sm:w-auto"}
                    onClick={() => redirectToQuiz(quiz)}>
                <span className={"text-wrap"}>Clonare</span>
            </Button>
            <Button variant={"link"} className={"w-full, sm:w-auto"}
                    onClick={() => startQuizCompletion(quiz, QUIZ_START_TYPES.PREVIEW)}>
                <span className={"text-wrap"}>Previzualizare</span>
            </Button>
        </Fragment>
    )
}
AuthorizedButtons.propTypes = {
    quiz: PropTypes.object.isRequired,
    redirectToQuiz: PropTypes.func.isRequired,
    startQuizCompletion: PropTypes.func.isRequired,
    changeQuizVisibility: PropTypes.func.isRequired,
}