import useIsDesktopViewPort from "../../hooks/use-is-desktop-viewport.js";
import {useLocation, useNavigate} from "react-router-dom";
import {cn, delay} from "../../utils.js";
import PropTypes from "prop-types";

export default function SideLink({path, displayName, idx, closeNav, navVisible}) {
    const {isDesktopViewPort} = useIsDesktopViewPort();
    const navigate = useNavigate()
    const location = useLocation()

    return (
        <button
            onClick={async () => {
                if (navVisible) closeNav();
                !isDesktopViewPort ? await delay(300) : null
                navigate(path);
            }}
            style={{animationDelay: `${100 * idx}ms`}}
            className={cn(location.pathname === path ? "bg-magenta/60 text-white" : "hover:bg-magenta/40 hover:shadow hover:text-white", "group h-[35px] min-h-[35px] flex items-center justify-between px-2 py-1 cursor-pointer font-semibold rounded-md duration:300")}>
            <span className={"truncate"}>{displayName}</span>
        </button>
    )
}
SideLink.propTypes = {
    idx: PropTypes.number.isRequired,
    path: PropTypes.string.isRequired,
    closeNav: PropTypes.func.isRequired,
    navVisible: PropTypes.bool.isRequired,
    displayName: PropTypes.string.isRequired,
}