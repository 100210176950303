import useIsMobileViewPort from "../../../hooks/use-is-mobile-view-port.js";
import ImageUploadPreview from "./previews/image-upload-preview.jsx";
import {SLIDE_TYPES} from "../../../pages/quizzes/quiz-creation.jsx";
import QuestionPreview from "./previews/question-preview.jsx";
import SettingsPreview from "./previews/settings-preview.jsx";
import PreviewActions from "./previews/preview-actions.jsx";
import VideoPreview from "./previews/video-preview.jsx";
import {Draggable, Droppable} from "@hello-pangea/dnd";
import InfoPreview from "./previews/info-preview.jsx";
import {FaCopy, FaTrashCan} from "react-icons/fa6";
import {cn} from "../../../utils.js";
import PropTypes from "prop-types";

export default function SideUtility({
                                        addQuestionSlide, addVideoSlide, addInfoSlide, saveQuiz,
                                        selectSlide, selectedSlide, formData, duplicateSlide,
                                        deleteSlide, isInPreview, setIsInPreview, errors,
                                        checkAnyErrorForPath, addImageUploadSlide, quizType,
                                    }) {
    const {isMobileViewPort} = useIsMobileViewPort()

    return (
        <div style={{backgroundSize: "400px"}}
             className={"w-full sm:w-[140px] h-full rounded-md border border-lime-700 bg-repeat bg-[url('/bg-banner.jpg')]"}>
            <div className={"w-full h-full p-2 bg-white bg-opacity-70 rounded-md"}>
                <div className={"flex flex-col w-full h-full space-y-2 "}>
                    <div className={"flex w-full h-full overflow-x-scroll overflow-y-hidden sm:overflow-x-hidden sm:overflow-y-scroll "}>
                        <div className={"relative flex w-full h-full py-[2px] sm:flex-col pb-2 sm:pr-2 space-x-2 sm:space-x-0 sm:space-y-2"}>
                            <SettingsPreview selectSlide={selectSlide} selectedSlide={selectedSlide}
                                             checkAnyErrorForPath={checkAnyErrorForPath}/>
                            <Droppable isDropDisabled={Object.keys(errors).length > 0} droppableId="questions"
                                       direction={isMobileViewPort ? "horizontal" : "vertical"}>
                                {(provided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}
                                         className={"flex sm:flex-col space-x-2 sm:space-x-0 sm:space-y-2"}>
                                        {formData.slides.map((slide, slideIdx) => (
                                            <Draggable isDragDisabled={Object.keys(errors).length > 0} key={slideIdx}
                                                       draggableId={`question-${slideIdx}`} index={slideIdx}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                                        className={"group flex flex-row max-h-[60px] min-h-[60px] max-w-[132.82px] space-x-1 select-none"}>
                                                        <div
                                                            className={cn("w-[15px] flex flex-col space-y-2 justify-end min-w-[15px] h-full")}>
                                                            <span
                                                                className={"text-xs font-black text-right"}>{slideIdx + 1}</span>
                                                            <FaCopy onClick={() => duplicateSlide(slide.id, slideIdx)}
                                                                    className={cn(selectedSlide === slide.id ? "opacity-100" : "group-hover:opacity-100 opacity-0", "cursor-pointer hover:text-magenta/80")}/>
                                                            <FaTrashCan onClick={() => deleteSlide(slide.id)}
                                                                        className={cn(selectedSlide === slide.id ? "opacity-100" : "group-hover:opacity-100 opacity-0", "cursor-pointer hover:text-magenta/80")}/>
                                                        </div>

                                                        {slide.type === SLIDE_TYPES.QUESTION && (
                                                            <QuestionPreview slide={slide} selectSlide={selectSlide}
                                                                             checkAnyErrorForPath={checkAnyErrorForPath}
                                                                             selectedSlide={selectedSlide}
                                                                             slideIdx={slideIdx}/>
                                                        )}

                                                        {slide.type === SLIDE_TYPES.VIDEO_PREVIEW && (
                                                            <VideoPreview slide={slide} selectSlide={selectSlide}
                                                                          checkAnyErrorForPath={checkAnyErrorForPath}
                                                                          selectedSlide={selectedSlide}
                                                                          slideIdx={slideIdx}/>
                                                        )}
                                                        {slide.type === SLIDE_TYPES.INFO && (
                                                            <InfoPreview slide={slide} selectSlide={selectSlide}
                                                                         checkAnyErrorForPath={checkAnyErrorForPath}
                                                                         selectedSlide={selectedSlide}
                                                                         slideIdx={slideIdx}/>
                                                        )}
                                                        {slide.type === SLIDE_TYPES.IMAGE_UPLOAD && (
                                                            <ImageUploadPreview slide={slide} selectSlide={selectSlide}
                                                                                checkAnyErrorForPath={checkAnyErrorForPath}
                                                                                selectedSlide={selectedSlide}
                                                                                slideIdx={slideIdx}/>
                                                        )}
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </div>
                    </div>
                    <PreviewActions setIsInPreview={setIsInPreview} quizType={quizType}
                                    addQuestionSlide={addQuestionSlide} saveQuiz={saveQuiz}
                                    addVideoSlide={addVideoSlide} isInPreview={isInPreview}
                                    addImageUploadSlide={addImageUploadSlide}
                                    addInfoSlide={addInfoSlide}/>
                </div>
            </div>
        </div>
    )
}
SideUtility.propTypes = {
    errors: PropTypes.object,
    saveQuiz: PropTypes.func.isRequired,
    quizType: PropTypes.string.isRequired,
    formData: PropTypes.object.isRequired,
    deleteSlide: PropTypes.func.isRequired,
    isInPreview: PropTypes.bool.isRequired,
    selectSlide: PropTypes.func.isRequired,
    addInfoSlide: PropTypes.func.isRequired,
    addVideoSlide: PropTypes.func.isRequired,
    setIsInPreview: PropTypes.func.isRequired,
    duplicateSlide: PropTypes.func.isRequired,
    addQuestionSlide: PropTypes.func.isRequired,
    addImageUploadSlide: PropTypes.func.isRequired,
    checkAnyErrorForPath: PropTypes.func.isRequired,
    selectedSlide: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};
