import ImageUploadSlide from "../../components/quizzes/creation/slides/image-upload-slide.jsx";
import SettingsSlide from "../../components/quizzes/creation/slides/settings-slide.jsx";
import QuestionSlide from "../../components/quizzes/creation/slides/question-slide.jsx";
import VideoSlide from "../../components/quizzes/creation/slides/video-slide.jsx";
import InfoSlide from "../../components/quizzes/creation/slides/info-slide.jsx";
import SideUtility from "../../components/quizzes/creation/side-utility.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import useQuizCreation from "../../hooks/quizzes/use-quiz-creation.js";
import {DragDropContext} from "@hello-pangea/dnd"
import {Fragment, useState} from "react";
import PropTypes from "prop-types";

// eslint-disable-next-line react-refresh/only-export-components
export const QUIZ_TYPES = Object.freeze({
    LESSON: "LESSON",
    HOMEWORK: "HOMEWORK",
    PRACTICAL_ACTIVITY: "PRACTICAL_ACTIVITY"
})

// eslint-disable-next-line react-refresh/only-export-components
export const SLIDE_TYPES = Object.freeze({
    VIDEO_PREVIEW: "VIDEO_PREVIEW",
    QUESTION: "QUESTION",
    INFO: "INFO",
    IMAGE_UPLOAD: "IMAGE_UPLOAD"
})

export default function QuizCreation({quizType}) {
    const [isInPreview, setIsInPreview] = useState(false)

    const {
        changeLessonDescription, setSelectedSlide, checkAnyErrorForPath,
        changeSlideImage, changeOptionText, changeOptionImage, saveQuiz,
        handleSlideDrag, duplicateSlide, deleteSlide, addOptionsToSlide,
        removeOptionsFromSlide, changeSlideTitle, changeSlideDescription,
        retrieveErrorForPath, addImageUploadSlide, selectedSlide, errors,
        removeOptionImage, markCorrectOption, changeLessonTitle, setFormData,
        selectSlide, addQuestionSlide, addVideoSlide, addInfoSlide, formData,
        changeLessonImage,
    } = useQuizCreation(quizType)

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <div className="flex flex-grow overflow-y-hidden flex-col space-y-4 w-full bg-light">
                    <div className={"flex flex-col space-y-4 sm:space-y-0 sm:space-x-4 w-full h-full sm:flex-row"}>
                        {Object.keys(formData).length > 0 && (
                            <Fragment>
                                {selectedSlide !== null ? (
                                    <Fragment>
                                        {formData.slides.find(slide => slide.id === selectedSlide)?.type === SLIDE_TYPES.QUESTION && (
                                            <QuestionSlide formData={formData} changeSlideImage={changeSlideImage}
                                                           selectedSlide={selectedSlide} isInPreview={isInPreview}
                                                           removeOptionsFromSlide={removeOptionsFromSlide}
                                                           retrieveErrorForPath={retrieveErrorForPath}
                                                           checkAnyErrorForPath={checkAnyErrorForPath}
                                                           markCorrectOption={markCorrectOption}
                                                           removeOptionImage={removeOptionImage}
                                                           changeOptionImage={changeOptionImage}
                                                           addOptionsToSlide={addOptionsToSlide}
                                                           changeSlideTitle={changeSlideTitle}
                                                           changeOptionText={changeOptionText}/>
                                        )}
                                        {formData.slides.find(slide => slide.id === selectedSlide)?.type === SLIDE_TYPES.VIDEO_PREVIEW && (
                                            <VideoSlide formData={formData} setFormData={setFormData}
                                                        retrieveErrorForPath={retrieveErrorForPath}
                                                        checkAnyErrorForPath={checkAnyErrorForPath}
                                                        changeSlideTitle={changeSlideTitle}
                                                        setSelectedSlide={setSelectedSlide}
                                                        selectedSlide={selectedSlide}
                                                        isInPreview={isInPreview}/>
                                        )}
                                        {formData.slides.find(slide => slide.id === selectedSlide)?.type === SLIDE_TYPES.INFO && (
                                            <InfoSlide formData={formData} selectedSlide={selectedSlide}
                                                       changeSlideDescription={changeSlideDescription}
                                                       retrieveErrorForPath={retrieveErrorForPath}
                                                       checkAnyErrorForPath={checkAnyErrorForPath}
                                                       changeSlideTitle={changeSlideTitle}
                                                       changeSlideImage={changeSlideImage}
                                                       isInPreview={isInPreview}/>
                                        )}
                                        {formData.slides.find(slide => slide.id === selectedSlide)?.type === SLIDE_TYPES.IMAGE_UPLOAD && (
                                            <ImageUploadSlide formData={formData} selectedSlide={selectedSlide}
                                                              retrieveErrorForPath={retrieveErrorForPath}
                                                              checkAnyErrorForPath={checkAnyErrorForPath}
                                                              changeSlideTitle={changeSlideTitle}
                                                              isInPreview={isInPreview}/>
                                        )}
                                    </Fragment>
                                ) : (
                                    <SettingsSlide formData={formData} isInPreview={isInPreview}
                                                   changeLessonDescription={changeLessonDescription}
                                                   retrieveErrorForPath={retrieveErrorForPath}
                                                   checkAnyErrorForPath={checkAnyErrorForPath}
                                                   changeLessonTitle={changeLessonTitle}
                                                   changeLessonImage={changeLessonImage}
                                                   quizType={quizType}/>
                                )}
                                <div className={"flex"}>
                                    <DragDropContext onDragEnd={handleSlideDrag}>
                                        <SideUtility addQuestionSlide={addQuestionSlide} addVideoSlide={addVideoSlide}
                                                     saveQuiz={saveQuiz} checkAnyErrorForPath={checkAnyErrorForPath}
                                                     selectSlide={selectSlide} addInfoSlide={addInfoSlide}
                                                     formData={formData} duplicateSlide={duplicateSlide}
                                                     deleteSlide={deleteSlide} isInPreview={isInPreview}
                                                     setIsInPreview={setIsInPreview} quizType={quizType}
                                                     selectedSlide={selectedSlide} errors={errors}
                                                     addImageUploadSlide={addImageUploadSlide}/>
                                    </DragDropContext>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </NavBarWrap>
        </BasePageWrap>
    )
}
QuizCreation.propTypes = {
    quizType: PropTypes.string.isRequired
}
