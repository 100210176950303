import {forwardRef, Fragment} from "react";
import {cn} from "../../../utils.js";
import PropTypes from "prop-types";

// eslint-disable-next-line react/display-name
const TextareaInput = forwardRef(({name, value, handleChange, rows = 1, cols = 50, label, customStyles = ""}, ref) => {
    return (
        <Fragment>
            <label htmlFor={name} className={cn(customStyles, "text-gray-400 w-full text-center font-bold")}>
                {label}
            </label>
            <textarea name={name} id={name} value={value || ""} placeholder={label}
                      onChange={handleChange} rows={rows} cols={cols} ref={ref}
                      className={cn("font-normal placeholder:font-bold w-full rounded-xl resize-none text-center text-[16px] h-full p-2 appearance-none focus:outline-none")}/>
        </Fragment>
    );
});

TextareaInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    customStyles: PropTypes.string,
    rows: PropTypes.number,
    cols: PropTypes.number,
};

export default TextareaInput;
