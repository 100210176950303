import {useRoutesProvider} from "../providers/provider-hooks.jsx";
import {Button} from "../components/schadcn-ui/button.jsx";
import {useNavigate} from "react-router-dom";

export default function Home() {
    const navigate = useNavigate()
    const {PAGES, PATHS} = useRoutesProvider()

    return (
        <div className={"overflow-x-hidden overflow-y-scroll bg-white"}>
            <div
                className="relative min-w-screen max-w-screen bg-white min-h-screen max-h-screen flex justify-center items-center">
                <div className="absolute flex flex-1 min-w-screen max-w-screen min-h-screen max-h-screen">
                    <img src="/thumbnail_interfata_desktop.jpg" alt="Home Banner"
                         className="md:flex hidden object-cover object-right min-w-screen max-w-screen min-h-screen max-h-screen"/>
                </div>
                <div className="absolute flex flex-1 min-w-screen max-w-screen min-h-screen max-h-screen">
                    <img src="/thumbnail_interfata.jpg" alt="Home Banner"
                         className="flex md:hidden object-cover object-top min-w-screen max-w-screen min-h-screen max-h-screen"/>
                </div>
                <div
                    className="p-20 z-10 flex flex-col space-y-8 w-full h-full justify-center items-center bg-black bg-opacity-20">
                    <div className={"w-full flex justify-center"}>
                        <Button
                            className={"rounded-full shadow text-xl py-5 px-10 items-center justify-center flex border-white border-2"}
                            onClick={() => navigate(PATHS[PAGES.PUPIL_AUTH])}>
                            <span className={"self-center text-3xl pb-1"}>Sunt Elev</span>
                        </Button>
                    </div>
                    <div className={"w-full flex justify-center"}>
                        <Button
                            className={"rounded-full shadow text-xl py-5 px-10 items-center justify-center flex border-white border-2"}
                            onClick={() => navigate(PATHS[PAGES.TEACHER_AUTH])}>
                            <span className={"self-center pb-1 text-3xl"}>Sunt Profesor</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
