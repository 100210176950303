import PropTypes from "prop-types";
import {cn} from "../../utils.js";

export default function ErrorSpan({customStyles = "", text}) {
    return (
        <span className={cn(`cursor-default px-2 pb-2 text-red-500 font-semibold justify-center animate-fade`, customStyles)}>
            {text}
        </span>
    );
}
ErrorSpan.propTypes = {
    customStyles: PropTypes.string,
    text: PropTypes.string.isRequired
}