import {checkAnyErrorForPath, cn, retrieveErrorForPath} from "../../../utils.js";
import TextInput from "../raw-inputs/text-input.jsx";
import ErrorSpan from "../../layout/error-span.jsx";
import FieldWrap from "../wraps/field-wrap.jsx";
import InputWrap from "../wraps/input-wrap.jsx";
import PropTypes from "prop-types";

export default function TextInputBox({
                                         value, name, errors, handleChange, customErrorStyles, label, disabled = false,
                                         customInputStyles, customInputWrapStyles, customFieldWrapStyles,
                                         isPassword = false
                                     }) {

    return (
        <FieldWrap customStyles={customFieldWrapStyles}>
            <InputWrap
                customStyles={cn(checkAnyErrorForPath(name, errors) ? "border-red-600" : "", customInputWrapStyles)}>
                <TextInput name={name} label={label} value={value} handleChange={handleChange}
                           isPassword={isPassword} disabled={disabled}
                           customStyles={cn(value ? "font-black" : "sr-only", customInputStyles)}/>
                <ErrorSpan text={retrieveErrorForPath(name, errors)}
                           customStyles={cn(checkAnyErrorForPath(name, errors) ? "flex" : "hidden", customErrorStyles)}/>
            </InputWrap>
        </FieldWrap>
    )
}
TextInputBox.propTypes = {
    errors: PropTypes.object,
    disabled: PropTypes.bool,
    isPassword: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    customErrorStyles: PropTypes.string,
    customInputStyles: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    customInputWrapStyles: PropTypes.string,
    customFieldWrapStyles: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}