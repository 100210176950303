import PreviewSlidesBar from "./side-bar/preview-slides-bar.jsx";
import PresentationSlide from "./slides/presentation-slide.jsx";
import ImageUploadSlide from "./slides/image-upload-slide.jsx";
import CompletionSlide from "./slides/completion-slide.jsx";
import {SLIDE_TYPES} from "../../../pages/quizzes/quiz-creation.jsx";
import QuestionSlide from "./slides/question-slide.jsx";
import VideoSlide from "./slides/video-slide.jsx";
import InfoSlide from "./slides/info-slide.jsx";
import WrapWithBg from "./wrap-with-bg.jsx";
import PropTypes from "prop-types";
import {Fragment} from "react";

export default function ValidQuizId({
                                        quizForm, quizAnswers, currentSlide, quizType, currentAnswer,
                                        saveAndMoveToAvailableSlide, addOrRemoveOption, isQuizFinished,
                                        redirectToListing, handleImageUpload, handleDZClick, removePhoto,
                                        actionsDisabled
                                    }) {

    return (
        <div className={"w-full h-full overflow-hidden flex flex-col-reverse sm:flex-row"}>
            <PreviewSlidesBar quizForm={quizForm} quizAnswers={quizAnswers}
                              currentSlide={currentSlide} isQuizFinished={isQuizFinished}/>

            <WrapWithBg customStyles={"animate-fade justify-start"}>

                {isQuizFinished === false && currentSlide === null && (
                    <PresentationSlide quizForm={quizForm} quizAnswers={quizAnswers} quizType={quizType}
                                       saveAndMoveToAvailableSlide={saveAndMoveToAvailableSlide}/>
                )}
                {![undefined, null].includes(currentSlide) && (
                    <Fragment>
                        {quizForm.slides.find(slide => slide.id === currentSlide)?.type === SLIDE_TYPES.INFO && (
                            <InfoSlide saveAndMoveToAvailableSlide={saveAndMoveToAvailableSlide}
                                       currentSlide={currentSlide} quizForm={quizForm} actionsDisabled={actionsDisabled}/>
                        )}
                        {quizForm.slides.find(slide => slide.id === currentSlide)?.type === SLIDE_TYPES.VIDEO_PREVIEW && (
                            <VideoSlide saveAndMoveToAvailableSlide={saveAndMoveToAvailableSlide}
                                        currentSlide={currentSlide} quizForm={quizForm} actionsDisabled={actionsDisabled}/>
                        )}
                        {quizForm.slides.find(slide => slide.id === currentSlide)?.type === SLIDE_TYPES.QUESTION && (
                            <QuestionSlide saveAndMoveToAvailableSlide={saveAndMoveToAvailableSlide}
                                           currentSlide={currentSlide} quizForm={quizForm}
                                           addOrRemoveOption={addOrRemoveOption}
                                           actionsDisabled={actionsDisabled}
                                           currentAnswer={currentAnswer}/>
                        )}
                        {quizForm.slides.find(slide => slide.id === currentSlide)?.type === SLIDE_TYPES.IMAGE_UPLOAD && (
                            <ImageUploadSlide currentAnswer={currentAnswer} handleImageUpload={handleImageUpload}
                                              saveAndMoveToAvailableSlide={saveAndMoveToAvailableSlide}
                                              handleDZClick={handleDZClick} removePhoto={removePhoto}
                                              actionsDisabled={actionsDisabled}
                                              currentSlide={currentSlide} quizForm={quizForm}/>
                        )}
                    </Fragment>
                )}
                {isQuizFinished === true && currentSlide === null && (
                    <CompletionSlide quizForm={quizForm} quizAnswers={quizAnswers} quizType={quizType}
                                     saveAndMoveToAvailableSlide={saveAndMoveToAvailableSlide}
                                     redirectToListing={redirectToListing}/>
                )}
            </WrapWithBg>
        </div>
    )
}
ValidQuizId.propTypes = {
    currentSlide: PropTypes.string,
    isQuizFinished: PropTypes.bool,
    actionsDisabled: PropTypes.bool,
    currentAnswer: PropTypes.object,
    quizForm: PropTypes.object.isRequired,
    quizType: PropTypes.string.isRequired,
    removePhoto: PropTypes.func.isRequired,
    quizAnswers: PropTypes.array.isRequired,
    handleDZClick: PropTypes.func.isRequired,
    redirectToListing: PropTypes.func.isRequired,
    addOrRemoveOption: PropTypes.func.isRequired,
    handleImageUpload: PropTypes.func.isRequired,
    saveAndMoveToAvailableSlide: PropTypes.func.isRequired,
}