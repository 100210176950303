import PropTypes from "prop-types";
import {cn} from "../../../utils.js";

export default function BodyCell({customStyles, colSpan, children}) {

    return (
        <td className={cn("p-4 align-middle font-semibold", customStyles)} colSpan={colSpan}>
            {children}
        </td>
    )
}
BodyCell.propTypes = {
    customStyles: PropTypes.string,
    colSpan: PropTypes.number.isRequired,
    children: PropTypes.node
};