import {useAuthProvider, useRoutesProvider} from "../../../providers/provider-hooks.jsx";
import BooleanFilterInput from "../tables/boolean-filter-input.jsx";
import SearchInput from "../tables/search-input.jsx";
import SortButton from "../tables/sort-button.jsx";
import HeadCell from "../tables/head-cell.jsx";
import HeadRow from "../tables/head-row.jsx";
import {cn} from "../../../utils.js";
import PropTypes from "prop-types";

export default function TableHeaders({actionsDisabled, params, changeParams}) {
    const {authState} = useAuthProvider()
    const {ROLES} = useRoutesProvider()

    return (
        <thead className={cn("font-bold bg-light sticky top-0 z-10")}>
        <HeadRow>
            {authState.userInfo.role === ROLES.DBEST && (
                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                    value={params["sort"] || ""} name={"id"}/>
                        <span className={"text-nowrap"}>Id</span>
                    </div>
                </HeadCell>
            )}

            {authState.userInfo.role === ROLES.DBEST && (
                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                    value={params["sort"] || ""} name={"notification.id"}/>
                        <span className={"text-nowrap"}>Id notificare</span>
                    </div>
                </HeadCell>
            )}

            {authState.userInfo.role === ROLES.DBEST && (
                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                    value={params["sort"] || ""} name={"receiver.id"}/>
                        <span className={"text-nowrap"}>Id destinatar</span>
                    </div>
                </HeadCell>
            )}

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"notification.content"}/>
                    <span className={"text-nowrap"}>Continut Notificare</span>
                </div>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"notification.sendAt"}/>
                    <span className={"text-nowrap"}>Notificare trimisa la data</span>
                </div>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"receiver.role"}/>
                    <span className={"text-nowrap"}>Rol destinatar</span>
                </div>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"readAt"}/>
                    <span className={"text-nowrap"}>Notificare citita la data</span>
                </div>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"isRead"}/>
                    <span className={"text-nowrap"}>Notificare citita</span>
                </div>
                <BooleanFilterInput handleChange={changeParams} disabled={actionsDisabled}
                                    name={`isRead`} value={params[`isRead`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"notification.subject"}/>
                    <span className={"text-nowrap"}>Subiect notificare</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Subiect notificare..."}
                             name={`notification.subject`} value={params[`notification.subject`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"receiver.nume"}/>
                    <span className={"text-nowrap"}>Destinatar</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Destinatar..."}
                             name={`receiver.nume`} value={params[`receiver.nume`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"receiver.email"}/>
                    <span className={"text-nowrap"}>Email destinatar</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Email destinatar..."}
                             name={`receiver.email`} value={params[`receiver.email`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"receiver.telefon"}/>
                    <span className={"text-nowrap"}>Telefon destinatar</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Telefon destinatar..."}
                             name={`receiver.telefon`} value={params[`receiver.telefon`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"receiver.school.codSe"}/>
                    <span className={"text-nowrap"}>Cod SE</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Cod SE..."}
                             name={`receiver.school.codSe`} value={params[`receiver.school.codSe`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"receiver.school.numeInstitutieInvatamant"}/>
                    <span className={"text-nowrap"}>Denumire institutie invatamant</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Denumire institutie invatamant..."}
                             name={`receiver.school.numeInstitutieInvatamant`}
                             value={params[`receiver.school.numeInstitutieInvatamant`] || ""}/>
            </HeadCell>
        </HeadRow>
        </thead>
    )
}
TableHeaders.propTypes = {
    actionsDisabled: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    changeParams: PropTypes.func.isRequired
}