import {useFetchProvider} from "../../providers/provider-hooks.jsx";
import {toastOneWarn, updateFailureToast} from "../../toast.js";
import useTableParams from "./use-table-params.js";
import {useEffect, useRef, useState} from "react";
import {Slide, toast} from "react-toastify";
import {delay} from "../../utils.js";

export default function useAccountAtuhorizationReport() {
    const [isFormDialogOpened, setIsFormDialogOpened] = useState(false)
    const [actionsDisabled, setActionsDisabled] = useState(false)
    const [formElementInDialog, setFormElementInDialog] = useState(null)
    const [isDialogOpened, setIsDialogOpened] = useState(false)
    const [elementInDialog, setElementInDialog] = useState(null)
    const [dialogFormData, setDialogFormData] = useState({})
    const [rejectReason, setRejectReason] = useState("")
    const formDialogRef = useRef(null)
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({})

    const printRef = useRef(null)
    const dialogRef = useRef(null)
    const {getProfileActivationTable, patchActivation, getSchoolRegistrationForm} = useFetchProvider()
    const {params, changeParams, setParams} = useTableParams(renderAtuhorizationReport, {page: 0})

    function closeFormDialog() {
        setIsFormDialogOpened(false)
        setFormElementInDialog(null)
        setDialogFormData({})
        setIsDialogOpened(false)
        setElementInDialog(null)
        setRejectReason("")
    }

    async function openDialogForm(accountId) {
        setActionsDisabled(true)
        let errorMessage = "Formularul nu s-a putut deschide."
        const toastId = toast.loading("Incarcam formularul de inregistrare al utilizatorului...", {transition: Slide})

        try {
            await delay(1000)
            const dialogData = await getSchoolRegistrationForm(accountId)
            setDialogFormData(dialogData)
            setIsFormDialogOpened(true)
            setFormElementInDialog(accountId)
            toast.dismiss(toastId)
        } catch (error) {
            updateFailureToast(error.message || errorMessage, toastId)
        } finally {
            setActionsDisabled(false)
        }
    }

    function closeRejectDialog() {
        setIsFormDialogOpened(false)
        setFormElementInDialog(null)
        setDialogFormData({})
        setIsDialogOpened(false)
        setElementInDialog(null)
        setRejectReason("")
    }

    function openRejectDialog(accountId) {
        setIsDialogOpened(true)
        setElementInDialog(accountId)
    }

    const changeRejectReason = (e) => setRejectReason(e.target.value)

    async function unauthorizeAccount() {
        setActionsDisabled(true)
        if (!rejectReason) {
            toastOneWarn("Motivul respingerii acestui cont nu a fost mentionat.")
            return setActionsDisabled(false)
        }
        try {
            await authorizeAccount(elementInDialog, false, rejectReason)
            closeRejectDialog()
        } catch (error) {
            console.error(error)
        } finally {
            setActionsDisabled(false)
        }
    }

    async function authorizeAccount(id, isAuthorized, rejectReason) {
        setActionsDisabled(true)
        let errorMessage = "Procesul de autorizare nu s-a putut efectua."
        const toastId = toast.loading("Actiunea este in proces de efectuare...", {transition: Slide})

        try {
            await delay(1000)
            await patchActivation(id, {activat: isAuthorized, rejectReason: rejectReason})
            toast.dismiss(toastId)
            closeRejectDialog()
            closeFormDialog()
        } catch (error) {
            console.error(error)
            updateFailureToast(error.message || errorMessage, toastId)
        } finally {
            setActionsDisabled(false)
            await renderAtuhorizationReport()
        }
    }

    async function renderAtuhorizationReport(freshParams) {
        setActionsDisabled(true)
        let errorMessage = "Tabelul nu s-a putut incarca"
        const id = toast.loading("Tabelul se incarca...", {transition: Slide})

        try {
            await delay(1000)
            const data = await getProfileActivationTable(freshParams || params)
            setTableData(data.content)
            setPagination({totalPages: data.totalPages, totalElements:data.totalElements, ...data["pageable"], first: data.first, last: data.last})
            toast.dismiss(id)
        } catch (error) {
            console.error(error)
            updateFailureToast(error.message || errorMessage, id)
        } finally {
            setActionsDisabled(false)
        }
    }

    async function resetFilters() {
        setParams({page: params.page});
        await renderAtuhorizationReport({page: params.page})
    }

    useEffect(() => {
        renderAtuhorizationReport().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        closeRejectDialog, openRejectDialog, changeRejectReason,
        authorizeAccount, renderAtuhorizationReport, resetFilters,
        tableData, printRef, actionsDisabled, params, changeParams,
        unauthorizeAccount, isDialogOpened, rejectReason, dialogRef,
        isFormDialogOpened, formElementInDialog, dialogFormData,
        formDialogRef, closeFormDialog, openDialogForm, pagination,
    }
}