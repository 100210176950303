import PropTypes from "prop-types";

export default function ReportDetails({pagination}) {

    return (
        <div className={"flex flex-col w-full"}>
            <span className={"font-semibold"}>PAGINA:
                <span className={"font-bold text-magenta"}>
                     {" "}{Object.keys(pagination).length > 0 ? pagination["pageNumber"] * 1 + 1 : ""}
                </span>
            </span>
            <span className={"font-semibold"}>TOTAL PAGINI:
                <span className={"font-bold text-magenta"}>
                     {" "}{Object.keys(pagination).length > 0 ? pagination["totalPages"] : ""}
                </span>
            </span>
            <span className={"font-semibold"}>TOTAL ELEMENTE:
                <span className={"font-bold text-magenta"}>
                     {" "}{Object.keys(pagination).length > 0 ? pagination["totalElements"] : ""}
                </span>
            </span>
            <span className={"font-semibold"}>INTERVAL ELEMENTE AFISATE:
                <span className={"font-bold text-magenta"}>
                     {" "}{Object.keys(pagination).length > 0 ? pagination["pageNumber"] * 1 * pagination["pageSize"] + 1 : ""}{Object.keys(pagination).length > 0 && " - "}{Object.keys(pagination).length > 0 ? (pagination["pageNumber"] * 1 + 1) * pagination["pageSize"] : ""}
                </span>
            </span>
        </div>
    )
}
ReportDetails.propTypes = {
    pagination: PropTypes.object.isRequired
}