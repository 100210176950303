import PropTypes from "prop-types";
import {cn} from "../../../utils.js";

export default function InputWrap({children, customStyles}) {

    return (
        <div className={cn("bg-white px-2 flex flex-col w-full rounded-lg shadow-2xl border-[3px] border-gray-300 focus-within:border-gray-400 transition-colors duration-300", customStyles)}>
            {children}
        </div>
    )
}
InputWrap.propTypes = {
    children: PropTypes.node.isRequired,
    customStyles: PropTypes.string
}