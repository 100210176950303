import {cn} from "../../../utils.js";
import PropTypes from "prop-types";
import {Fragment} from "react";

export default function NumberInput({value, name, label, handleChange, customStyles = "", disabled}) {

    return (
        <Fragment>
            <label htmlFor={name}
                   className={cn("text-gray-400 w-full text-center font-bold", customStyles, value ? "font-black" : "sr-only",)}>
                {label}
            </label>
            <input type="number" id={name} name={name} value={value || ""}
                   onChange={handleChange} placeholder={label} min="0" disabled={disabled}
                   className="disabled:cursor-not-allowed disabled:opacity-50 font-normal placeholder:font-bold  w-full text-center text-[16px] h-full p-2 rounded-md appearance-none focus:outline-none"/>
        </Fragment>
    )
}
NumberInput.propTypes = {
    disabled: PropTypes.bool,
    customStyles: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}