import {useAuthProvider, useFetchProvider, useRoutesProvider} from "../../providers/provider-hooks.jsx";
import {QUIZ_TYPES} from "../../pages/quizzes/quiz-creation.jsx";
import {toastOneWarn, updateFailureToast} from "../../toast.js";
import {delay, getQuizPlaceholder} from "../../utils.js";
import {useNavigate} from "react-router-dom";
import {Slide, toast} from "react-toastify";

export default function useQuizListing(quizType, setItemsInListing) {
    if (!quizType) throw new Error("quizType param was not provided to useQuizListing")
    if (!setItemsInListing) throw new Error("setItemsInListing param was not provided to useQuizListing")

    const navigate = useNavigate()
    const {ROLES, PAGES, PATHS} = useRoutesProvider()
    const {getQuizListing, patchQuizVisibility} = useFetchProvider()
    const {authState} = useAuthProvider()


    async function changeQuizVisibility(id, isHidden) {
        const toastId = toast.loading(`Operatiunea se incarca...`, {transition: Slide})
        try {
            await delay(1000)
            await patchQuizVisibility(id, {isHidden: isHidden})
            toast.dismiss(toastId)
            await renderListing()
        } catch (error) {
            updateFailureToast("Operatiunea nu s-a putut incarca.", toastId)
            console.error(error)
        }
    }

    async function createQuiz() {
        switch (quizType) {
            case QUIZ_TYPES.LESSON:
                return navigate(PATHS[PAGES.LESSON_QUIZ_CREATION_REDIRECT])
            case QUIZ_TYPES.HOMEWORK:
                return navigate(PATHS[PAGES.HOMEWORK_CREATION_REDIRECT])
            case QUIZ_TYPES.PRACTICAL_ACTIVITY:
                return navigate(PATHS[PAGES.PRACTICAL_ACTIVITY_CREATION_REDIRECT])
        }
    }

    async function redirectToQuiz(quiz) {
        if (!quiz) throw new Error("quiz param was not provided to redirectToQuiz")
        switch (quizType) {
            case QUIZ_TYPES.LESSON:
                return navigate(`${PATHS[PAGES.LESSON_QUIZ_CREATION_REDIRECT]}/${quiz.id}`)
            case QUIZ_TYPES.HOMEWORK:
                return navigate(`${PATHS[PAGES.HOMEWORK_CREATION_REDIRECT]}/${quiz.id}`)
            case QUIZ_TYPES.PRACTICAL_ACTIVITY:
                return navigate(`${PATHS[PAGES.PRACTICAL_ACTIVITY_CREATION_REDIRECT]}/${quiz.id}`)
        }
    }

    async function renderListing() {
        setItemsInListing(null)
        const id = toast.loading(`Lista cu ${getQuizPlaceholder(quizType, 5)} se incarca...`, {transition: Slide})
        try {
            await delay(1000)
            let newLessons = await getQuizListing(quizType)
            if (authState.userInfo.role === ROLES.PROFESOR) newLessons = [] //todo: remove in the future
            if (authState.userInfo.role === ROLES.ELEV) newLessons = [] //todo: remove in the future
            toast.dismiss(id)
            if (newLessons.length === 0) return toastOneWarn(`Momentan nu exista ${getQuizPlaceholder(quizType, 5)} incarcate in platforma`)
            setItemsInListing(newLessons)
        } catch (error) {
            updateFailureToast("Pagina nu s-a putut incarca.", id)
            console.error(error)
        }
    }

    async function startQuizCompletion(quiz, method) {
        if (!quiz) throw new Error("quiz param was not provided to startQuizCompletion")
        switch (quizType) {
            case QUIZ_TYPES.LESSON:
                if (method === "PREVIEW") return navigate(`${PATHS[PAGES.LESSON_COMPLETION_PREVIEW].replace(":id", `${quiz.id}`)}`, {state: {isFreshCompletion: true}})
                if (method === "NEW") return navigate(`${PATHS[PAGES.LESSON_COMPLETION].replace(":id", `${quiz.id}`)}`, {state: {isFreshCompletion: true}})
                if (method === "OLD") return navigate(`${PATHS[PAGES.LESSON_COMPLETION].replace(":id", `${quiz.id}`)}`, {state: {isFreshCompletion: false}})
                break
            case QUIZ_TYPES.HOMEWORK:
                if (method === "PREVIEW") return navigate(`${PATHS[PAGES.HOMEWORK_COMPLETION_PREVIEW].replace(":id", `${quiz.id}`)}`, {state: {isFreshCompletion: true}})
                if (method === "NEW") return navigate(`${PATHS[PAGES.HOMEWORK_COMPLETION].replace(":id", `${quiz.id}`)}`, {state: {isFreshCompletion: true}})
                if (method === "OLD") return navigate(`${PATHS[PAGES.HOMEWORK_COMPLETION].replace(":id", `${quiz.id}`)}`, {state: {isFreshCompletion: false}})
                break
            case QUIZ_TYPES.PRACTICAL_ACTIVITY:
                if (method === "PREVIEW") return navigate(`${PATHS[PAGES.PRACTICAL_ACTIVITY_COMPLETION_PREVIEW].replace(":id", `${quiz.id}`)}`, {state: {isFreshCompletion: true}})
                if (method === "NEW") return navigate(`${PATHS[PAGES.PRACTICAL_ACTIVITY_COMPLETION].replace(":id", `${quiz.id}`)}`, {state: {isFreshCompletion: true}})
                if (method === "OLD") return navigate(`${PATHS[PAGES.PRACTICAL_ACTIVITY_COMPLETION].replace(":id", `${quiz.id}`)}`, {state: {isFreshCompletion: false}})
                break
        }
    }

    return {renderListing, createQuiz, redirectToQuiz, startQuizCompletion, changeQuizVisibility}
}