import PropTypes from "prop-types";
import {cn} from "../../../utils.js";

export default function BooleanFilterInput({disabled, name, value, handleChange, type = "boolean"}) {

    return (
        <div className={"relative flex flex-col"}>
            <div className={"pb-1 flex flex-row flex-nowrap justify-start space-x-2"}>
                <button disabled={disabled}
                        onClick={() => handleChange({target: {name: name, value: value === "true" ? null : "true"}}, type, true)}
                        className={cn(value === "true" ? "font-semibold ring-2 ring-dark" : "font-normal", "disabled:cursor-not-allowed px-2 rounded-full focus:outline-none")}>DA
                </button>
                <span>/</span>
                <button disabled={disabled}
                        onClick={() => handleChange({target: {name: name, value: value === "false" ? null : "false"}}, type, true)}
                        className={cn(value === "false" ? "font-semibold ring-2 ring-dark" : "font-normal", "disabled:cursor-not-allowed px-2 rounded-full focus:outline-none")}>NU
                </button>
            </div>
            <div className={"w-full h-[1px] bg-dark rounded-full"}/>
        </div>
    )
}
BooleanFilterInput.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
}