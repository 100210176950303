import {useAuthProvider, useRoutesProvider} from "../../../providers/provider-hooks.jsx";
import {formatUnixTimestamp} from "../../../utils.js";
import BodyCell from "../tables/body-cell.jsx";
import BodyRow from "../tables/body-row.jsx";
import PropTypes from "prop-types";
import {toastOneWarn} from "../../../toast.js";
import {Button} from "../../schadcn-ui/button.jsx";

export default function TableBody({tableData, actionsDisabled}) {
    const {authState} = useAuthProvider()
    const {ROLES} = useRoutesProvider()

    return (
        <tbody className={"bg-light"}>
        {tableData.map((row, rowIdx) => {
            return (
                <BodyRow key={`row-${rowIdx}`} customStyles={"hover:bg-lilac/30"}>
                    {authState.userInfo.role === ROLES.DBEST && (
                        <BodyCell colSpan={1}>
                            <div className={"text-nowrap"}>{row["id"] || ""}</div>
                        </BodyCell>
                    )}

                    {authState.userInfo.role === ROLES.DBEST && (
                        <BodyCell colSpan={1}>
                            <div
                                className={"text-nowrap"}>{row["notification"] && row["notification"]["id"] ? row["notification"]["id"] : ""}</div>
                        </BodyCell>
                    )}

                    {authState.userInfo.role === ROLES.DBEST && (
                        <BodyCell colSpan={1}>
                            <div
                                className={"text-nowrap"}>{row["receiver"] && row["receiver"]["id"] ? row["receiver"]["id"] : ""}</div>
                        </BodyCell>
                    )}

                    <BodyCell colSpan={1}>
                        <div className={"text-nowrap"}>
                            <Button
                                onClick={() => toastOneWarn("Aceasta functionalitate nu a fost implementata.")}
                                className={"px-0 pt-[10px] font-semibold disabled:cursor-not-allowed"}
                                variant={"link"} disabled={actionsDisabled}>
                                Deschide notificarea
                            </Button>
                        </div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div
                            className={"text-nowrap"}>{row["notification"] && row["notification"]["sendAt"] ? formatUnixTimestamp(row["notification"]["sendAt"]) : ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div
                            className={"text-nowrap"}>{row["receiver"] && row["receiver"]["role"] ? row["receiver"]["role"] : ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div className={"text-nowrap"}>{row["readAt"] ? formatUnixTimestamp(row["readAt"]) : ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div className={"text-nowrap"}>
                            {row["isRead"] === true ? "DA" : row["isRead"] === false ? "NU" : ""}
                        </div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div
                            className={"text-nowrap"}>{row["notification"] && row["notification"]["subject"] ? row["notification"]["subject"] : ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div
                            className={"text-nowrap"}>{row["receiver"] && row["receiver"]["nume"] ? row["receiver"]["nume"] : ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div
                            className={"text-nowrap"}>{row["receiver"] && row["receiver"]["email"] ? row["receiver"]["email"] : ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div
                            className={"text-nowrap"}>{row["receiver"] && row["receiver"]["telefon"] ? row["receiver"]["telefon"] : ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div
                            className={"text-nowrap"}>{row["receiver"] && row["receiver"]["school"] && row["receiver"]["school"]["codSe"] ? row["receiver"]["school"]["codSe"] : ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div
                            className={"text-nowrap"}>{row["receiver"] && row["receiver"]["school"] && row["receiver"]["school"]["numeInstitutieInvatamant"] ? row["receiver"]["school"]["numeInstitutieInvatamant"] : ""}</div>
                    </BodyCell>
                </BodyRow>
            )
        })}
        </tbody>
    )
}
TableBody.propTypes = {
    tableData: PropTypes.array.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
}