import {useAuthProvider, useRoutesProvider} from "../../../providers/provider-hooks.jsx";
import {CalendarRangePicker} from "../tables/calendar-range-picker.jsx";
import BooleanFilterInput from "../tables/boolean-filter-input.jsx";
import SearchInput from "../tables/search-input.jsx";
import SortButton from "../tables/sort-button.jsx";
import HeadCell from "../tables/head-cell.jsx";
import HeadRow from "../tables/head-row.jsx";
import {cn} from "../../../utils.js";
import PropTypes from "prop-types";
import {Fragment} from "react";

export default function TableHeaders({actionsDisabled, params, changeParams}) {
    const {authState} = useAuthProvider()
    const {ROLES} = useRoutesProvider()

    return (
        <thead className={cn("font-bold bg-light sticky top-0 z-10")}>
        <HeadRow>
            {authState.userInfo.role !== ROLES.PROFESOR && (
                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <span className={"text-nowrap"}>Actiuni</span>
                    </div>
                </HeadCell>
            )}

            {authState.userInfo.role !== ROLES.PROFESOR && (
                <Fragment>
                    <HeadCell colSpan={1}>
                        <div className="flex items-center space-y-1 py-2">
                            <span className={"text-nowrap"}>Formular de inscriere</span>
                        </div>
                    </HeadCell>
                    <HeadCell colSpan={1}>
                        <div className="flex items-center space-y-1 py-2">
                            <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                        value={params["sort"] || ""} name={"role"}/>
                            <span className={"text-nowrap"}>Rol</span>
                        </div>
                    </HeadCell>
                </Fragment>
            )}

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"isEnabled"}/>
                    <span className={"text-nowrap"}>Activat</span>
                </div>
                <BooleanFilterInput handleChange={changeParams} disabled={actionsDisabled}
                                    name={`isEnabled`} value={params[`isEnabled`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"rejectReason"}/>
                    <span className={"text-nowrap"}>Motiv respingere</span>
                </div>
                <SearchInput placeholder={"Motiv respingere..."} disabled={actionsDisabled}
                             handleChange={changeParams} name={"rejectReason"}
                             value={params["rejectReason"] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"timestamp"}/>
                    <span className={"text-nowrap"}>Data plasare comanda</span>
                </div>
                <CalendarRangePicker handleChange={changeParams} fromName={"timestampRange.start"}
                                     toName={"timestampRange.end"} from={params["timestampRange.start"] || ""}
                                     to={params["timestampRange.end"] || ""} disabled={actionsDisabled}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"telefon"}/>
                    <span className={"text-nowrap"}>Telefon</span>
                </div>
                <SearchInput placeholder={"Telefon..."} disabled={actionsDisabled}
                             handleChange={changeParams} name={"telefon"}
                             value={params["telefon"] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"nume"}/>
                    <span className={"text-nowrap"}>Nume</span>
                </div>
                <SearchInput placeholder={"Nume..."} disabled={actionsDisabled}
                             handleChange={changeParams} name={"nume"}
                             value={params["nume"] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"email"}/>
                    <span className={"text-nowrap"}>Email</span>
                </div>
                <SearchInput placeholder={"Email..."} disabled={actionsDisabled}
                             handleChange={changeParams} name={"email"}
                             value={params["email"] || ""}/>
            </HeadCell>
        </HeadRow>
        </thead>
    )
}
TableHeaders.propTypes = {
    actionsDisabled: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    changeParams: PropTypes.func.isRequired
}