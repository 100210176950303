import FiltersResetButton from "../../components/reports/tables/filters-reset-button.jsx";
import LogisticsDialog from "../../components/reports/orders/logistics-dialog.jsx";
import ApprovalDialog from "../../components/reports/orders/aproval-dialog.jsx";
import RefreshButton from "../../components/reports/tables/refresh-button.jsx";
import TableHeaders from "../../components/reports/orders/table-headers.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import Pagination from "../../components/reports/tables/pagination.jsx";
import TableBody from "../../components/reports/orders/table-body.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import useOrdersReport from "../../hooks/reports/use-orders-report.js";
import ExportButton from "../../components/reports/tables/export-button.jsx";
import ReportDetails from "../../components/reports/tables/report-details.jsx";

export default function Orders() {
    const {
        tableData, printRef, actionsDisabled,
        closeRejectDialog, changeRejectReason, rejectReason,
        logisticsDialogRef, isLogisticsDialogOpened,
        removePhoto, transmitOrder,  currentEdition, editions,
        invalidateOrder, openRejectDialog, pagination, logisticsData,
        logisticsErrors, closeLogisticsDialog, changeLogisticsData,
        openLogisticsDialog, saveLogisticsData, handleImageUpload,
        params, changeParams, renderOrdersReport, dialogRef,
        validateOrder, isDialogOpened, resetFilters,
        exportTable,
    } = useOrdersReport()

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap customStyles={"pr-0 rounded-none overflow-hidden"}>
                    <div
                        className={"pb-4 w-full flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 lg:justify-end lg:items-end"}>
                        <ReportDetails pagination={pagination}/>
                        <FiltersResetButton handleRefresh={resetFilters}
                                            disabled={Object.keys(params).length <= 1 || actionsDisabled}/>
                        <RefreshButton handleRefresh={renderOrdersReport} disabled={actionsDisabled}/>
                        <ExportButton handleExport={exportTable} disabled={actionsDisabled}/>
                    </div>
                    <div className="relative flex-1 pr-4 overflow-auto">
                        <div className={"absolute top-0 left-0 w-full"}>
                            <table ref={printRef} className="w-full h-full relative">
                                <TableHeaders actionsDisabled={actionsDisabled} params={params}
                                              changeParams={changeParams}/>
                                <TableBody
                                    openLogisticsDialog={openLogisticsDialog} openRejectDialog={openRejectDialog}
                                    validateOrder={validateOrder} tableData={tableData} transmitOrder={transmitOrder}
                                    actionsDisabled={actionsDisabled} currentEdition={currentEdition} editions={editions}/>
                            </table>
                        </div>
                    </div>
                    <Pagination pagination={pagination} changeParams={changeParams}
                                actionsDisabled={actionsDisabled} tableData={tableData}/>
                    <ApprovalDialog
                        invalidateOrder={invalidateOrder} changeRejectReason={changeRejectReason}
                        actionsDisabled={actionsDisabled} rejectReason={rejectReason} ref={dialogRef}
                        isDialogOpened={isDialogOpened} closeRejectDialog={closeRejectDialog}/>
                    <LogisticsDialog
                        isLogisticsDialogOpened={isLogisticsDialogOpened} sendLogisticsData={saveLogisticsData}
                        closeLogisticsDialog={closeLogisticsDialog} changeLogisticsData={changeLogisticsData}
                        logisticsData={logisticsData} logisticsErrors={logisticsErrors}
                        handleImageUpload={handleImageUpload} removePhoto={removePhoto}
                        actionsDisabled={actionsDisabled} ref={logisticsDialogRef}/>
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    )
}