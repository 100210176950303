import {APPLICATION_REPORTS, useTableExports} from "./use-table-exports.js";
import {useFetchProvider} from "../../providers/provider-hooks.jsx";
import {toastOneWarn, updateFailureToast} from "../../toast.js";
import {delay, findChangedPath} from "../../utils.js";
import useTableParams from "./use-table-params.js";
import {useEffect, useRef, useState} from "react";
import {useEditions} from "../use-editions.js";
import {Slide, toast} from "react-toastify";
import _ from "lodash";

const DEFAULT_LOGISTICS_DATA = Object.freeze({
    id: "", realDeeeKg: "", realDbaKg: "", realBecuriSiNeoaneKg: "", proceseVerbale: []
})

export default function useOrdersReport() {
    const [logisticsData, setLogisticsData] = useState(JSON.parse(JSON.stringify(DEFAULT_LOGISTICS_DATA)))
    const [isLogisticsDialogOpened, setIsLogisticsDialogOpened] = useState(false)
    const [actionsDisabled, setActionsDisabled] = useState(false)
    const [isDialogOpened, setIsDialogOpened] = useState(false)
    const [oldLogisticsData, setOldLogisticsData] = useState({})
    const [logisticsErrors, setLogisticsErrors] = useState({})
    const [elementInDialog, setElementInDialog] = useState(null)
    const [rejectReason, setRejectReason] = useState("")
    const [tableData, setTableData] = useState([])
    const [pagination, setPagination] = useState({})

    const {params, changeParams, setParams} = useTableParams(renderOrdersReport, {page: 0})
    const {exportTable} = useTableExports(APPLICATION_REPORTS.ORDERS, params, setActionsDisabled)
    const {editions, currentEdition, loadEditions} = useEditions(params, setParams)
    const logisticsDialogRef = useRef(null)
    const dialogRef = useRef(null)
    const printRef = useRef(null)
    const {
        getOrdersReport, patchWasteFormValidation, uploadImage,
        patchLogisticsOrderData, patchOrderTransmitted
    } = useFetchProvider()

    async function transmitOrder (orderId) {
        setActionsDisabled(true)
        const toastId = toast.loading("Informatiile se salveaza...", {transition: Slide});
        try {
            await delay(1000)
            await patchOrderTransmitted(orderId)
            toast.dismiss(toastId)
            await renderOrdersReport()
        } catch (error) {
            console.log(error);
            updateFailureToast(error.message || "Am intampinat o problema. Contactati echipa tehnica.", toastId);
        } finally {
            setActionsDisabled(false)
        }
    }

    async function handleImageUpload(file) {
        try {
            setActionsDisabled(true)
            const uploadedPhoto = await uploadImage(file)
            setLogisticsData(prevState => ({
                ...prevState,
                proceseVerbale: [...prevState.proceseVerbale, uploadedPhoto]
            }));
        } catch (error) {
            console.error(error)
        } finally {
            setActionsDisabled(false)
        }
    }

    function removePhoto(index) {
        setLogisticsData(prevState => ({
            ...prevState,
            proceseVerbale: prevState.proceseVerbale.filter((_, i) => i !== index)
        }));
    }

    async function changeLogisticsData(e) {
        const {name, value} = e.target
        const updatedData = {...logisticsData}
        updatedData[name] = value
        setLogisticsData(updatedData)
    }

    function openLogisticsDialog(order) {
        setOldLogisticsData({})
        setLogisticsData({
            realDeeeKg: order["realDeeeKg"], realDbaKg: order["realDbaKg"],
            realBecuriSiNeoaneKg: order["realBecuriSiNeoaneKg"],
            proceseVerbale: order["proceseVerbale"], id: order["id"]
        })
        setIsLogisticsDialogOpened(true)
        setLogisticsErrors({})
    }

    async function saveLogisticsData() {
        setActionsDisabled(true)
        const toastId = toast.loading("Informatiile se salveaza...", {transition: Slide});
        try {
            await delay(1000)
            const {id, ...params} = logisticsData;
            await patchLogisticsOrderData(id, params)
            toast.dismiss(toastId)
            closeLogisticsDialog()
            await renderOrdersReport()
        } catch (error) {
            console.log(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setLogisticsErrors(error.errors);
                if (error.message) updateFailureToast(error.message, toastId);
                return;
            }
            updateFailureToast("Am intampinat o problema. Contactati echipa tehnica.", toastId);
        } finally {
            setActionsDisabled(false)
        }

    }

    function closeLogisticsDialog() {
        setIsLogisticsDialogOpened(false)
        setOldLogisticsData({})
        setLogisticsData(JSON.parse(JSON.stringify(DEFAULT_LOGISTICS_DATA)))
        setLogisticsErrors({})
    }

    function closeRejectDialog() {
        setIsDialogOpened(false)
        setElementInDialog(null)
        setRejectReason("")
    }

    function openRejectDialog(orderId) {
        setIsDialogOpened(true)
        setElementInDialog(orderId)
    }

    const changeRejectReason = (e) => setRejectReason(e.target.value)

    async function invalidateOrder() {
        setActionsDisabled(true)
        if (!rejectReason) {
            toastOneWarn("Motivul respingerii acestei comenzi nu a fost mentionat.")
            return setActionsDisabled(false)
        }
        try {
            await validateOrder(elementInDialog, false, rejectReason)
            closeRejectDialog()
        } catch (error) {
            console.error(error)
        } finally {
            setActionsDisabled(false)
        }
    }

    async function validateOrder(orderId, isValidated, rejectReason) {
        setActionsDisabled(true)
        let params = {}
        const errorMessage = "Aceasta comanda nu s-a putut valida."
        const toastId = toast.loading("Operatiunea se proceseaza...", {transition: Slide})

        try {
            await delay(1000)
            if (![undefined, null].includes(isValidated) && typeof isValidated === "boolean") params.isValidated = isValidated
            if (![undefined, null, ""].includes(rejectReason) && typeof rejectReason === "string") params.rejectReason = rejectReason
            await patchWasteFormValidation(orderId, params)
            toast.dismiss(toastId)
        } catch (error) {
            console.error(error)
            updateFailureToast(error.message || errorMessage, toastId)
        } finally {
            await renderOrdersReport()
            setActionsDisabled(false)
        }
    }

    async function renderOrdersReport(freshParams) {
        setActionsDisabled(true)
        const errorMessage = "Tabelul nu s-a putut incarca."
        const id = toast.loading("Tabelul se incarca...", {transition: Slide})

        try {
            await delay(1000)
            const data = await getOrdersReport(freshParams || params)
            setTableData(data.content)
            setPagination({totalPages: data.totalPages, totalElements:data.totalElements, ...data["pageable"], first: data.first, last: data.last})
            toast.dismiss(id)
        } catch (error) {
            console.error(error)
            updateFailureToast(error.message || errorMessage, id)
        } finally {
            setActionsDisabled(false)
        }
    }

    async function resetFilters() {
        setParams({page: params.page, editionId: params.editionId});
        await renderOrdersReport({page: params.page, editionId: params.editionId})
    }

    useEffect(() => {
        loadEditions().then(res =>
            renderOrdersReport(res ? {...params, ...res} : {...params}).then())
        const handleClickOutside = (event) => dialogRef.current && !dialogRef.current.contains(event.target) ? closeRejectDialog() : null
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isLogisticsDialogOpened) return
        const changedPath = findChangedPath(logisticsData, oldLogisticsData);
        if (changedPath) {
            const errorPath = Object.keys(logisticsErrors).find(errorKey => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(logisticsErrors));
                delete newErrors[errorPath];
                setLogisticsErrors(newErrors);
            }
            setOldLogisticsData(_.cloneDeep(logisticsData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logisticsData, logisticsErrors]);

    return {
        tableData, printRef, actionsDisabled, resetFilters,
        changeRejectReason, invalidateOrder, openRejectDialog,
        params, changeParams, renderOrdersReport, rejectReason,
        pagination, logisticsDialogRef, isLogisticsDialogOpened,
        validateOrder, dialogRef, isDialogOpened, logisticsData,
        closeRejectDialog, logisticsErrors, closeLogisticsDialog,
        handleImageUpload, removePhoto, transmitOrder, exportTable,
        changeLogisticsData, openLogisticsDialog, saveLogisticsData,
        currentEdition, editions,
    }
}