import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import {getQuizPlaceholder} from "../../../../utils.js";
import {Button} from "../../../schadcn-ui/button.jsx";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";

export default function CompletionSlide({quizType, redirectToListing}) {
    const location = useLocation()

    return (
        <div className={"w-full h-full flex flex-col justify-center items-center"}>
            <FieldWrap customStyles={"w-auto max-w-md animate-fade"}>
                <InputWrap customStyles={"w-auto p-4"}>
                    <span className={"font-semibold text-large text-center"}>
                        Felicitari! Ati {location.pathname.includes("preview") ? "vizualizat" : "finalizat"} cu succes aceasta {getQuizPlaceholder(quizType, 2)} 🎉🎈🎊.
                    </span>
                </InputWrap>
            </FieldWrap>

            <div className={"p-2 max-w-screen-md animate-fade"}>
                <Button className={"h-auto flex w-auto"} onClick={redirectToListing}>
                        <span className={"text-wrap"}>
                            Inapoi la lista cu {getQuizPlaceholder(quizType, 5)}
                        </span>
                </Button>
            </div>
        </div>
    )
}
CompletionSlide.propTypes = {
    quizType: PropTypes.string.isRequired,
    redirectToListing: PropTypes.func.isRequired
}