import {CheckIcon, ChevronRightIcon, DotFilledIcon,} from "@radix-ui/react-icons"
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu"
import PropTypes from "prop-types";
import {cn} from "../../utils.js";
import {forwardRef} from "react";

const DropdownMenu = DropdownMenuPrimitive.Root

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger

const DropdownMenuGroup = DropdownMenuPrimitive.Group

const DropdownMenuPortal = DropdownMenuPrimitive.Portal

const DropdownMenuSub = DropdownMenuPrimitive.Sub

const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup

const DropdownMenuSubTrigger = forwardRef(({className, inset, children, ...props}, ref) => (
    <DropdownMenuPrimitive.SubTrigger ref={ref} className={cn("flex cursor-default select-none items-center rounded-sm px-2 py-1.5 text-small outline-none focus:bg-lilac/30", inset && "pl-8", className)} {...props}>
        {children}
        <ChevronRightIcon className="ml-auto h-4 w-4"/>
    </DropdownMenuPrimitive.SubTrigger>
))
DropdownMenuSubTrigger.displayName = DropdownMenuPrimitive.SubTrigger.displayName
DropdownMenuSubTrigger.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    inset: PropTypes.any,
}

const DropdownMenuSubContent = forwardRef(({className, ...props}, ref) => (
    <DropdownMenuPrimitive.SubContent ref={ref} className={cn("z-40 min-w-[8rem] overflow-hidden rounded-md border border-dark bg-light p-1 text-popover-foreground shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2", className)} {...props}/>
))
DropdownMenuSubContent.displayName = DropdownMenuPrimitive.SubContent.displayName
DropdownMenuSubContent.propTypes = {
    className: PropTypes.string,
}

const DropdownMenuContent = forwardRef(({className, sideOffset = 4, ...props}, ref) => (
    <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content ref={ref} sideOffset={sideOffset} className={cn("z-40 min-w-[8rem] overflow-hidden rounded-md border border-dark bg-light p-1 shadow-md", className)} {...props}/>
    </DropdownMenuPrimitive.Portal>
))
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName
DropdownMenuContent.propTypes = {
    className: PropTypes.string,
    sideOffset: PropTypes.number,
}

const DropdownMenuItem = forwardRef(({className, inset, ...props}, ref) => (
    <DropdownMenuPrimitive.Item ref={ref} className={cn("cursor-pointer flex select-none items-center rounded-sm px-2 py-1.5 text-small outline-none transition-colors focus:bg-lilac/30", inset && "pl-8", className)} {...props}/>
))
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName
DropdownMenuItem.propTypes = {
    className: PropTypes.string,
    inset: PropTypes.any,
}

const DropdownMenuCheckboxItem = forwardRef(({className, children, checked, ...props}, ref) => (
    <DropdownMenuPrimitive.CheckboxItem ref={ref} className={cn("relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-small outline-none transition-colors focus:bg-lilac/30", className)} checked={checked} {...props}>
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <CheckIcon className="h-4 w-4"/>
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
        {children}
    </DropdownMenuPrimitive.CheckboxItem>
))
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName
DropdownMenuCheckboxItem.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    checked: PropTypes.bool
}

const DropdownMenuRadioItem = forwardRef(({className, children, ...props}, ref) => (
    <DropdownMenuPrimitive.RadioItem ref={ref} className={cn(
            "relative flex cursor-default select-none items-center rounded-sm py-1.5 pl-8 pr-2 text-small outline-none transition-colors focus:bg-lilac/30", className)} {...props}>
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <DotFilledIcon className="h-4 w-4 fill-current"/>
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
        {children}
    </DropdownMenuPrimitive.RadioItem>
))
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName
DropdownMenuRadioItem.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
}

const DropdownMenuLabel = forwardRef(({className, inset, ...props}, ref) => (
    <DropdownMenuPrimitive.Label ref={ref} className={cn("px-2 py-1.5 text-small font-semibold", inset && "pl-8", className)} {...props}/>
))
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName
DropdownMenuLabel.propTypes = {
    className: PropTypes.string,
    inset: PropTypes.any
}

const DropdownMenuSeparator = forwardRef(({className, ...props}, ref) => (
    <DropdownMenuPrimitive.Separator ref={ref} className={cn("-mx-1 my-1 h-px bg-dark/30", className)} {...props}/>
))
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName
DropdownMenuSeparator.propTypes = {
    className: PropTypes.string
}
const DropdownMenuShortcut = ({className, ...props}) => (
    <span className={cn("ml-auto text-xs tracking-widest opacity-60", className)}{...props}/>
)
DropdownMenuShortcut.displayName = "DropdownMenuShortcut"
DropdownMenuShortcut.propTypes = {
    className: PropTypes.string
}

export {
    DropdownMenu, DropdownMenuTrigger, DropdownMenuContent,
    DropdownMenuGroup, DropdownMenuPortal, DropdownMenuSub,
    DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuShortcut,
    DropdownMenuItem, DropdownMenuCheckboxItem, DropdownMenuRadioItem,
    DropdownMenuSubContent, DropdownMenuSubTrigger, DropdownMenuRadioGroup,
}
