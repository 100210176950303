import {Button} from "../../schadcn-ui/button.jsx";
import {FaArrowsRotate} from "react-icons/fa6";
import PropTypes from "prop-types";

export default function RefreshButton({handleRefresh, disabled}) {

    return (
        <Button onClick={() => handleRefresh()} disabled={disabled}
                className={"w-full sm:w-auto"}>
            <FaArrowsRotate/>
            <span className={"pl-2"}>Reincarcare</span>
        </Button>
    )
}
RefreshButton.propTypes = {
    handleRefresh: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}