import {FaClock} from "react-icons/fa6";
import {cn} from "../../../../utils.js";
import PropTypes from "prop-types";

export default function PreviewSlideBox({content, customStyles, withClock = false}) {

    return (
        <div
            className={cn("relative select-none bg-white font-semibold justify-center items-center flex rounded-xl border-[2px] border-gray-200 h-full sm:h-auto sm:w-full aspect-square", customStyles)}>
            <span>{content}</span>
            {withClock && (
                <div className={"absolute z-10 -top-1 -right-1"}>
                    <FaClock className={"text-small bg-white text-dark rounded-full"}/>
                </div>
            )}
        </div>
    )
}
PreviewSlideBox.propTypes = {
    withClock: PropTypes.bool,
    customStyles: PropTypes.string,
    content: PropTypes.string.isRequired,
}