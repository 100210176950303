import {Dialog, DialogContent, DialogDescription, DialogTitle} from "../schadcn-ui/dialog.jsx";
import TextInputBox from "../forms/final-input-boxes/text-input-box.jsx";
import {Button} from "../schadcn-ui/button.jsx";
import PropTypes from "prop-types";

export default function PasswordChange({setIsModalOpen, isModalOpen, formData, handleFieldChange, updatePassword, errors}) {

    return (
        <Dialog onOpenChange={setIsModalOpen} open={isModalOpen}>
            <DialogContent className="sm:max-w-[425px]">
                <DialogTitle className={"font-normal p-2"}>Formular de schimbare a parolei</DialogTitle>
                <DialogDescription className={"sr-only"}/>
                <div className={"flex flex-col -mt-4"}>
                    <TextInputBox name={"currentPassword"} label={"Parola Curenta"}
                                  value={formData["currentPassword"] || ""}
                                  errors={errors} handleChange={handleFieldChange}/>
                    <TextInputBox name={"newPassword"} label={"Parola Noua"} value={formData["newPassword"] || ""}
                                  errors={errors} handleChange={handleFieldChange}/>
                    <div
                        className={"p-2 flex w-full flex-col sm:justify-end sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4"}>
                        <Button size={"lg"} onClick={updatePassword}>
                            Salvare modificari
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
PasswordChange.propTypes = {
    handleFieldChange: PropTypes.func.isRequired,
    setIsModalOpen: PropTypes.func.isRequired,
    updatePassword: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    formData: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
}