import { useFetchProvider } from "../../../providers/provider-hooks.jsx";
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { FaCheck, FaTrashCan } from "react-icons/fa6";
import { Button } from "../../schadcn-ui/button.jsx";
import { useRef, useState, Fragment } from 'react';
import { cn } from "../../../utils.js";
import PropTypes from 'prop-types';

export default function SignatureInput({ handleChange, name, value, customStyles, label, disabled }) {
    const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
    const canvasRef = useRef(null);
    const { uploadImage } = useFetchProvider();

    const saveSignature = async () => {
        if (isCanvasEmpty) return;
        try {
            const signatureData = await canvasRef.current.exportImage("png");
            const blob = dataURItoBlob(signatureData);
            const file = new File([blob], "signature.png", { type: blob.type });
            const image = await uploadImage(file);
            handleChange({ target: { name: name, value: image } });
        } catch (error) {
            console.error("Error exporting or uploading signature:", error);
        }
    };

    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    const clearSignature = () => {
        canvasRef.current.clearCanvas();
        setIsCanvasEmpty(true);
        handleChange({ target: { name: name, value: "" } });
    };

    const handleStroke = () => {
        setIsCanvasEmpty(false);
    };

    const handleEnd = async () => {
        const currentPaths = await canvasRef.current.exportPaths();
        setIsCanvasEmpty(currentPaths.length === 0);
    };

    return (
        <Fragment>
            <label htmlFor={name}
                   className={cn(customStyles, "text-gray-400 w-full text-center font-bold select-none")}>
                {label}
            </label>
            <div className="m-0 h-[300px] relative">
                <ReactSketchCanvas
                    ref={canvasRef} strokeColor="black" height="100%"
                    strokeWidth={4} canvasColor="white" width="100%"
                    style={{ border: '0px solid #000', borderRadius: '4px' }}
                    className={cn((value || disabled) ? "cursor-not-allowed pointer-events-none" : "")}
                    onStroke={handleStroke} onStrokeEnd={handleEnd} />
                <div className="absolute z-10 bottom-1.5 right-0">
                    {!isCanvasEmpty && !value && (
                        <Button type="button" variant={"ghost"} className={"text-magenta hover:text-white"}
                                onClick={saveSignature}>
                            <FaCheck />
                        </Button>
                    )}
                    {(!isCanvasEmpty || value) && (
                        <Button type="button" variant={"ghost"} onClick={clearSignature}>
                            <FaTrashCan />
                        </Button>
                    )}
                </div>
            </div>
        </Fragment>
    );
}

SignatureInput.propTypes = {
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    customStyles: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
};
