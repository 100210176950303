import {useAuthProvider, useRoutesProvider} from "../../../providers/provider-hooks.jsx";
import {FaMagnifyingGlass, FaXmark} from "react-icons/fa6";
import {Button} from "../../schadcn-ui/button.jsx";
import {forwardRef, Fragment} from "react";
import PropTypes from "prop-types";

const QuizDialog = forwardRef(({
                                   closeQuizDialog, openRejectDialog, validateQuiz, quizData,
                                   isQuizDialogOpened, quizAnswers, elementInDialog
                               }, ref) => {
    const {authState} = useAuthProvider()
    const {ROLES} = useRoutesProvider()

    return (
        <Fragment>
            {isQuizDialogOpened && (
                <div
                    className="animate-fade animate-duration-200 cursor-default fixed left-0 top-0 w-screen h-screen flex items-center justify-center z-40 bg-black/50 backdrop-blur-sm">
                    <div ref={ref}
                         className="bg-light flex flex-col p-4 overflow-hidden rounded-lg shadow-lg relative min-w-[40vw] max-w-[80vw] max-h-[80vh]">
                        <div className={"relative flex justify-end pb-4"}>
                            <button onClick={() => closeQuizDialog()} className="">
                                <FaXmark/>
                            </button>
                        </div>
                        <div
                            className={"flex flex-col space-y-4 pr-4 relative select-text h-full w-full overflow-y-scroll min-w-[40vw] max-w-[80vw] max-h-[80vh]"}>
                            {Array.isArray(quizData) && quizData.length === 0 && (
                                <span className={"font-semibold"}>Nu a fost solicitata incarcarea pozelor la aceasta activitate practica.</span>
                            )}
                            {Array.isArray(quizData) && quizData.length > 0 && quizData.map((question, questionIdx) => {
                                const photos = Array.isArray(quizAnswers) ? quizAnswers.find(q => q.slideId === question.id)?.photos : null
                                return (
                                    <div key={questionIdx}
                                         className={"flex flex-col spacey-4 p-4 border-2 border-magenta bg-white rounded-md shadow-md"}>
                                        <span className={"font-semibold"}>{question.text}</span>
                                        {Array.isArray(photos) && photos.length > 0 ? (
                                            <div className="flex flex-wrap">
                                                {photos.map((photo, index) => (
                                                    <div key={index}
                                                         className="relative w-auto object-contain h-32 m-2">
                                                        <img src={photo} alt=""
                                                             className="w-full h-full object-cover cursor-default"/>
                                                        <button onClick={() => window.open(photo, "_blank")}
                                                                className="absolute top-1 right-1 bg-white bg-opacity-50 text-dark p-1 rounded-full">
                                                            <FaMagnifyingGlass/>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <span>Nu au fost incarcate poze pentru aceasta intrebare.</span>
                                        )}
                                    </div>
                                )
                            })}
                            {[ROLES.ADMIN, ROLES.DBEST].includes(authState.userInfo.role) && (
                                <div className={"flex flex-col space-y-4"}>
                                    <Button onClick={() => validateQuiz(elementInDialog, true, "")}>
                                        Aprobare
                                    </Button>
                                    <Button variant={"destructive"}
                                            onClick={() => openRejectDialog(elementInDialog)}>
                                        Respingere
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
})
QuizDialog.displayName = 'QuizDialog';
QuizDialog.propTypes = {
    quizData: PropTypes.object,
    quizAnswers: PropTypes.array,
    validateQuiz: PropTypes.func.isRequired,
    closeQuizDialog: PropTypes.func.isRequired,
    openRejectDialog: PropTypes.func.isRequired,
    elementInDialog: PropTypes.string,
    isQuizDialogOpened: PropTypes.bool.isRequired,
};

export default QuizDialog;