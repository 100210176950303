import MarkdownPreview from "../../components/forms/raw-inputs/preview-markdown.jsx";
import PreviewTextBox from "../../components/quizzes/creation/preview-text-box.jsx";
import Pagination from "../../components/reports/tables/pagination.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import {useInboxProvider} from "../../providers/provider-hooks.jsx";
import {Button} from "../../components/schadcn-ui/button.jsx";
import {cn, formatUnixTimestamp} from "../../utils.js";
import {useEffect, useMemo, useRef} from "react";
import {FaXmark} from "react-icons/fa6";

export default function NotificationsReceived() {
    const {
        notifications, readNotification, openedNotification,
        setOpenedNotification, pagination, changeParams,
        actionsDisabled
    } = useInboxProvider();
    const unreadNotifications = useMemo(() => notifications.length ? notifications.filter(n => n["isRead"] === false).length : 0, [notifications]);
    const dialogRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => dialogRef.current && !dialogRef.current.contains(event.target) ? setOpenedNotification("") : null
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap>
                    <div className={"flex flex-col"}>
                        <div>
                            <PreviewTextBox text={`Inbox ${unreadNotifications > 0 ? (unreadNotifications === 1 ? " - 1 notificare necititia" : ` - ${unreadNotifications} notificari necitite`) : ""}`} />
                        </div>
                        <div className="space-y-4 overflow-x-hidden p-2">
                            {Array.isArray(notifications) && notifications.length > 0 && notifications.map((notification, notificationIdx) => (
                                <div key={notification["id"].toString()}
                                     style={{animationDelay: `${notificationIdx * 100}ms`}}
                                     className={cn(!notification["isRead"] ? "border-red-500" : "border-green-500", "animate-fade p-4 flex flex-col bg-white shadow-md rounded-lg border-2")}>
                                    <h2 className="text-xl font-semibold">{notification["subject"]}</h2>
                                    <span className="text-sm text-gray-500 mt-2">
                                        Data primire: {formatUnixTimestamp(notification["sendAt"].toString())}
                                    </span>
                                    <span
                                        className={cn(!notification["isRead"] ? "text-red-500" : "text-green-500", "text-sm mt-2")}>
                                        {!notification["isRead"] ? "NECITIT" : "CITIT"}
                                    </span>
                                    <div className={"flex w-full justify-end"}>
                                        <Button variant={"link"} onClick={() => readNotification(notification["id"])}>
                                            Deschide
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <Pagination pagination={pagination} changeParams={changeParams}
                                actionsDisabled={actionsDisabled} tableData={notifications}/>

                    {openedNotification && (
                        <div
                            className="animate-fade animate-duration-200 cursor-default fixed left-0 top-0 w-screen h-screen flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm">
                            <div ref={dialogRef} className="bg-white flex flex-col p-4 overflow-y-hidden rounded-lg shadow-lg relative min-w-[40vw] max-w-[80vw] max-h-[80vh]">
                                <div className={"relative flex justify-end pb-4"}>
                                    <button onClick={() => setOpenedNotification("")} className="">
                                        <FaXmark/>
                                    </button>
                                </div>
                                <div className={"relative select-text flex h-full w-full pr-4 overflow-y-scroll min-w-[40vw] max-w-[80vw] max-h-[80vh]"}>
                                    <MarkdownPreview text={openedNotification}/>
                                </div>
                            </div>
                        </div>
                    )}
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    );
}
