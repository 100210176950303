import PropTypes from "prop-types";
import {cn} from "../../../utils.js";

export default function PreviewTextBox({text, customStyles}) {

    return (
        <div className={cn("p-2 w-full text-xl font-bold text-gray-600 flex items-center justify-center min-h-[50px] h-full", customStyles)}>
            <span className={"markdown text-center"}>{text || ""}</span>
        </div>
    )
}
PreviewTextBox.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    customStyles: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
}