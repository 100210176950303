import PropTypes from "prop-types";

export default function SearchInput({placeholder, disabled, name, value, handleChange, type = null}) {

    return (
        <div className={"relative flex flex-col"}>
            <div className={"pb-1"}>
                <input placeholder={placeholder} disabled={disabled} name={name} autoComplete={"off"}
                       value={value} onChange={(e) => handleChange(e, type)}
                       className={"disabled:cursor-not-allowed w-full flex focus:outline-none bg-transparent placeholder:text-dark/60"}/>
            </div>
            <div className={"w-full h-[1px] bg-dark rounded-full"}/>
        </div>
    )
}
SearchInput.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
}