import * as DialogPrimitive from "@radix-ui/react-dialog"
import {Cross2Icon} from "@radix-ui/react-icons"
import {forwardRef} from "react";
import {cn} from "../../utils.js";
import PropTypes from "prop-types";


const Dialog = DialogPrimitive.Root

const DialogTrigger = DialogPrimitive.Trigger

const DialogPortal = DialogPrimitive.Portal

const DialogClose = DialogPrimitive.Close

const DialogOverlay = forwardRef(({className, ...props}, ref) => (
    <DialogPrimitive.Overlay ref={ref} className={cn("fixed inset-0 z-50 bg-dark/80", className)} {...props}/>
))
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName
DialogOverlay.propTypes = {
    className: PropTypes.string
}

const DialogContent = forwardRef(({className, children, ...props}, ref) => (
    <DialogPortal>
        <DialogOverlay/>
        <DialogPrimitive.Content ref={ref} className={cn("fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 border border-dark bg-light p-6 shadow-lg duration-200 sm:rounded-lg", className)} {...props}>
            {children}
            <DialogPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-light transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none">
                <Cross2Icon className="h-4 w-4"/>
                <span className="sr-only">Close</span>
            </DialogPrimitive.Close>
        </DialogPrimitive.Content>
    </DialogPortal>
))
DialogContent.displayName = DialogPrimitive.Content.displayName
DialogContent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
}

const DialogHeader = ({className, ...props}) => (
    <div className={cn("flex flex-col space-y-1.5 text-center sm:text-left", className)} {...props}/>
)
DialogHeader.displayName = "DialogHeader"
DialogHeader.propTypes = {
    className: PropTypes.string
}

const DialogFooter = ({className, ...props}) => (
    <div className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)} {...props}/>
)
DialogFooter.displayName = "DialogFooter"
DialogFooter.propTypes = {
    className: PropTypes.string
}

const DialogTitle = forwardRef(({className, ...props}, ref) => (
    <DialogPrimitive.Title ref={ref} className={cn("text-lg font-semibold leading-none tracking-tight", className)} {...props}/>
))
DialogTitle.displayName = DialogPrimitive.Title.displayName
DialogTitle.propTypes = {
    className: PropTypes.string
}

const DialogDescription = forwardRef(({className, ...props}, ref) => (
    <DialogPrimitive.Description ref={ref} className={cn("text-sm", className)} {...props}/>
))
DialogDescription.displayName = DialogPrimitive.Description.displayName
DialogDescription.propTypes = {
    className: PropTypes.string
}

export {
    Dialog, DialogPortal, DialogOverlay, DialogTrigger,
    DialogFooter, DialogTitle, DialogDescription,
    DialogClose, DialogContent, DialogHeader,
}
