import {checkAnyErrorForPath, cn, retrieveErrorForPath} from "../../../utils.js";
import SignatureInput from "../raw-inputs/signature-input.jsx";
import ErrorSpan from "../../layout/error-span.jsx";
import FieldWrap from "../wraps/field-wrap.jsx";
import InputWrap from "../wraps/input-wrap.jsx";
import PropTypes from "prop-types";


export default function SignatureInputBox({
                                            value, name, errors, handleChange, customErrorStyles,
                                            customInputWrapStyles, customFieldWrapStyles, label,
                                            disabled,
                                        }) {

    return (
        <FieldWrap customStyles={customFieldWrapStyles}>
            <InputWrap
                customStyles={cn(checkAnyErrorForPath(name, errors) ? "border-red-600" : "", customInputWrapStyles)}>
                <SignatureInput name={name} label={label} value={value} handleChange={handleChange} disabled={disabled}/>
                <ErrorSpan text={retrieveErrorForPath(name, errors)}
                           customStyles={cn(checkAnyErrorForPath(name, errors) ? "flex" : "hidden", customErrorStyles)}/>
            </InputWrap>
        </FieldWrap>
    )
}
SignatureInputBox.propTypes = {
    value: PropTypes.string,
    errors: PropTypes.object,
    disabled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    customErrorStyles: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    customInputWrapStyles: PropTypes.string,
    customFieldWrapStyles: PropTypes.string,
}