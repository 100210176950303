import {toastOneWarn, updateFailureToast, updateSuccessToast} from "../../toast.js";
import {useFetchProvider} from "../../providers/provider-hooks.jsx";
import useTableParams from "../reports/use-table-params.js";
import {useEffect, useRef, useState} from "react";
import {Slide, toast} from "react-toastify";
import {delay} from "../../utils.js";

export default function useNotificationsSent() {
    const [notificationDetails, setNotificationDetails] = useState([])
    const [openedNotification, setOpenedNotification] = useState("")
    const [actionsDisabled, setActionsDisabled] = useState(false)
    const [sentNotifications, setNotifications] = useState([]);
    const notificationDetailsRef = useRef(null);
    const openedNotificationRef = useRef(null);
    const [pagination, setPagination] = useState({})

    const {params, changeParams} = useTableParams(renderNotifications, {page: 0})
    const {getSentNotifications, postNotificationResend, getNotificationReceivers} = useFetchProvider();

    async function readNotification(id) {
        const targetNotification = sentNotifications.find(notif => notif.id === id)?.content
        if (!targetNotification) return toastOneWarn("Nu exista continut pentru aceasta notificare.")
        setOpenedNotification(targetNotification)
    }

    async function openDetailedNotificationStatus(id) {
        const toastId = toast.loading(`Detaliile despre aceasta notificare se incarca.`, {transition: Slide})
        const errorMessage = "Detaliile despre aceasta notificare nu s-au putut incarca. Va rugam sa incercati mai tarziu."
        const warnMessage = "Nu exista detalii despre aceasta notificare"
        try {
            await delay(1000)
            const receivers = await getNotificationReceivers(id)
            if (receivers.length === 0) return updateFailureToast(warnMessage, toastId)
            setNotificationDetails(receivers)
            toast.dismiss(toastId)
        } catch (error) {
            console.error(error)
            updateFailureToast(error.message || errorMessage, toastId)
        }
    }

    async function resendNotification(id) {
        const toastId = toast.loading(`Notificarea se trimite`, {transition: Slide})
        const errorMessage = "Notificarea nu a putut fi retrimisa. Va rugam sa incercati mai tarziu."
        const successMessage = "Notificarea a fost retrimisa."
        try {
            await delay(1000)
            await postNotificationResend(id)
            updateSuccessToast(successMessage, toastId)
        } catch (error) {
            console.error(error)
            updateFailureToast(error.message || errorMessage, toastId)
        }
    }

    async function renderNotifications(freshParams) {
        const id = toast.loading(`Lista cu notificari trimise se incarca`, {transition: Slide})
        const errorMessage = "Am intampinat o problema tehnica. Va rugam sa incercati mai tarziu."
        setActionsDisabled(true)
        try {
            await delay(1000)
            const data = await getSentNotifications(freshParams || params)
            if (data.content.length === 0) {
                updateFailureToast("Nu exista notificari trimise", id)
            } else {
                setNotifications(data.content)
                setPagination({totalPages: data.totalPages, ...data["pageable"], first: data.first, last: data.last})
                toast.dismiss(id)
            }
        } catch (error) {
            console.error(error)
            updateFailureToast(error.message || errorMessage, id)
        } finally {
            setActionsDisabled(false)
        }
    }

    useEffect(() => {
        renderNotifications().then();
        const handleClickOutside = (event) => {
            if ((openedNotificationRef.current && !openedNotificationRef.current.contains(event.target)) || (notificationDetailsRef.current && !notificationDetailsRef.current.contains(event.target))) {
                setOpenedNotification("")
                setNotificationDetails([])
            }
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };  // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        sentNotifications, readNotification, openedNotification,
        setOpenedNotification, openDetailedNotificationStatus,
        setNotificationDetails, notificationDetails,
        openedNotificationRef, resendNotification,
        pagination, changeParams, actionsDisabled
    }
}