import InvalidQuizId from "../../components/quizzes/completion/invalid-quiz-id.jsx";
import ValidQuizId from "../../components/quizzes/completion/valid-quiz-id.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import useQuizCompletion from "../../hooks/quizzes/use-quiz-completion.js";
import PropTypes from "prop-types";

export default function QuizCompletion({quizType}) {
    const {
        isQuizIdValid, redirectToListing, quizForm, quizAnswers,
        handleDZClick, removePhoto, actionsDisabled,
        currentSlide, saveAndMoveToAvailableSlide, currentAnswer,
        addOrRemoveOption, isQuizFinished, handleImageUpload,
    } = useQuizCompletion(quizType)

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap withoutSideMenu={true} withoutNav={true}>
                {isQuizIdValid === false && <InvalidQuizId quizType={quizType} redirectToListing={redirectToListing}/>}
                {isQuizIdValid === true && (
                    <ValidQuizId quizForm={quizForm} quizAnswers={quizAnswers} currentSlide={currentSlide}
                                 redirectToListing={redirectToListing} handleImageUpload={handleImageUpload}
                                 addOrRemoveOption={addOrRemoveOption} isQuizFinished={isQuizFinished}
                                 saveAndMoveToAvailableSlide={saveAndMoveToAvailableSlide}
                                 handleDZClick={handleDZClick} removePhoto={removePhoto}
                                 quizType={quizType} currentAnswer={currentAnswer}
                                 actionsDisabled={actionsDisabled}/>
                )}
            </NavBarWrap>
        </BasePageWrap>
    )
}
QuizCompletion.propTypes = {
    quizType: PropTypes.string.isRequired
}