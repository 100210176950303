import {useAuthProvider, useFetchProvider, useRoutesProvider} from "../../providers/provider-hooks.jsx";
import {updateFailureToast, updateSuccessToast} from "../../toast.js";
import {delay, findChangedPath} from "../../utils.js";
import {useNavigate} from "react-router-dom";
import {Slide, toast} from "react-toastify";
import {useEffect, useState} from "react";
import _ from "lodash";

const DEFAULT_FORM = Object.freeze({
    nume: "", email: "", parola: "", confirmaParola: "",
    role: "", telefon: "", codProfesor: "", clasa: "",
})

export default function useRegister(forRole) {
    const [registrationData, setRegistrationData] = useState({...JSON.parse(JSON.stringify(DEFAULT_FORM)), role:forRole});
    const [oldRegistrationData, setOldRegistrationData] = useState({...JSON.parse(JSON.stringify(DEFAULT_FORM)), role:forRole});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})

    const {isTokenValid, setAuthInfo, updateRefreshToken} = useAuthProvider()
    const {publicFetch} = useFetchProvider()
    const {PATHS, PAGES, ROLES} = useRoutesProvider()

    const navigate = useNavigate()

    async function registerUser(event) {
        event.preventDefault()
        setLoading(true);

        const successMessage = "V-ati inregistrat cu succes!";
        const standardError = "Am intamplinat probleme la inregistrare.";
        const passwordError = "Parola nu se potrivește cu confirmarea parolei."
        const id = toast.loading("Se incarca...", {transition: Slide});
        try {
            await delay(1000)
            if (registrationData.parola !== registrationData.confirmaParola) throw {response: {data: {message: standardError, errors: {"confirmaParola": passwordError}}}}
            const payload = JSON.parse(JSON.stringify(registrationData))
            if (!payload.schoolId) payload.schoolId = null
            if (!payload.telefon) payload.telefon = null
            if (!payload.clasa) payload.clasa = null
            if (!payload.nume) payload.nume = null
            delete payload.confirmaParola
            const {data} = await publicFetch.post("/auth/register", payload);
            const {token, refreshToken} = data
            if (!isTokenValid(token)) return updateFailureToast(standardError, id)
            if (!token) return updateFailureToast(standardError, id)
            updateRefreshToken(refreshToken)
            setAuthInfo(token)
            updateSuccessToast(successMessage, id);
            await delay(500);
            return navigate("/")
        } catch (error) {
            console.log(error);
            if (error.response.data.message || error.response.data.errors) {
                if (error.response.data.message) updateFailureToast(error.response.data.message, id);
                if (error.response.data.errors && Object.keys(error.response.data.errors).length > 0) setErrors(error.response.data.errors);
                return;
            }
            updateFailureToast(standardError, id);
        } finally {
            setLoading(false)
            toast.dismiss(id)
        }
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        const updatedData = {...registrationData}
        if (name === "role" && value === ROLES.PROFESOR) {
            updatedData.codProfesor = ""
        } else if (name === "role" && value === ROLES.ELEV) {
            updatedData.nume = ""
            updatedData.telefon = ""
        }
        updatedData[name] = value
        setRegistrationData(updatedData)
    };

    const redirectToLogIn = (role) => {
        if (ROLES.PROFESOR === role) return navigate(PATHS[PAGES.TEACHER_AUTH])
        if (ROLES.ELEV === role) return navigate(PATHS[PAGES.PUPIL_AUTH])
    }

    useEffect(() => {
        const changedPath = findChangedPath(registrationData, oldRegistrationData);
        if (changedPath) {
            const errorPath = Object.keys(errors).find(errorKey => errorKey === changedPath);
            if (errorPath) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                delete newErrors[errorPath];
                setErrors(newErrors);
            }
            setOldRegistrationData(_.cloneDeep(registrationData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registrationData, errors]);

    return {
        redirectToLogIn, handleChange, loading,
        errors, registerUser, registrationData,
    }
}