import BooleanInputBox from "../../components/forms/final-input-boxes/boolean-input-box.jsx";
import NumberInputBox from "../../components/forms/final-input-boxes/number-input-box.jsx";
import TextInputBox from "../../components/forms/final-input-boxes/text-input-box.jsx";
import DateInputBox from "../../components/forms/final-input-boxes/date-input-box.jsx";
import PreviewTextBox from "../../components/quizzes/creation/preview-text-box.jsx";
import useWasteCollectionForm from "../../hooks/forms/use-waste-collection-form.js";
import {checkAnyErrorForPath, cn, formatUnixTimestamp, retrieveErrorForPath} from "../../utils.js";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import FieldWrap from "../../components/forms/wraps/field-wrap.jsx";
import InputWrap from "../../components/forms/wraps/input-wrap.jsx";
import {FaImage, FaRotate, FaTrashCan} from "react-icons/fa6";
import ErrorSpan from "../../components/layout/error-span.jsx";
import {Button} from "../../components/schadcn-ui/button.jsx";
import Dropzone from "react-dropzone";
import {Fragment} from "react";

export default function WasteCollection() {

    const {
        renderWasteForm, handleStandardFieldChange, handleDZClick,
        handleImageUpload, errors, formData, removePhoto,
        saveWasteCollectionForm, handleAddressFieldChange,
    } = useWasteCollectionForm()

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap>
                    <div>
                        <PreviewTextBox text={"Formular de preluare a deseurilor"}/>
                    </div>

                    {Object.keys(formData).length > 0 && (
                        <Fragment>
                            <TextInputBox name={"contactPersonName"} label={"Denumire Persoana Contact"}
                                          value={formData["contactPersonName"] || ""}
                                          errors={errors} handleChange={handleStandardFieldChange}/>
                            <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                <TextInputBox name={"contactPersonEmail"} label={"Email Persoana Contact"}
                                              value={formData["contactPersonEmail"] || ""}
                                              errors={errors} handleChange={handleStandardFieldChange}/>
                                <TextInputBox name={"contactPersonPhone"} label={"Telefon Persoana Contact"}
                                              value={formData["contactPersonPhone"] || ""}
                                              errors={errors} handleChange={handleStandardFieldChange}/>
                            </div>
                            <DateInputBox name={"dataOrientativaColectare"} label={"Data orientativa de ridicare"}
                                          value={formatUnixTimestamp(formData["dataOrientativaColectare"]) || ""} disabled={true}
                                          errors={errors} handleChange={handleStandardFieldChange}/>

                            <BooleanInputBox handleChange={handleStandardFieldChange} errors={errors}
                                             name={"pickupAddressSameWithSchoolAddress"}
                                             value={formData["pickupAddressSameWithSchoolAddress"]}
                                             label={"Adresa de colectare corespunde cu adresa scolii?"}/>

                            {formData["pickupAddressSameWithSchoolAddress"] !== null && (
                                <Fragment>
                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox name={"pickupAddress.judet"} label={"Judet"}
                                                      disabled={!!formData["pickupAddressSameWithSchoolAddress"]}
                                                      value={formData["pickupAddress"]["judet"] || ""}
                                                      errors={errors} handleChange={handleAddressFieldChange}/>
                                        <TextInputBox name={"pickupAddress.oras"} label={"Oras"}
                                                      disabled={!!formData["pickupAddressSameWithSchoolAddress"]}
                                                      value={formData["pickupAddress"]["oras"] || ""}
                                                      errors={errors} handleChange={handleAddressFieldChange}/>
                                    </div>
                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox name={"pickupAddress.comuna"} label={"Comuna"}
                                                      disabled={!!formData["pickupAddressSameWithSchoolAddress"]}
                                                      value={formData["pickupAddress"]["comuna"] || ""}
                                                      errors={errors} handleChange={handleAddressFieldChange}/>
                                        <TextInputBox name={"pickupAddress.sat"} label={"Sat"}
                                                      disabled={!!formData["pickupAddressSameWithSchoolAddress"]}
                                                      value={formData["pickupAddress"]["sat"] || ""}
                                                      errors={errors} handleChange={handleAddressFieldChange}/>
                                    </div>
                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox name={"pickupAddress.strada"} label={"Strada"}
                                                      disabled={!!formData["pickupAddressSameWithSchoolAddress"]}
                                                      value={formData["pickupAddress"]["strada"] || ""}
                                                      errors={errors} handleChange={handleAddressFieldChange}/>
                                        <TextInputBox name={"pickupAddress.numar"} label={"Numar"}
                                                      disabled={!!formData["pickupAddressSameWithSchoolAddress"]}
                                                      value={formData["pickupAddress"]["numar"] || ""}
                                                      errors={errors} handleChange={handleAddressFieldChange}/>
                                    </div>

                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox name={"pickupAddress.bloc"} label={"Bloc"}
                                                      disabled={!!formData["pickupAddressSameWithSchoolAddress"]}
                                                      value={formData["pickupAddress"]["bloc"] || ""}
                                                      errors={errors} handleChange={handleAddressFieldChange}/>
                                        <TextInputBox name={"pickupAddress.scara"} label={"Scara"}
                                                      disabled={!!formData["pickupAddressSameWithSchoolAddress"]}
                                                      value={formData["pickupAddress"]["scara"] || ""}
                                                      errors={errors} handleChange={handleAddressFieldChange}/>
                                    </div>

                                    <div className={"flex flex-col sm:flex-row md:flex-col lg:flex-row"}>
                                        <TextInputBox name={"pickupAddress.etaj"} label={"Etaj"}
                                                      disabled={!!formData["pickupAddressSameWithSchoolAddress"]}
                                                      value={formData["pickupAddress"]["etaj"] || ""}
                                                      errors={errors} handleChange={handleAddressFieldChange}/>
                                        <TextInputBox name={"pickupAddress.apartament"} label={"Apartament"}
                                                      disabled={!!formData["pickupAddressSameWithSchoolAddress"]}
                                                      value={formData["pickupAddress"]["apartament"] || ""}
                                                      errors={errors} handleChange={handleAddressFieldChange}/>
                                    </div>

                                    <TextInputBox name={"pickupAddress.codPostal"} label={"Cod postal"}
                                                  disabled={!!formData["pickupAddressSameWithSchoolAddress"]}
                                                  value={formData["pickupAddress"]["codPostal"] || ""}
                                                  errors={errors} handleChange={handleAddressFieldChange}/>
                                </Fragment>
                            )}

                            <NumberInputBox name={"deeeKg"} label={"Kilograme de DEEE"}
                                            value={formData["deeeKg"] || ""}
                                            errors={errors} handleChange={handleStandardFieldChange}/>
                            <NumberInputBox name={"dbaKg"} label={"Kilograme de DBA"}
                                            value={formData["dbaKg"] || ""}
                                            errors={errors} handleChange={handleStandardFieldChange}/>
                            <NumberInputBox name={"becuriSiNeoaneKg"} label={"Kilograme de becuri si neoane"}
                                            value={formData["becuriSiNeoaneKg"] || ""}
                                            errors={errors} handleChange={handleStandardFieldChange}/>
                            <FieldWrap customStyles={"animate-fade"}>
                                <div className="flex flex-1 h-full justify-center items-center">
                                    <InputWrap
                                        customStyles={cn(checkAnyErrorForPath(`photos`, errors) ? "border-red-600" : "p-0")}>
                                        <div onClick={handleDZClick}
                                             className={cn("group rounded-[5px] relative cursor-pointer flex flex-1 flex-col items-center justify-center w-full h-full text-magenta/80 hover:bg-magenta/80 hover:text-white transition duration-500 text-center")}>
                                            <Dropzone onDrop={handleImageUpload}>
                                                {({getRootProps, getInputProps}) => (
                                                    <div className="dz-message needsclick;" {...getRootProps()}>
                                                        <input multiple={true}
                                                               id="question-image-upload" {...getInputProps({accept: 'image/*'})} />
                                                    </div>
                                                )}
                                            </Dropzone>
                                            {Array.isArray(formData["photos"]) && formData["photos"].length > 0 ? (
                                                <div className="flex flex-wrap">
                                                    {formData["photos"].map((photo, index) => (
                                                        <div key={index} className="relative w-32 h-32 m-2">
                                                            <img src={photo} alt={`Uploaded ${index}`}
                                                                 className="w-full h-full object-cover cursor-default"/>
                                                            <button onClick={(e) => {
                                                                e.stopPropagation()
                                                                removePhoto(index)
                                                            }}
                                                                    className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full">
                                                                <FaTrashCan/>
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <div className={"flex flex-col space-x-4 p-4 items-center"}>
                                                    <FaImage style={{fontSize: "2.3em"}}/>
                                                    <p className="m-0 text-lg text-gray-400 font-semibold group-hover:text-white transition duration-500">
                                                    Atasati poze cu deseurile
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                        <ErrorSpan text={retrieveErrorForPath(`photos`, errors)}
                                                   customStyles={cn(checkAnyErrorForPath(`photos`, errors) ? "flex" : "hidden")}/>
                                    </InputWrap>
                                </div>
                            </FieldWrap>
                            <BooleanInputBox handleChange={handleStandardFieldChange} errors={errors}
                                             name={"consent"} value={formData["consent"]}
                                             label={"Prin prezenta confirm că am citit Informarea cu privire la prelucrarea datelor mele cu caracter personal prezentata pe www.ecotic.ro/politica-de-confidentialitate-si-de-prelucrare-datelor-cu-caracter-personal"}/>
                            <div
                                className={"p-2 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4 items-center w-full"}>
                                <Button className={"w-full sm:w-auto"} onClick={() => renderWasteForm()}>
                                    <FaRotate className={"mr-2"}/>
                                    <span>Reincarcare</span>
                                </Button>
                                <Button className={"w-full sm:w-auto"}
                                        onClick={() => saveWasteCollectionForm()}>
                                    <span>Salvare</span>
                                </Button>
                            </div>
                        </Fragment>
                    )}
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    )
}