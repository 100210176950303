import {Drawer, DrawerContent, DrawerDescription, DrawerTitle, DrawerTrigger} from "../schadcn-ui/drawer.jsx";
import useIsDesktopViewPort from "../../hooks/use-is-desktop-viewport.js";
import {FaCaretRight} from "react-icons/fa6";
import SideMenu from "./side-menu.jsx";
import TopNavBar from "./top-nav-bar.jsx";
import PropTypes from "prop-types";
import {cn} from "../../utils.js";
import {useState} from "react";

export default function NavBarWrap({children, withoutSideMenu = false, withoutNav = false}) {
    const {isDesktopViewPort} = useIsDesktopViewPort()
    const [navVisible, setNavVisible] = useState(false);

    const closeNav = () => setNavVisible(false)

    return (
        <div
            className="flex flex-col overflow-hidden h-screen min-w-screen max-w-screen min-h-screen max-h-screen w-full p-4">
            {!withoutNav && <TopNavBar/>}
            <div className={cn("relative flex h-full w-full overflow-hidden")}>
                {isDesktopViewPort && !withoutSideMenu && (
                    <div
                        style={{backgroundSize: "400px"}}
                        className="flex mr-4 rounded-md border border-lime-700 max-w-[250px] w-[250px] min-w-[250px] focus:outline-none focus:border-0 bg-repeat bg-[url('/bg-banner.jpg')]">
                        <div className={"bg-white w-full h-full py-4 pl-4 bg-opacity-30 rounded-md"}>
                            <SideMenu navVisible={navVisible} closeNav={closeNav}/>
                        </div>
                    </div>
                )}
                {!isDesktopViewPort && !withoutSideMenu && (
                    <Drawer open={navVisible} onOpenChange={setNavVisible} direction="left">
                        <DrawerTrigger asChild>
                            <button
                                className={"fixed left-0 self-center flex justify-center items-center border h-[70px] hover:bg-magenta/80 hover:border-magenta/80 cursor-pointer hover:text-light duration-300 w-[30px] bg-dark/80 rounded-r-2xl"}>
                                <FaCaretRight className={"text-large h-full -ml-1"}/>
                            </button>
                        </DrawerTrigger>
                        <DrawerContent
                            style={{backgroundSize: "400px"}}
                            className="h-full w-[250px] min-w-[250px] rounded-none border-none focus:outline-none focus:border-0 bg-contain bg-center bg-repeat bg-[url('/bg-banner.jpg')]">
                            <DrawerTitle className={"sr-only"}>
                                Interfata Test
                            </DrawerTitle>
                            <DrawerDescription className={"sr-only"}>
                                Interfata Test
                            </DrawerDescription>
                            <div className={"h-full bg-white bg-opacity-30 bg-clip-border p-4"}>
                                <SideMenu navVisible={navVisible} closeNav={closeNav}/>
                            </div>
                        </DrawerContent>
                    </Drawer>
                )}
                {children}
            </div>
        </div>
    )
}
NavBarWrap.propTypes = {
    children: PropTypes.node.isRequired,
    withoutSideMenu: PropTypes.bool,
    withoutNav: PropTypes.bool
}