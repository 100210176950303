import useNotificationsSent from "../../hooks/communication/use-notifications-sent.js";
import MarkdownPreview from "../../components/forms/raw-inputs/preview-markdown.jsx";
import PreviewTextBox from "../../components/quizzes/creation/preview-text-box.jsx";
import BgContentWrap from "../../components/layout/bg-content-wrap.jsx";
import Pagination from "../../components/reports/tables/pagination.jsx";
import NavBarWrap from "../../components/navigation/nav-bar-wrap.jsx";
import BasePageWrap from "../../components/layout/base-page-wrap.jsx";
import {Button} from "../../components/schadcn-ui/button.jsx";
import {cn, formatUnixTimestamp} from "../../utils.js";
import {FaXmark} from "react-icons/fa6";

export default function NotificationsSent() {
    const {
        setOpenedNotification, openDetailedNotificationStatus,
        openedNotification, pagination, changeParams,
        setNotificationDetails, notificationDetails,
        openedNotificationRef, resendNotification,
        sentNotifications, readNotification,
        actionsDisabled
    } = useNotificationsSent()

    return (
        <BasePageWrap customStyles="overflow-hidden max-h-screen">
            <NavBarWrap>
                <BgContentWrap>
                    <div className={"flex flex-col"}>
                        <div>
                            <PreviewTextBox text={`Notificari trimise`}/>
                        </div>
                        <div className="space-y-4 overflow-x-hidden p-2">
                            {Array.isArray(sentNotifications) && sentNotifications.length > 0 && sentNotifications.map((notification, notificationIdx) => (
                                <div key={notification["id"].toString()}
                                     style={{animationDelay: `${notificationIdx * 100}ms`}}
                                     className={cn(notification["status"].split("/")[0] === "0" ? "border-red-500" : notification["status"].split("/")[0] !== notification["status"].split("/")[1] ? "border-orange-500" : "border-green-500", "animate-fade p-4 flex flex-col bg-white border-2 shadow-md rounded-lg")}>
                                    <h2 className="text-xl font-semibold">{notification["subject"]}</h2>
                                    <span className="text-sm text-gray-500 mt-2">
                                        Data trimitere: {formatUnixTimestamp(notification["sendAt"].toString())}
                                    </span>
                                    <span
                                        className={cn(notification["status"].split("/")[0] === "0" ? "text-red-500" : notification["status"].split("/")[0] !== notification["status"].split("/")[1] ? "text-orange-500" : "text-green-500", "text-sm mt-2")}>
                                        Status: {notification["status"]} persoane au citit notificarea
                                    </span>
                                    <div className={"flex w-full flex-col sm:flex-row items-end sm:justify-end"}>
                                        {notification["status"] && notification["status"].split("/")[0] !== notification["status"].split("/")[1] && (
                                            <Button variant={"link"}
                                                    onClick={() => resendNotification(notification["id"])}>
                                                Trimitere Reminder
                                            </Button>
                                        )}
                                        <div className={"flex"}>
                                            <Button variant={"link"}
                                                    onClick={() => openDetailedNotificationStatus(notification["id"])}>
                                                Detalii
                                            </Button>
                                            <Button variant={"link"}
                                                    onClick={() => readNotification(notification["id"])}>
                                                Deschide
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <Pagination pagination={pagination} changeParams={changeParams}
                                actionsDisabled={actionsDisabled} tableData={sentNotifications}/>

                    {openedNotification && (
                        <div
                            className="animate-fade animate-duration-200 cursor-default fixed left-0 top-0 w-screen h-screen flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm">
                            <div ref={openedNotificationRef}
                                 className="bg-white flex flex-col p-4 overflow-y-hidden rounded-lg shadow-lg relative min-w-[40vw] max-w-[80vw] max-h-[80vh]">
                                <div className={"relative flex justify-end pb-4"}>
                                    <button onClick={() => setOpenedNotification("")} className="">
                                        <FaXmark/>
                                    </button>
                                </div>
                                <div
                                    className={"relative overflow-y-scroll select-text flex h-full w-full pr-4 min-w-[40vw] max-w-[80vw] max-h-[80vh]"}>
                                    <MarkdownPreview text={openedNotification}/>
                                </div>
                            </div>
                        </div>
                    )}
                    {Array.isArray(notificationDetails) && notificationDetails.length > 0 && (
                        <div
                            className="animate-fade animate-duration-200 cursor-default fixed left-0 top-0 w-screen h-screen flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm">
                            <div ref={openedNotificationRef}
                                 className="bg-white flex flex-col p-4 overflow-y-hidden rounded-lg shadow-lg relative min-w-[40vw] max-w-[80vw] max-h-[80vh]">
                                <div className={"relative flex justify-end pb-4"}>
                                    <button onClick={() => setNotificationDetails([])} className="">
                                        <FaXmark/>
                                    </button>
                                </div>
                                <div className={"relative overflow-y-scroll pr-4 flex flex-col space-y-4"}>
                                    {notificationDetails.map((detail, idx) => (
                                        <div key={idx}
                                             className={cn(!detail["isRead"] ? "bg-red-300" : "bg-green-300", "p-4 rounded-lg shadow-md")}>
                                            <h3 className={"text-md"}>
                                                SE Destinatar: <span className={"font-semibold"}>{detail["codSe"] || ""}</span>
                                            </h3>
                                            <h4 className={"text-md text-gray-600"}>
                                                Institutie invatamant: <span className={"font-semibold"}>{detail["numeInstitutieInvatamant"] || ""}</span>
                                            </h4>
                                            {detail["receiverNume"] && (
                                                <h4 className={"text-md text-gray-600"}>
                                                    Denumire destinatar: <span className={"font-semibold"}>{detail["receiverNume"] || ""}</span>
                                                </h4>
                                            )}
                                            <h4 className={"text-md text-gray-600"}>
                                                Tip destinatar:  <span className={"font-semibold"}>{detail["receiverRole"] || ""}</span>
                                            </h4>

                                            <p className="text-md text-gray-600">
                                                Status Mesaj: <span className={"font-semibold"}>{detail["isRead"] ? "CITIT" : "NECITIT"}</span>
                                            </p>
                                            <p className="text-md text-gray-600">
                                                Citit la data: <span className={"font-semibold"}>{detail["readAt"] ? formatUnixTimestamp(detail["readAt"].toString()) : "N/a"}</span>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </BgContentWrap>
            </NavBarWrap>
        </BasePageWrap>
    )
}