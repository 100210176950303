import PropTypes from "prop-types";
import {cn} from "../../utils.js"

export default function BasePageWrap({customStyles, children}) {

    return (
        <div className={cn("relative min-h-screen min-w-screen max-w-screen max-h-screen flex flex-col bg-light text-dark font-roboto-mono", customStyles)}>
            {children}
        </div>
    )
}
BasePageWrap.propTypes = {
    customStyles: PropTypes.string,
    children: PropTypes.node.isRequired
};