import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import {FaImage, FaTrashCan} from "react-icons/fa6";
import {Button} from "../../../schadcn-ui/button.jsx";
import {cn} from "../../../../utils.js";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import {useMemo} from "react";
import {useLocation} from "react-router-dom";

export default function ImageUploadSlide({
                                             quizForm, currentSlide, saveAndMoveToAvailableSlide,
                                             currentAnswer, handleImageUpload, handleDZClick, removePhoto,
                                             actionsDisabled,
                                         }) {
    const slide = useMemo(() => quizForm.slides.find(slide => slide.id === currentSlide), [currentSlide, quizForm]);
    const location = useLocation()

    return (
        <div className={"my-auto flex flex-col items-center"}>
            <FieldWrap customStyles={"w-auto max-w-md animate-fade"}>
                <InputWrap customStyles={"w-auto p-4"}>
                    <span className={"font-semibold text-center text-large"}>{slide.text}</span>
                </InputWrap>
            </FieldWrap>

            {!location.pathname.includes("preview") && (
                <FieldWrap customStyles={"animate-fade"}>
                    <div className="flex flex-1 h-full justify-center items-center">
                        <InputWrap
                            customStyles={"p-0"}>
                            <div onClick={handleDZClick}
                                 className={cn("group rounded-[5px] relative cursor-pointer flex flex-1 flex-col items-center justify-center w-full h-full text-magenta/80 hover:bg-magenta/80 hover:text-white transition duration-500 text-center")}>
                                <Dropzone onDrop={handleImageUpload} disabled={actionsDisabled}>
                                    {({getRootProps, getInputProps}) => (
                                        <div className="dz-message needsclick;" {...getRootProps()}>
                                            <input multiple={true} disabled={actionsDisabled}
                                                   id="question-image-upload" {...getInputProps({accept: 'image/*'})} />
                                        </div>
                                    )}
                                </Dropzone>
                                {Array.isArray(currentAnswer.photos) && currentAnswer.photos.length > 0 ? (
                                    <div className="flex flex-wrap">
                                        {currentAnswer.photos.map((photo, index) => (
                                            <div key={index} className={"relative h-32 m-2"}>
                                                <img src={photo.toString()} alt={`Uploaded ${index}`}
                                                     className="w-auto h-full object-fit cursor-default animate-fade"/>
                                                <button onClick={(e) => {
                                                    e.stopPropagation()
                                                    removePhoto(index)
                                                }}
                                                        className="absolute top-1 right-1 bg-red-500 text-white p-1 rounded-full">
                                                    <FaTrashCan/>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className={"flex flex-col space-x-4 p-4 items-center"}>
                                        <FaImage style={{fontSize: "2.3em"}}/>
                                        <p className="m-0 text-lg text-gray-400 font-semibold group-hover:text-white transition duration-500">
                                            Apasati aici pentru a adauga poze
                                        </p>
                                    </div>
                                )}
                            </div>
                        </InputWrap>
                    </div>
                </FieldWrap>
            )}


            <div className={"p-2 flex justify-center animate-fade"}>
                <Button className={"h-auto max-w-md"} disabled={actionsDisabled}
                        onClick={() => saveAndMoveToAvailableSlide(slide["id"])}>
                    <span className={"text-wrap"}>
                        {!location.pathname.includes("preview")? "Salvare" : "Continuare"}
                    </span>
                </Button>
            </div>
        </div>
    )
}
ImageUploadSlide.propTypes = {
    actionsDisabled: PropTypes.bool,
    quizForm: PropTypes.object.isRequired,
    removePhoto: PropTypes.func.isRequired,
    handleDZClick: PropTypes.func.isRequired,
    currentSlide: PropTypes.string.isRequired,
    currentAnswer: PropTypes.object.isRequired,
    handleImageUpload: PropTypes.func.isRequired,
    saveAndMoveToAvailableSlide: PropTypes.func.isRequired,
}
