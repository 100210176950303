import {BASE_FETCH_URL} from "../constants/config.js";
import {useAuthProvider} from "./provider-hooks.jsx";
import queryString from 'query-string';
import {createContext} from "react";
import PropTypes from "prop-types";
import axios from "axios";
import {Slide, toast} from "react-toastify";
import {updateFailureToast} from "../toast.js";


const FetchProviderContext = createContext(null);
const {Provider} = FetchProviderContext;

const FetchProvider = ({children}) => {
    const {token, isTokenExpired, refreshToken, getNewToken} = useAuthProvider()

    const privateFetch = axios.create({baseURL: BASE_FETCH_URL});
    const publicFetch = axios.create({baseURL: BASE_FETCH_URL});

    privateFetch.interceptors.response.use(
        (response) => response,
        (error) => Promise.reject(error)
    );

    privateFetch.interceptors.request.use(
        async (config) => {
            if (token && isTokenExpired(token)) {
                const newToken = await getNewToken(refreshToken);
                config.headers.Authorization = `Bearer ${newToken}`;
            } else if (token && !isTokenExpired(token)) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    async function get(url, params) {
        try {
            const response = await privateFetch.get(url, {params});
            return response.data;
        } catch (error) {
            console.error(error)
            throw error.response.data
        }
    }

    async function post(url, data, params) {
        try {
            const response = await privateFetch.post(url, data, {params});
            return response.data;
        } catch (error) {
            throw error.response.data
        }
    }

    async function put(url, data, params) {
        try {
            const response = await privateFetch.put(url, data, {params});
            return response.data;
        } catch (error) {
            throw error.response.data
        }
    }

    async function patch(url, data, params) {
        try {
            const response = await privateFetch.patch(url, data, {params});
            return response.data;
        } catch (error) {
            throw error.response.data
        }
    }

    async function del(url, params) {
        try {
            const response = await privateFetch.delete(url, {params});
            return response.data;
        } catch (error) {
            throw error.response.data
        }
    }

    async function uploadImage(file) {
        const id = toast.loading("Se incarca un fisier...", {transition: Slide});
        try {
            const formData = new FormData();
            formData.append('file', file);
            const data = await post("/files/upload", formData)
            toast.dismiss(id)
            return data
        } catch (error) {
            console.error(error)
            updateFailureToast("Un fisier nu s-a putut incarca", id)
            throw error
        }
    }

    async function getDefaultLesson() {
        return await get("/lessons/default")
    }

    async function getLessonById(id) {
        if (!id) throw new Error("Id param needed to fetch lesson was not provided!")
        if (typeof id !== "string") throw new Error("Id param for lesson fetch must be a string!")
        return await get(`/lessons/${id}`)
    }

    async function getDefaultSlide() {
        return await get("/slides/default")
    }

    async function delSlide(id) {
        if (!id) throw new Error("Id param for the slide to be deleted was not provided!")
        if (typeof id !== "string") throw new Error("Id param for the slide to be deleted must be a string!")
        return await del(`/slides/${id}`)
    }

    async function getDefaultOption() {
        return await get("/options/default")
    }

    async function delOption(id) {
        if (!id) throw new Error("Id param for the option to be deleted was not provided!")
        if (typeof id !== "string") throw new Error("Id param for the option to be deleted must be a string!")
        return await del(`/options/${id}`)
    }

    async function getQuizListing(type) {
        return await get(`/lessons/types?type=${type}`)
    }

    async function postLesson(data) {
        if (!data) throw new Error("Lesson data was not provided!")
        return await post("/lessons", data)
    }

    async function patchLesson(data) {
        if (!data) throw new Error("Lesson data was not provided!")
        return await patch("/lessons", data)
    }

    async function getDefaultWasteForm() {
        return await get("/waste-collection-forms/default")
    }

    async function postWasteForm(data) {
        if (!data) throw new Error("Waste form data was not provided!")
        return await post("/waste-collection-forms", data)
    }

    async function patchWasteForm(data) {
        if (!data) throw new Error("Waste form data was not provided!")
        return await patch("/waste-collection-forms", data)
    }

    async function getWasteFormById(id) {
        if (!id) throw new Error("Id param needed to fetch waste form was not provided!")
        if (typeof id !== "string") throw new Error("Id param for waste form fetch must be a string!")
        return await get(`/waste-collection-forms/${id}`)
    }

    async function patchWasteFormValidation(id, params) {
        return await patch(`/waste-collection-forms/${id}/validation`, undefined, params)
    }

    async function getDefaultBulkNotification() {
        return await get("/notifications/default")
    }

    async function postBulkNotifications(data) {
        if (!data) throw new Error("Notification data was not provided!")
        return await post("/notifications", data)
    }

    async function getNotifications(params) {
        return await get("/notifications/inbox", params)
    }

    async function getSentNotifications(params) {
        return await get("/notifications/outbox", params)
    }

    async function getNotificationReceivers(id) {
        return await get(`/notifications/${id}/receivers`)
    }

    async function postNotificationResend(id) {
        return await post(`/notifications/${id}/resend`)
    }

    async function patchNotificationAsRead(id) {
        return await patch(`/notifications/${id}/read?isRead=true`)
    }

    async function getNotificationsContacts() {
        return await get("/users/contacts")
    }

    async function getWasteTable() {
        return await get("/waste-collection-forms")
    }

    async function getProfileData() {
        return await get("/users/profile")
    }

    async function getProfileActivationTable(params) {
        return await get("/users/report", params)
    }

    async function patchActivation(id, params) {
        return await patch(`/users/${id}/activation`, {}, params)
    }

    async function patchLogisticsOrderData(id, params) {
        if (Object.values(params).length === 0) return await patch(`/waste-collection-forms/${id}/waste-real-values`)
        return await patch(`/waste-collection-forms/${id}/waste-real-values?${queryString.stringify(params, {arrayFormat: 'repeat'})}`)
    }

    async function patchOrderTransmitted(id) {
        return await patch(`waste-collection-forms/${id}/process`, {}, {isProcessed: true})
    }

    async function patchQuizVisibility(id, params) {
        return await patch(`/lessons/${id}/hide`, {}, params)
    }

    async function patchProfileData(data) {
        return await patch("/users/profile", data)
    }

    async function patchPassword(data) {
        return await patch("/auth/change-password", data)
    }

    async function getSchools(params) {
        return await get("/schools", params)
    }

    async function getCompanies() {
        return await get("/companies")
    }

    async function getAnswersForLesson(lessonId) {
        if (!lessonId) throw new Error("lessonId param needed to getAnswersForLesson was not provided!")
        if (typeof lessonId !== "string") throw new Error("lessonId param for getAnswersForLesson must be a string!")
        return await get(`/answers/lessons/${lessonId}`)
    }

    async function getDefaultAnswer() {
        return await get("/answers/default")
    }

    async function postAnswer(answer) {
        return await post("/answers", answer)
    }

    async function getSchoolsReport(params) {
        return await get("/schools/report", params)
    }

    async function getNotificationsReport(params) {
        return await get("/notifications/report", params)
    }

    async function getOwnSchool() {
        return await get("/schools/own")
    }

    async function getDefaultSchool() {
        return await get("/schools/default")
    }

    async function patchSchool(data) {
        return await patch("/schools", data)
    }

    async function getAnswersReport(params) {
        return await get("/lessons/answers/report", params)
    }

    async function getOrdersReport(params) {
        return await get("/waste-collection-forms/report", params)
    }

    async function getAnswersForQuiz(lessonId, version, userId) {
        return await get(`/answers/lessons/${lessonId}/versions/${version}`, {userId: userId})
    }

    async function patchLessonApproval(id, params) {
        return await patch(`/lessons/answers/${id}/approval`, undefined, params)
    }

    async function getSchoolById(id) {
        return await get(`/schools/${id}`)
    }

    async function getCuiCheckDataForSchool(params) {
        return await get('/schools/import-data', params)
    }

    async function getUnauthorizedDefaultSchoolForm() {
        return await get("/school-requests/default")
    }

    async function getUnauthorizedOwnSchoolForm() {
        return await get("/school-requests/own")
    }

    async function postUnauthorizedSchoolForm(data) {
        return await post("/school-requests", data)
    }

    async function patchUnauthorizedSchoolForm(data) {
        return await patch("/school-requests", data)
    }

    async function patchParentalApproval(data) {
        return await patch("/users/signature", data)
    }

    async function getSchoolRegistrationForm(id) {
        return await get(`/school-requests/user/${id}`)
    }

    async function getEditions() {
        return await get("/editions")
    }

    async function getExcelReport(path) {
        return await privateFetch.get(`/files/excel-reports?url=${path}`, {responseType: 'blob'});
    }

    async function getFinalReport(params) {
        return await get('/schools/status-report', params)
    }

    const providerValues = Object.freeze({
        getAnswersReport, postNotificationResend, getNotificationsReport,
        getAnswersForQuiz, get, post, put, patch, getCuiCheckDataForSchool,
        getDefaultLesson, getDefaultSlide, getDefaultOption, getQuizListing,
        patchQuizVisibility, patchLogisticsOrderData, patchOrderTransmitted,
        getDefaultBulkNotification, postBulkNotifications, getNotifications,
        getProfileActivationTable, getAnswersForLesson, getDefaultWasteForm,
        getUnauthorizedDefaultSchoolForm, patchParentalApproval, getEditions,
        patchProfileData, patchPassword, getSchools, getCompanies, delOption,
        postAnswer, getSchoolsReport, getOwnSchool, getNotificationsContacts,
        getNewToken, getDefaultSchool, getSentNotifications, del, postLesson,
        patchNotificationAsRead, getWasteFormById, getWasteTable, uploadImage,
        postUnauthorizedSchoolForm, getUnauthorizedOwnSchoolForm, getSchoolById,
        getNotificationReceivers, patchWasteFormValidation, patchLessonApproval,
        delSlide, patchActivation, patchLesson, getLessonById, getDefaultAnswer,
        getOrdersReport, getSchoolRegistrationForm, patchUnauthorizedSchoolForm,
        postWasteForm, patchWasteForm, getProfileData, patchSchool, getFinalReport,
        publicFetch, privateFetch, getExcelReport,
    })

    return (
        <Provider value={providerValues}>
            {children}
        </Provider>
    );
};
FetchProvider.propTypes = {children: PropTypes.node.isRequired}
export {FetchProviderContext, FetchProvider};
