import BasePageWrap from "../layout/base-page-wrap.jsx";
import PropTypes from "prop-types";

export default function PageWrap({children}) {

    return(
        <BasePageWrap customStyles="max-h-screen overflow-hidden">
            <div className={"bg-[url('/thumbnail_interfata.jpg')] md:bg-[url('/thumbnail_interfata_desktop.jpg')] bg-cover bg-top md:bg-right"}>
                <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
                    <div className="max-w-md w-full">
                           {children}
                    </div>
                </div>
            </div>
        </BasePageWrap>
    )
}
PageWrap.propTypes = {
    children: PropTypes.node.isRequired
}