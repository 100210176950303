import {useAuthProvider, useRoutesProvider} from "../../../providers/provider-hooks.jsx";
import AuthorizationAction from "./authorization-action.jsx";
import {formatUnixTimestamp} from "../../../utils.js";
import BodyCell from "../tables/body-cell.jsx";
import BodyRow from "../tables/body-row.jsx";
import PropTypes from "prop-types";
import {Fragment} from "react";

export default function TableBody({tableData, authorizeAccount, actionsDisabled, openRejectDialog, openDialogForm}) {
    const {authState} = useAuthProvider()
    const {ROLES, getMainPresentationRole} = useRoutesProvider()

    return (
        <tbody className={"bg-light"}>
        {tableData.map((row, rowIdx) => {
            return (
                <BodyRow key={`row-${rowIdx}`} customStyles={"hover:bg-lilac/30"}>
                    {authState.userInfo.role !== ROLES.PROFESOR && (
                        <BodyCell colSpan={1}>
                            <div className={"flex justify-between space-x-1 font-bold"}>
                                <AuthorizationAction authorizeAccount={authorizeAccount}
                                                     openRejectDialog={openRejectDialog}
                                                     actionsDisabled={actionsDisabled} row={row}/>
                            </div>
                        </BodyCell>
                    )}

                    {authState.userInfo.role !== ROLES.PROFESOR && (
                        <Fragment>

                            <BodyCell colSpan={1}>
                                <div className={"flex justify-between space-x-1 font-bold"}>
                                    {row["role"] !== ROLES.ELEV && row["hasSchoolRegistrationFormCompleted"] ? (
                                        <button disabled={actionsDisabled} onClick={() => openDialogForm(row["id"])}
                                                className={"disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:no-underline hover:underline focus:outline-none"}>
                                            Deschidere
                                        </button>
                                    ) : (
                                        <span>N/a</span>
                                    )}
                                </div>
                            </BodyCell>
                            <BodyCell colSpan={1}>
                                <div
                                    className={"text-nowrap"}>{row["role"] ? getMainPresentationRole(row["role"]) : ""}</div>
                            </BodyCell>
                        </Fragment>
                    )}

                    <BodyCell colSpan={1}>
                        <div className={"text-nowrap"}>
                            {row["isEnabled"] === true ? "DA" : row["isEnabled"] === false ? "NU" : ""}
                        </div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div className={"text-nowrap"}>{row["rejectReason"] || ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div className={"text-nowrap"}>{row["timestamp"]? formatUnixTimestamp(row["timestamp"]) : ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div className={"text-nowrap"}>{row["telefon"] || ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div className={"text-nowrap"}>{row["nume"] || ""}</div>
                    </BodyCell>

                    <BodyCell colSpan={1}>
                        <div className={"text-nowrap"}>{row["email"] || ""}</div>
                    </BodyCell>
                </BodyRow>
            )
        })}
        </tbody>
    )
}
TableBody.propTypes = {
    tableData: PropTypes.array.isRequired,
    openDialogForm: PropTypes.func.isRequired,
    actionsDisabled: PropTypes.bool.isRequired,
    authorizeAccount: PropTypes.func.isRequired,
    openRejectDialog: PropTypes.func.isRequired,
}