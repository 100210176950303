import {useAuthProvider, useRoutesProvider} from "../../../providers/provider-hooks.jsx";
import BooleanFilterInput from "../tables/boolean-filter-input.jsx";
import SearchInput from "../tables/search-input.jsx";
import SortButton from "../tables/sort-button.jsx";
import HeadCell from "../tables/head-cell.jsx";
import HeadRow from "../tables/head-row.jsx";
import {cn} from "../../../utils.js";
import PropTypes from "prop-types";
import {CalendarRangePicker} from "../tables/calendar-range-picker.jsx";

export default function TableHeaders({actionsDisabled, params, changeParams}) {
    const {authState} = useAuthProvider()
    const {ROLES} = useRoutesProvider()

    return (
        <thead className={cn("font-bold bg-light sticky top-0 z-10")}>
        <HeadRow>
            {[ROLES.DBEST, ROLES.ADMIN].includes(authState.userInfo.role) && (
                <HeadCell colSpan={1}>
                    <div className="flex items-center space-y-1 py-2">
                        <span className={"text-nowrap"}>Actiuni</span>
                    </div>
                </HeadCell>
            )}

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <span className={"text-nowrap"}>Imagini activitati practice</span>
                </div>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"timestamp"}/>
                    <span className={"text-nowrap"}>Data completare</span>
                </div>
                <CalendarRangePicker handleChange={changeParams} fromName={"timestampRange.start"}
                                     toName={"timestampRange.end"} from={params["timestampRange.start"] || ""}
                                     to={params["timestampRange.end"] || ""} disabled={actionsDisabled}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"lesson.typeAlias"}/>
                    <span className={"text-nowrap"}>Tip activitate</span>
                </div>
                <SearchInput placeholder={"Tip activitate..."} disabled={actionsDisabled}
                             handleChange={changeParams} name={"lesson.typeAlias"}
                             value={params["lesson.typeAlias"] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"lesson.title"}/>
                    <span className={"text-nowrap"}>Titlu activitate</span>
                </div>
                <SearchInput placeholder={"Titlu activitate..."} disabled={actionsDisabled}
                             handleChange={changeParams} name={"lesson.title"}
                             value={params["lesson.title"] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"user.nume"}/>
                    <span className={"text-nowrap"}>Nume utilizator</span>
                </div>
                <SearchInput placeholder={"Nume utilizator..."} disabled={actionsDisabled}
                             handleChange={changeParams} name={"user.nume"}
                             value={params["user.nume"] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"user.email"}/>
                    <span className={"text-nowrap"}>Email utilizator</span>
                </div>
                <SearchInput placeholder={"Nume utilizator..."} disabled={actionsDisabled}
                             handleChange={changeParams} name={"user.email"}
                             value={params["user.email"] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"user.school.numeInstitutieInvatamant"}/>
                    <span className={"text-nowrap"}>Denumire institutie invatamant</span>
                </div>
                {[ROLES.DBEST, ROLES.ADMIN].includes(authState.userInfo.role) && (
                    <SearchInput placeholder={"Denumire institutie invatamant..."} disabled={actionsDisabled}
                                 handleChange={changeParams} name={"user.school.numeInstitutieInvatamant"}
                                 value={params["user.school.numeInstitutieInvatamant"] || ""}/>
                )}
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"user.school.codSeNumeric"}/>
                    <span className={"text-nowrap"}>Cod SE</span>
                </div>
                {[ROLES.DBEST, ROLES.ADMIN].includes(authState.userInfo.role) && (
                    <SearchInput placeholder={"Cod SE..."} disabled={actionsDisabled}
                                 handleChange={changeParams} name={"user.school.codSe"}
                                 value={params["user.school.codSe"] || ""}/>
                )}
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"rejectReason"}/>
                    <span className={"text-nowrap"}>Motiv respingere (daca este cazul)</span>
                </div>
                <SearchInput handleChange={changeParams} name={"rejectReason"}
                             disabled={actionsDisabled} placeholder={"Motiv respingere..."}
                             value={params["rejectReason"] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"isApproved"}/>
                    <span className={"text-nowrap"}>Aprobat</span>
                </div>
                <BooleanFilterInput handleChange={changeParams} disabled={actionsDisabled}
                                    name={`isApproved`} value={params[`isApproved`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"points"}/>
                    <span className={"text-nowrap"}>Numar puncte</span>
                </div>
                <SearchInput handleChange={changeParams} name={"points"} type={"number"}
                             disabled={actionsDisabled} placeholder={"Numar puncte..."}
                             value={params["points"] || ""}/>
            </HeadCell>
        </HeadRow>
        </thead>
    )
}
TableHeaders.propTypes = {
    actionsDisabled: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    changeParams: PropTypes.func.isRequired
}