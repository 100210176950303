import {useAuthProvider, useFetchProvider, useRoutesProvider} from "../../providers/provider-hooks.jsx";
import usePasswordChange from "../../hooks/auth/use-password-change.js";
import PasswordChange from "./password-change.jsx";
import {Fragment, useEffect, useState} from "react";
import UserIcon from "./user-icon.jsx";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {ACTUAL_ENVIRONMENT, ENVIRONMENTS} from "../../constants/config.js";

export default function TopNavBar({children}) {
    const [profileData, setProfileData] = useState({})
    const {getProfileData} = useFetchProvider()
    const {authState} = useAuthProvider()
    const {ROLES} = useRoutesProvider()
    const {
        openModal, setIsModalOpen, isModalOpen, formData,
        handleFieldChange, errors, updatePassword
    } = usePasswordChange()


    useEffect(() => {
        async function loadProfileData() {
            try {
                setProfileData(await getProfileData())
            } catch (error) {
                console.error(error)
            }
        }

        loadProfileData().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{backgroundSize: "400px"}}
             className="w-full justify-between z-40 sticky top-0 border border-lime-700 rounded-xl mb-4 bg-contain bg-center bg-repeat bg-[url('/bg-banner.jpg')]">
            <div
                className="flex flex-row flex-1 space-x-2 items-center h-[54px] max-h-[54px] rounded-xl bg-white bg-opacity-30 bg-clip-border p-2">
                <Link to={"/"}
                      className="-ml-3 flex items-center justify-start min-w-[140px] md:min-w-[160px] lg:min-w-[190px]">
                    <img src="/logo-ecotic.png" alt="ECOTIC logo" className="max-h-[65px] h-full"/>
                </Link>
                {children}
                {ACTUAL_ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT && (
                    <Fragment>
                    <span className={"hidden lg:flex lg:font-bold lg:text-xl self-center text-red-600"}>ACEASTA ESTE VARIANTA DE TESTARE A APLICATIEI!!!</span>
                    <span className={"lg:hidden flex font-bold self-center text-red-600"}>VARIANTA TESTARE!!!</span>
                    </Fragment>
                )}
                <div className={"w-full flex items-center justify-end space-x-2"}>
                    {[ROLES.ELEV, ROLES.PROFESOR].includes(authState.userInfo.role) && (
                        <div className={"relative flex items-center justify-center"}>
                            <div className={"w-[100px] h-[32px] fond-semibold flex items-center justify-center text-white rounded-lg border-2 border-dark bg-magenta/60"}>
                                <span className={"text-nowrap font-bold"}>{profileData["codSe"] || ""}</span>
                            </div>
                            <div className={"absolute -left-[8px] top-1/2 transform -translate-y-1/2 h-[16px] w-[8px] rounded-l bg-yellow-400/60 border-2 border-dark"}></div>
                        </div>
                    )}
                    <UserIcon openPasswordModal={openModal} profileData={profileData}/>
                </div>
                <PasswordChange setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}
                                updatePassword={updatePassword} handleFieldChange={handleFieldChange}
                                formData={formData} errors={errors}/>
            </div>
        </div>
    )
}
TopNavBar.propTypes = {
    children: PropTypes.node,
}