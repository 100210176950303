import {BASE_FETCH_URL, ECOTIC_REFRESH_TOKEN_KEY, ECOTIC_TOKEN_KEY} from "../constants/config.js";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {createContext, useEffect, useMemo, useState} from "react";
import {useRoutesProvider} from "./provider-hooks.jsx";
import {pathToRegex} from "../utils.js";
import {jwtDecode} from "jwt-decode";
import PropTypes from "prop-types";

const AuthProviderContext = createContext(null);
const {Provider} = AuthProviderContext;

const AuthProvider = ({children}) => {
    const {ROLES, PATHS, PAGES, NAV_LINKS_V2, getUserHomePage} = useRoutesProvider()
    const [token, setToken] = useState(localStorage.getItem(ECOTIC_TOKEN_KEY));
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem(ECOTIC_REFRESH_TOKEN_KEY))
    const userInfo = useMemo(() => token ? jwtDecode(token.toString()) : null, [token]);
    const authState = useMemo(() => token ? {token: token, userInfo: userInfo} : null, [token, userInfo])
    const navigate = useNavigate();
    const location = useLocation()


    const isTokenValid = (token) => {
        return !(!token || !jwtDecode(token.toString()).exp);
    }

    const isTokenExpired = (token) => {
        return new Date().getTime() / 1000 > jwtDecode(token.toString()).exp;
    }

    const updateRefreshToken = (newRefreshToken) => {
        localStorage.setItem(ECOTIC_REFRESH_TOKEN_KEY, newRefreshToken);
        setRefreshToken(newRefreshToken)
    }

    const setAuthInfo = (token) => {
        if (!isTokenValid(token)) {
            console.error("Disconnection because token is invalid in setAuthInfo", {
                "token": token,
                isTokenValid: isTokenValid(token)
            })
            return logout()
        }
        localStorage.setItem(ECOTIC_TOKEN_KEY, token);
        const userInfo = token ? jwtDecode(token.toString()) : null;
        setToken(token);
        return userInfo
    };

    const logout = () => {
        setToken(null);
        setRefreshToken(null)
        localStorage.setItem(ECOTIC_TOKEN_KEY, "");
        localStorage.setItem(ECOTIC_REFRESH_TOKEN_KEY, "");
    };

    function redirectToHomePage() {
        let newToken = token
        let newAuthState = authState;
        if (!newToken) return <Navigate to={PATHS[PAGES.HOME]}/>;
        if (!isTokenValid(newToken)) {
            localStorage.setItem(ECOTIC_TOKEN_KEY, "");
            localStorage.setItem(ECOTIC_REFRESH_TOKEN_KEY, "");
            setToken(null);
            setRefreshToken(null)
            return <Navigate to={PATHS[PAGES.HOME]}/>
        }
        const {userInfo: {role, isEnabled, hasSchoolRegistrationFormCompleted}} = newAuthState;
        const navigation = handleRoleNavigation(role, isEnabled, hasSchoolRegistrationFormCompleted, location);

        if (navigation) return navigation;
        return <Navigate to={getUserHomePage(authState.userInfo.role)}/>;
    }

    const handleRoleNavigation = (role, isEnabled, hasSchoolRegistrationFormCompleted, path) => {
        if (role === ROLES.ELEV && !isEnabled) {
            if (!PATHS[PAGES.PARENTAL_APPROVAL].includes(location.pathname)) {
                return <Navigate to={PATHS[PAGES.PARENTAL_APPROVAL]}/>;
            } else return NAV_LINKS_V2[PAGES.PARENTAL_APPROVAL].COMPONENT
        }
        if (path === PATHS[PAGES.BIG_SCHOOLS_DATA_TABLE]) return null
        if (role === ROLES.PROFESOR && !isEnabled && !hasSchoolRegistrationFormCompleted) {
            if (!PATHS[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2].includes(location.pathname)) {
                return <Navigate to={PATHS[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2]}/>;
            } else {
                window.history.replaceState(null, "ECOTIC", NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2].PATH)
                return NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2].COMPONENT
            }
        }
        if (role === ROLES.PROFESOR && !isEnabled && hasSchoolRegistrationFormCompleted
            && path === NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3].PATH
            && NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3].ALLOWED_ROLES.includes(role)) {
            console.log("hit")

            if (!PATHS[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3].includes(location.pathname)) {
                return <Navigate to={NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3].PATH}/>
            } else return NAV_LINKS_V2[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3].COMPONENT
        }
        if (role === ROLES.PROFESOR && !isEnabled && hasSchoolRegistrationFormCompleted) {
            if (!PATHS[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER].includes(location.pathname)) {
                return <Navigate to={PATHS[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER]}/>;
            } else {
                window.history.replaceState(null, "ECOTIC", NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER].PATH)
                return NAV_LINKS_V2[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER].COMPONENT
            }
        }
        return null;
    };

    const checkPermissionsV2 = (path, component) => {
        const navLinksArray = Object.values(NAV_LINKS_V2);
        for (const link of navLinksArray) {
            if (link.PATH === path) {
                if (link.ALLOWED_ROLES.length === 0) return component;

                let newToken = token;
                let newAuthState = authState;

                if (!newToken) return <Navigate to={PATHS[PAGES.HOME]}/>;
                if (!isTokenValid(newToken)) {
                    console.error("Disconnection because token is invalid in checkPermissionsV2", {
                        "token": newToken,
                        isTokenValid: isTokenValid(newToken)
                    })
                    return logout();
                }

                const {userInfo: {role, isEnabled, hasSchoolRegistrationFormCompleted}} = newAuthState;

                const navigation = handleRoleNavigation(role, isEnabled, hasSchoolRegistrationFormCompleted, path);
                if (navigation) return navigation;

                if (link.ALLOWED_ROLES.some(allowedLinkRole => role === allowedLinkRole)) return component;
                return <Navigate to={PATHS[PAGES.HOME]}/>;
            }
        }
        return <Navigate to={PATHS[PAGES.HOME]}/>;
    };

    async function getNewToken(oldRefreshToken) {
        try {
            const response = await fetch(`${BASE_FETCH_URL}/auth/refresh-token`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({refreshToken: oldRefreshToken})
            });

            if (!response.ok) throw new Error("Failed to refresh token");

            const data = await response.json();
            localStorage.setItem(ECOTIC_TOKEN_KEY, data.token);
            localStorage.setItem(ECOTIC_REFRESH_TOKEN_KEY, data.refreshToken);
            updateRefreshToken(data.refreshToken);
            setAuthInfo(data.token);
            return data.token;
        } catch (error) {
            console.error("Disconnection because the newToken could not be retrieved with getNewToken", {
                error: error
            })
            logout()
        }
    }

    async function postLogOut(oldToken) {
        const response = await fetch(`${BASE_FETCH_URL}/auth/logout`, {
            method: "POST",
            headers: {"Content-Type": "application/json", 'Authorization': `Bearer ${oldToken}`},
            body: JSON.stringify({})
        });

        if (!response.ok) throw new Error("Failed to refresh token");
    }

    async function validateAppInitiation(someToken, someRefreshToken) {
        if (someToken && someRefreshToken && isTokenValid(someToken)) {
            try {
                await getNewToken(someRefreshToken)
            } catch (error) {
                console.error(error)
            }
        }
    }

    useEffect(() => {
        const checkLocalStorage = () => {
            const tokenValue = localStorage.getItem(ECOTIC_TOKEN_KEY);
            const refreshTokenValue = localStorage.getItem(ECOTIC_REFRESH_TOKEN_KEY);
            if (tokenValue === '' || refreshTokenValue === "") {
                setRefreshToken(null)
                setToken(null);
            } else if (tokenValue && refreshTokenValue) {
                const newUserInfo = setAuthInfo(tokenValue)
                updateRefreshToken(refreshTokenValue)
                if ([PATHS[PAGES.HOME], PATHS[PAGES.TEACHER_AUTH], PATHS[PAGES.TEACHER_REGISTER], PATHS[PAGES.PUPIL_AUTH],
                    PATHS[PAGES.PUPIL_REGISTER]].includes(location.pathname)) return navigate("/")
                for (const page of Object.values(NAV_LINKS_V2)) {
                    const regex = pathToRegex(page.PATH);
                    if (regex.test(location.pathname)) {
                        if (page.ALLOWED_ROLES.includes(newUserInfo.role)) return
                        return navigate("/")
                    }
                }
            }
        };

        const handleStorageChange = (event) => {
            if ((event.key === ECOTIC_TOKEN_KEY) || (event.key === ECOTIC_REFRESH_TOKEN_KEY)) {
                checkLocalStorage();
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ((!token || !refreshToken) && (![PATHS[PAGES.HOME], PATHS[PAGES.TEACHER_AUTH],
            PATHS[PAGES.TEACHER_REGISTER], PATHS[PAGES.PUPIL_AUTH], PATHS[PAGES.PUPIL_REGISTER]]
            .includes(location.pathname))) navigate(PATHS[PAGES.HOME]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, refreshToken]);

    const authProviderValue = {
        setAuthInfo, isTokenValid, logout, redirectToHomePage,
        authState, token, checkPermissionsV2, updateRefreshToken,
        refreshToken, isTokenExpired, getNewToken, postLogOut,
        validateAppInitiation
    };

    return <Provider value={authProviderValue}>{children}</Provider>;
};
AuthProvider.propTypes = {children: PropTypes.node.isRequired}
export {AuthProviderContext, AuthProvider};