import {useAuthProvider, useFetchProvider, useRoutesProvider} from "./provider-hooks.jsx";
import {createContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {toastOneWarn} from "../toast.js";
import PropTypes from "prop-types";
import useTableParams from "../hooks/reports/use-table-params.js";
import {delay} from "../utils.js";

const InboxProviderContext = createContext(null);
const {Provider} = InboxProviderContext;

const InboxProvider = ({children}) => {
    const [notifications, setNotifications] = useState([]);
    const [openedNotification, setOpenedNotification] = useState("")
    const [pagination, setPagination] = useState({})
    const [actionsDisabled, setActionsDisabled] = useState(false)
    const {params, changeParams} = useTableParams(renderNotifications, {page: 0})

    const {getNotifications, patchNotificationAsRead} = useFetchProvider();
    const {isTokenValid, token, authState} = useAuthProvider();
    const {PATHS, PAGES, NAV_LINKS_V2} = useRoutesProvider()
    const location = useLocation()

    async function readNotification(id) {
        const targetNotif = notifications.find(notif => notif.id === id)?.content
        if (!targetNotif) return toastOneWarn("Nu exista continut pentru aceasta notificare")
        if (!targetNotif["isRead"]) {
            try {
                await patchNotificationAsRead(id)
            } catch (error) {
                console.error(error)
            }
        }
        await renderNotifications()
        return setOpenedNotification(targetNotif)
    }

    async function renderNotifications(freshParams) {
        if (isTokenValid(token)) {
            await delay(1000)
            setActionsDisabled(true)
            try {
                const data = await getNotifications(freshParams || params)
                if (data.content.length === 0 && location.pathname === PATHS[PAGES.INBOX]) {
                    return toastOneWarn("Nu exista notificari disponibile")
                }
                setNotifications(data.content)
                setPagination({totalPages: data.totalPages, ...data["pageable"], first: data.first, last: data.last})
            } catch (error) {
                console.error(error)
            } finally {
                setActionsDisabled(false)
            }
        }
    }

    useEffect(() => {
        if (!token || !authState) return
        if (!NAV_LINKS_V2[PAGES.INBOX].ALLOWED_ROLES.includes(authState.userInfo.role)) return
        if ([PATHS[PAGES.HOME], PATHS[PAGES.BIG_SCHOOLS_DATA_TABLE], PATHS[PAGES.TEACHER_AUTH], PATHS[PAGES.TEACHER_REGISTER], PATHS[PAGES.PUPIL_AUTH],
            PATHS[PAGES.PUPIL_REGISTER], PATHS[PAGES.PARENTAL_APPROVAL], PATHS[PAGES.ACCOUNT_UNAUTHORIZED_PUPIL],
            PATHS[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V2], PATHS[PAGES.SCHOOL_FORM_ACCOUNT_UNAUTHORIZED_V3],
            PATHS[PAGES.ACCOUNT_UNAUTHORIZED_TEACHER], "/"]
            .includes(location.pathname)) return
        renderNotifications().then()
        if (![PATHS[PAGES.INBOX]].includes(location.pathname)) {
            const intervalId = setInterval(renderNotifications, 60000)
            return () => clearInterval(intervalId)
        }   // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const inboxProviderValue = {
        notifications, openedNotification, readNotification, setOpenedNotification,
        pagination, changeParams, actionsDisabled
    };

    return <Provider value={inboxProviderValue}>{children}</Provider>;
};

InboxProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export {InboxProviderContext, InboxProvider};
