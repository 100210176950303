import SelectInputBox from "../../components/forms/final-input-boxes/select-input-box.jsx";
import TextInputBox from "../../components/forms/final-input-boxes/text-input-box.jsx";
import {useRoutesProvider} from "../../providers/provider-hooks.jsx";
import {Button} from "../../components/schadcn-ui/button.jsx";
import PageWrap from "../../components/auth/page-wrap.jsx";
import useRegister from "../../hooks/auth/use-register.js";
import {CLASSES} from "../../constants/classes.js";
import H1 from "../../components/layout/h1.jsx";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {Fragment} from "react";

export default function Register({forRole}) {
    const {ROLES, PATHS, PAGES} = useRoutesProvider()
    const navigate = useNavigate()
    const {
        redirectToLogIn, handleChange, loading,
        errors, registerUser, registrationData,
    } = useRegister(forRole)

    return (
        <PageWrap>
            <div className="p-4 mb-4 rounded-2xl bg-light/70 shadow">
                <div className="z-10 flex flex-1 flex-col max-h-[20vh] w-full my-auto max-w-md">
                    <Button type={"button"} size={"lg"} disabled={loading} className={"m-2 bg-magenta text-white"}
                            onClick={() => redirectToLogIn(forRole)}>
                        Am deja un cont creat!
                    </Button>
                </div>
            </div>
            <div className="p-4 rounded-2xl bg-light/90 shadow">
                <div className="z-10 flex flex-1 flex-col max-h-[60vh] overflow-y-scroll pr-2 w-full my-auto max-w-md">
                    <H1 customStyles="text-center" text="Acum vreau sa creez contul"/>
                    <form onSubmit={registerUser} className="flex w-full flex-col" autoComplete="off">
                        {forRole === ROLES.ELEV && (
                            <Fragment>
                                <TextInputBox handleChange={handleChange} name={"codProfesor"}
                                              label={'Codul profesorului coordonator'}
                                              value={registrationData["codProfesor"]} errors={errors}/>
                                <span className="px-2 pb-2 -mt-2 text-black">
                                    *îl puteți obține de la profesorul coordonator.
                                </span>
                                <SelectInputBox name={"clasa"} label={"Clasa"}
                                                display={{value: "value", keys: ["label"]}}
                                                emptyValueLabel={"Selectati clasa in care sunteti"}
                                                value={registrationData["clasa"] || ""} options={[...CLASSES]}
                                                errors={errors} handleChange={handleChange}/>
                            </Fragment>
                        )}

                        <TextInputBox handleChange={handleChange} name={"nume"}
                                      label={forRole === ROLES.PROFESOR ? "Nume si Prenume" : "Nickname"}
                                      value={registrationData["nume"]} errors={errors}/>
                        {forRole === ROLES.ELEV && (
                            <Fragment>
                                <span className="px-2 -mt-2 text-black">
                                    *vă rugăm să alegeți un nume cât mai fantezist, nu numele vostru.
                                </span>
                                <span className="px-2 text-black">
                                    *acest nickname va reflecta acumularea punctelor
                                </span>
                            </Fragment>
                        )}
                        {forRole === ROLES.PROFESOR && (
                            <TextInputBox handleChange={handleChange} name={"telefon"} label={'Telefon'}
                                          value={registrationData["telefon"]} errors={errors}/>
                        )}

                        <TextInputBox handleChange={handleChange} name={"email"} label={'Email'}
                                      value={registrationData["email"]} errors={errors}/>
                        {forRole === ROLES.ELEV && (
                            <span className="px-2 -mt-2 text-black">
                                *vă rugăm să folosiți o adresă de email creată pentru această campanie, cu nickname-ul folosit și nu cu numele vostru real.
                            </span>
                        )}
                        {forRole === ROLES.PROFESOR && (
                            <span className="px-2 -mt-2 text-black">
                                *vă rugăm să verificați constant această adresă, deoarece vom trimite constant informări.
                            </span>
                        )}
                        <TextInputBox handleChange={handleChange} name={"parola"} label={'Parola'}
                                      value={registrationData["parola"]} errors={errors} isPassword={true}/>
                        <TextInputBox handleChange={handleChange} name={"confirmaParola"} label={'Confirma parola'}
                                      value={registrationData["confirmaParola"]} errors={errors} isPassword={true}/>

                        <Button size={"lg"} disabled={loading} className={"m-2"}>
                            Conectare!
                        </Button>

                    </form>
                </div>
            </div>
            <div className="p-4 mt-4 rounded-2xl bg-light/70 shadow">
                <div className="z-10 flex flex-1 flex-col max-h-[20vh] w-full my-auto max-w-md">
                    <Button type={"button"} size={"lg"} disabled={loading} className={"m-2"}
                            onClick={() => navigate(PATHS[PAGES.HOME])}>
                        Inapoi la pagina principala
                    </Button>
                </div>
            </div>
        </PageWrap>
    )
}
Register.propTypes = {
    forRole: PropTypes.string.isRequired
}