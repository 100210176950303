import {useAuthProvider, useFetchProvider, useRoutesProvider} from "../../providers/provider-hooks.jsx";
import {toastOneError, toastOneWarn, updateFailureToast, updateSuccessToast} from "../../toast.js";
import {delay, findChangedPath, generateUUID, getQuizPlaceholder} from "../../utils.js";
import {QUIZ_TYPES, SLIDE_TYPES} from "../../pages/quizzes/quiz-creation.jsx";
import {useEffect, useRef, useState} from "react";
import {ECOTIC_ID} from "../../constants/config.js";
import {useNavigate} from "react-router-dom";
import {Slide, toast} from "react-toastify";
import {useParams} from "react-router";
import _ from "lodash";

export default function useQuizCreation(quizType) {
    const [selectedSlide, setSelectedSlide] = useState(null)
    const [oldFormData, setOldFormData] = useState({})
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({})

    useEffect(() => {
        console.log(selectedSlide)
    }, [selectedSlide]);

    const {
        getDefaultLesson, getDefaultSlide, uploadImage,
        postLesson, getLessonById, getDefaultOption,
    } = useFetchProvider()
    const errorsRef = useRef({slide: "", slides: "", options: ""})
    const {ROLES, PAGES, PATHS} = useRoutesProvider()
    const {authState} = useAuthProvider()

    const navigate = useNavigate()
    const {id} = useParams()

    function handleSlideDrag(result) {
        if (!result.destination) return;
        const reorderedQuestions = Array.from(formData.slides);
        const [movedQuestion] = reorderedQuestions.splice(result.source.index, 1);
        reorderedQuestions.splice(result.destination.index, 0, movedQuestion);
        setFormData({...formData, slides: reassignSlideNumbers(reorderedQuestions)});
    }

    async function renderLesson(lessonId) {
        errorsRef.current = {slide: "", slides: "", options: ""}
        const id = toast.loading(`${lessonId ? getQuizPlaceholder(quizType, 4) : "Pagina"} se incarca...`, {transition: Slide})
        if (lessonId) {
            try {
                await delay(1000)
                const lesson = {...await getLessonById(lessonId)}
                setFormData(lesson)
                return toast.dismiss(id)
            } catch (error) {
                console.error(error)
                updateFailureToast(error.message || `${getQuizPlaceholder(quizType, 4)} dorita nu se poate incerca. Veti fi redirectionat.`, id)
                await delay(1000)
                navigate(PATHS[PAGES.LESSON_QUIZ_CREATION_REDIRECT])
            }
        }
        try {
            await delay(1000)
            setFormData({...await getDefaultLesson(), slides: [], type: quizType})
            return toast.dismiss(id)
        } catch (error) {
            updateFailureToast(error.message || "Pagina nu s-a putut incarca.", id)
            console.error(error)
        }
    }

    function reassignSlideNumbers(slides) {
        return slides.map((question, index) => ({
            ...question,
            number: index,
        }));
    }

    async function addQuestionSlide() {
        try {
            const newSlides = [
                ...formData.slides,
                {
                    ...await getDefaultSlide(),
                    id: generateUUID(),
                    type: SLIDE_TYPES.QUESTION,
                    number: formData.slides.length,
                    timeLimit: 60,
                    options: [
                        {...await getDefaultOption(), correct: false},
                        {...await getDefaultOption(), correct: false},
                        {...await getDefaultOption(), correct: false},
                        {...await getDefaultOption(), correct: false},
                    ]
                }
            ];
            setFormData({...formData, slides: reassignSlideNumbers(newSlides)});
        } catch (e) {
            toastOneError("Nu s-a putut adauga slide-ul de tip intrebare. Contactati echipa de suport tehnic!")
        }
    }

    async function addVideoSlide() {
        try {
            const newSlides = [
                ...formData.slides,
                {
                    ...await getDefaultSlide(),
                    id: generateUUID(),
                    type: SLIDE_TYPES.VIDEO_PREVIEW,
                    number: formData.slides.length,
                }
            ];
            setFormData({...formData, slides: reassignSlideNumbers(newSlides)});
        } catch (e) {
            toastOneError("Nu s-a putut adauga slide-ul de tip video-prezentare. Contactati echipa de suport tehnic!")
        }
    }

    async function addInfoSlide() {
        try {
            const newSlides = [
                ...formData.slides,
                {
                    ...await getDefaultSlide(),
                    id: generateUUID(),
                    type: SLIDE_TYPES.INFO,
                    number: formData.slides.length,
                }
            ];
            setFormData({...formData, slides: reassignSlideNumbers(newSlides)});
        } catch (e) {
            toastOneError("Nu s-a putut adauga slide-ul de tip info. Contactati echipa de suport tehnic!")
        }
    }

    async function addImageUploadSlide() {
        try {
            const newSlides = [
                ...formData.slides,
                {
                    ...await getDefaultSlide(),
                    id: generateUUID(),
                    type: SLIDE_TYPES.IMAGE_UPLOAD,
                    number: formData.slides.length,
                }
            ];
            setFormData({...formData, slides: reassignSlideNumbers(newSlides)});
        } catch (e) {
            toastOneError("Nu s-a putut adauga slide-ul de tip upload imagine. Contactati echipa de suport tehnic!")
        }
    }

    async function duplicateSlide(id, idx) {
        const defaultSlide = {
            ...await getDefaultSlide(), id: generateUUID(),
        };
        const slideToDuplicate = formData.slides.find(slide => slide.id === id);
        const newSlides = [
            ...formData.slides.slice(0, idx + 1),
            slideToDuplicate.type !== SLIDE_TYPES.QUESTION
                ? {...slideToDuplicate, id: defaultSlide.id}
                : {
                    ...slideToDuplicate, id: defaultSlide.id, options: await Promise.all(
                        slideToDuplicate.options.map(async option => {
                            const defaultOption = {
                                ...await getDefaultOption(), id: generateUUID(),
                            };
                            return {...option, id: defaultOption.id};
                        })
                    )
                },
            ...formData.slides.slice(idx + 1),
        ];

        setFormData({...formData, slides: reassignSlideNumbers(newSlides)});
    }

    async function deleteSlide(id) {
        if (!id) throw new Error("Id param for the slide to be deleted was not provided!")
        if (typeof id !== "string") throw new Error("Id param for the slide to be deleted must be a string!")
        try {
            if (selectedSlide === id) setSelectedSlide(null)
            const newSlides = formData.slides.filter(slide => slide.id !== id);
            setFormData({...formData, slides: reassignSlideNumbers(newSlides)})
        } catch (error) {
            console.error(error)
            toastOneError("Acest slide nu a putut fi sters. Contactati echipa de suport tehnic.")
        }
    }

    async function addOptionsToSlide(id) {
        const updatedSlides = await Promise.all(
            formData.slides.map(async (slide) => {
                if (slide.id === id) {
                    const newOptions = [
                        ...slide.options,
                        {
                            ...await getDefaultOption(), id: generateUUID(),
                            correct: false
                        },
                        {
                            ...await getDefaultOption(), id: generateUUID(),
                            correct: false
                        },
                    ];
                    return {...slide, options: newOptions};
                }
                return slide;
            })
        );
        setFormData({...formData, slides: updatedSlides})
    }

    async function removeOptionsFromSlide(id) {
        try {
            const slideToUpdate = formData.slides.find(slide => slide.id === id);
            if (slideToUpdate && slideToUpdate.options.length > 4) {
                const updatedSlides = formData.slides.map((slide) => {
                    if (slide.id === id) {
                        const newOptions = slide.options.slice(0, -2);
                        return {...slide, options: newOptions};
                    }
                    return slide;
                });
                setFormData({...formData, slides: updatedSlides});
            }
        } catch (error) {
            console.error(error);
            toastOneError("Optiunile acestei intrebari nu au putut fi sterse. Contactati echipa de suport tehnic.");
        }
    }

    function selectSlide(id = null) {
        setSelectedSlide(id)
    }

    function changeLessonTitle(title) {
        setFormData((prevFormData) => ({...prevFormData, title: title}));
    }

    function changeLessonDescription(description) {
        setFormData((prevFormData) => ({...prevFormData, description: description}));
    }

    async function changeLessonImage(file) {
        const image = await uploadImage(file)
        setFormData((prevFormData) => ({...prevFormData, image: image}));
    }

    function changeSlideTitle(title) {
        const updatedSlides = formData.slides.map(slide =>
            slide.id === selectedSlide ? {...slide, text: title} : slide);
        setFormData({...formData, slides: updatedSlides});
    }

    function changeSlideDescription(description) {
        console.log("selectedSlide", selectedSlide)
        const updatedSlides = formData.slides.map(slide =>
            slide.id === selectedSlide ? {...slide, description: description} : slide);
        console.log("updatedSlides", updatedSlides)
        setFormData({...formData, slides: updatedSlides});
    }

    async function changeSlideImage(file) {
        const image = await uploadImage(file)
        const updatedSlides = formData.slides.map(slide =>
            slide.id === selectedSlide ? {...slide, image: image} : slide);
        setFormData({...formData, slides: updatedSlides});
    }

    async function changeOptionImage(optionId, file) {
        const image = await uploadImage(file)
        const updatedSlides = formData.slides.map(slide => {
            if (slide.id === selectedSlide) {
                const updatedOptions = slide.options.map(option => {
                        if (option.id === optionId) {
                            return {...option, text: null, image: image}
                        } else return option
                    }
                );
                return {...slide, options: updatedOptions};
            }
            return slide;
        });
        setFormData({...formData, slides: updatedSlides})
    }

    function removeOptionImage(optionId) {
        const updatedSlides = formData.slides.map(slide => {
            if (slide.id === selectedSlide) {
                const updatedOptions = slide.options.map(option => {
                        if (option.id === optionId) {
                            return {...option, text: null, image: null}
                        } else return option
                    }
                );
                return {...slide, options: updatedOptions};
            }
            return slide;
        });
        setFormData({...formData, slides: updatedSlides})
    }

    function changeOptionText(optionId, text) {
        const updatedSlides = formData.slides.map(slide => {
            if (slide.id === selectedSlide) {
                const updatedOptions = slide.options.map(option => {
                        if (option.id === optionId) {
                            return {...option, text: text, image: null}
                        } else return option
                    }
                );
                return {...slide, options: updatedOptions};
            }
            return slide;
        });
        setFormData({...formData, slides: updatedSlides})
    }

    function markCorrectOption(optionId) {
        const updatedSlides = formData.slides.map(slide => {
            if (slide.id === selectedSlide) {
                const updatedOptions = slide.options.map(option => {
                        if (option.id === optionId) {
                            return {...option, correct: !option.correct}
                        } else return option
                    }
                );
                return {...slide, options: updatedOptions};
            }
            return slide;
        });
        setFormData({...formData, slides: updatedSlides})
    }

    function checkAnyErrorForPath(path) {
        if (typeof path !== "string") return false
        for (const key in errors) {
            if (key.startsWith(path)) return true
        }
        return false
    }

    function retrieveErrorForPath(path) {
        if (typeof path !== "string") return ""
        for (const key in errors) {
            if (key === path) return errors[key].toString()
        }
        return ""
    }

    async function redirectToListing() {
        await delay(1000)
        switch (quizType) {
            case QUIZ_TYPES.LESSON:
                return navigate(PATHS[PAGES.LESSON_QUIZ_LISTING])
            case QUIZ_TYPES.HOMEWORK:
                return navigate(PATHS[PAGES.HOMEWORK_LISTING])
            case QUIZ_TYPES.PRACTICAL_ACTIVITY:
                return navigate(PATHS[PAGES.PRACTICAL_ACTIVITY_LISTING])
        }
    }

    async function saveQuiz() {
        const id = toast.loading(`${getQuizPlaceholder(quizType, 4)} se salveaza...`, {transition: Slide});
        errorsRef.current = {slide: "", slides: "", options: ""}
        if (authState.userInfo.role === ROLES.DBEST) formData.companyId = ECOTIC_ID
        try {
            await delay(1000);
            await postLesson(formData);
            updateSuccessToast("Operatiunea a fost efectuata cu succes.", id)
            await redirectToListing()
        } catch (error) {
            setErrors({})
            console.error(error);
            if (error.message || error.errors) {
                if (error.errors && Object.keys(error.errors).length > 0) setErrors(error.errors);
                if (error.message) updateFailureToast(error.message, id);
                return;
            }
            updateFailureToast(`${getQuizPlaceholder(quizType, 4)} nu a putut fi salvata.`, id);
        }
    }

    function displayGenericErrors(obj) {
        const slidesErrors = obj["slides"] || ""
        if (errorsRef.current.slides === "" && slidesErrors) {
            toastOneWarn(slidesErrors)
            errorsRef.current.slides = slidesErrors
        }
        if (formData.slides && Array.isArray(formData.slides) && formData.slides.length > 0 && selectedSlide) {
            const slide = formData.slides.find(slide => slide.id === selectedSlide)
            const slideIdx = formData.slides.findIndex(slide => slide.id === selectedSlide)
            const slideOptionsError = obj[`slides[${slideIdx}].options`] || ""
            const slideError = obj[`slides[${slideIdx}]`] || ""
            if (errorsRef.current.options === "" && slideOptionsError && slide.id === selectedSlide) {
                toastOneWarn(slideOptionsError)
                errorsRef.current.options = slideOptionsError
            }
            if (errorsRef.current.slide === "" && slideError && slide.id === selectedSlide) {
                toastOneWarn(slideError)
                errorsRef.current.slide = slideError
            }
        }
    }

    useEffect(() => {
        errorsRef.current = {slide: "", slides: "", options: ""}
    }, [selectedSlide]);

    useEffect(() => {
        displayGenericErrors(errors)
        const changedPath = findChangedPath(formData, oldFormData);
        if (changedPath) {
            const errorPath = Object.keys(errors).find(errorKey => errorKey === changedPath);
            const secondaryPath = /slides\[\d+]\.options/.test(changedPath) ? changedPath.substring(0, changedPath.indexOf(".options") + 8) : ""
            const ternaryPath = /slides\[\d+]/.test(changedPath) ? changedPath.match(/slides\[\d+]/)[0] : "";

            if (errorPath || secondaryPath || ternaryPath) {
                const newErrors = JSON.parse(JSON.stringify(errors));
                delete newErrors[errorPath];
                if (secondaryPath) delete newErrors[secondaryPath]
                if (ternaryPath) delete newErrors[ternaryPath]
                setErrors(newErrors);
            }
            setOldFormData(_.cloneDeep(formData));
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData, errors, selectedSlide]);

    useEffect(() => {
        renderLesson(id).then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        formData, selectedSlide, errors, setSelectedSlide, setFormData,
        changeSlideImage, addQuestionSlide, addVideoSlide, addInfoSlide,
        handleSlideDrag, duplicateSlide, deleteSlide, addOptionsToSlide,
        checkAnyErrorForPath, changeLessonTitle, changeLessonDescription,
        removeOptionsFromSlide, changeSlideTitle, changeSlideDescription,
        selectSlide, changeOptionText, changeOptionImage, removeOptionImage,
        markCorrectOption, saveQuiz, retrieveErrorForPath, changeLessonImage,
        addImageUploadSlide,
    }
}