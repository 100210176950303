import PropTypes from "prop-types";
import {cn} from "../../../utils.js";

export default function WrapWithBg({children, customStyles}) {

    return (
        <div className={"relative w-full h-full rounded-md border border-lime-700 overflow-x-hidden "}>
            <div className={"absolute inset-0 z-0"}>
                <img src="/quiz-bg.jpg" alt="imaginea de fundal a chestionarului"
                     className="w-full h-full object-cover rounded-md" loading="lazy"/>
            </div>
            <div className={"absolute w-full h-full z-10 bg-white/40 rounded-md p-2"}>
                <div className={cn("flex flex-col my-auto items-center w-full h-full pr-2 overflow-y-scroll", customStyles)}>
                    {children}
                </div>
            </div>
        </div>
    )
}
WrapWithBg.propTypes = {
    children: PropTypes.node.isRequired,
    customStyles: PropTypes.string,
}