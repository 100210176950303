import {QUIZ_TYPES} from "../../../../pages/quizzes/quiz-creation.jsx";
import {Fragment, useState, useEffect, useRef} from "react";
import {Button} from "../../../schadcn-ui/button.jsx";
import PropTypes from "prop-types";

export default function PreviewActions({
                                           addQuestionSlide, addVideoSlide, addInfoSlide, saveQuiz,
                                           isInPreview, setIsInPreview, addImageUploadSlide, quizType
                                       }) {
    const [loading, setLoading] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dialogRef = useRef(null);

    async function handleAction(action) {
        setLoading(true);
        await action();
        setIsDialogOpen(false)
        setLoading(false);
    }

    const handleClickOutside = (event) =>
        dialogRef.current && !dialogRef.current.contains(event.target) ? setIsDialogOpen(false) : null


    useEffect(() => {
        if (isDialogOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else document.removeEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [isDialogOpen]);

    return (
        <Fragment>
            {isInPreview ? (
                <Button onClick={() => setIsInPreview(false)}>Editare</Button>
            ) : (
                <Button onClick={() => setIsInPreview(true)}>Vizualizare</Button>
            )}
            {!isInPreview && (
                <Fragment>
                    <Button onClick={() => setIsDialogOpen(true)}>+ Slide</Button>
                    <Button onClick={() => handleAction(() => saveQuiz())}>Salvare</Button>
                </Fragment>
            )}
            {isDialogOpen && (
                <div
                    className="animate-fade animate-duration-200 cursor-default fixed left-0 -top-2 w-screen h-screen flex items-center justify-center z-50 bg-black/50 backdrop-blur-sm">
                    <div ref={dialogRef} className="bg-white flex flex-col space-y-4 p-4 rounded shadow-lg">
                        <Button onClick={() => handleAction(addInfoSlide)} disabled={loading}>Info</Button>
                        <Button onClick={() => handleAction(addVideoSlide)} disabled={loading}>Video</Button>
                        {[QUIZ_TYPES.HOMEWORK].includes(quizType) && (
                            <Button onClick={() => handleAction(addQuestionSlide)} disabled={loading}>Intrebare</Button>
                        )}
                        {[QUIZ_TYPES.PRACTICAL_ACTIVITY].includes(quizType) && (
                            <Button onClick={() => handleAction(addImageUploadSlide)} disabled={loading}>Upload Imagine</Button>
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    );
}

PreviewActions.propTypes = {
    quizType: PropTypes.string.isRequired,
    isInPreview: PropTypes.bool.isRequired,
    setIsInPreview: PropTypes.func.isRequired,
    addQuestionSlide: PropTypes.func.isRequired,
    addImageUploadSlide: PropTypes.func.isRequired,
    addVideoSlide: PropTypes.func.isRequired,
    addInfoSlide: PropTypes.func.isRequired,
    saveQuiz: PropTypes.func.isRequired,

};
