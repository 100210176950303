import {Popover, PopoverContent, PopoverTrigger} from "../../schadcn-ui/popover.jsx";
import {Calendar as CalendarIcon} from "lucide-react";
import Calendar from  "../../schadcn-ui/calendar.jsx"
import {cn} from "../../../utils.js";
import {ro} from "date-fns/locale";
import PropTypes from "prop-types";
import {format} from "date-fns";

export function DatePickerInput({name, label, value, handleChange, customStyles, dateFormat = "yyyy-MM-dd", disabled}) {

    return (
        <Popover>
            <PopoverTrigger asChild disabled={disabled} className={"disabled:cursor-not-allowed disabled:opacity-50"}>
                <button className={cn("min-h-[36.8px] w-full flex flex-col justify-center hover:bg-white focus:bg-white text-dark hover:text-dark text-left rounded-md")}>
                    <label htmlFor={name} className={cn(customStyles, "text-gray-400 w-full text-center font-bold")}>
                        {label}
                    </label>
                    <div className={"flex justify-center items-center w-full text-[16px]"}>
                        <CalendarIcon className="mr-2 h-4 w-4"/>
                        {value ? format(new Date(value), "PPP", {locale: ro}) : <span className={"font-bold text-gray-400"}>{label}</span>}
                    </div>
                </button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <Calendar mode="single" selected={value} initialFocus locale={ro}
                          onSelect={(date) => handleChange({target: {name: name, value: format(new Date(date), dateFormat)}})}/>
            </PopoverContent>
        </Popover>
    );
}

DatePickerInput.propTypes = {
    disabled: PropTypes.bool,
    dateFormat: PropTypes.string,
    customStyles: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
}
