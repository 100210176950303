import SearchInput from "../tables/search-input.jsx";
import SortButton from "../tables/sort-button.jsx";
import HeadCell from "../tables/head-cell.jsx";
import HeadRow from "../tables/head-row.jsx";
import {cn} from "../../../utils.js";
import PropTypes from "prop-types";

export default function TableHeaders({actionsDisabled, params, changeParams}) {

    return (
        <thead className={cn("font-bold bg-light sticky top-0 z-10")}>
        <HeadRow>
            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"codSiiirUnitate"}/>
                    <span className={"text-nowrap"}>Cod SIIIR</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Cod SIIIR..."}
                             name={`codSiiirUnitate`} value={params[`codSiiirUnitate`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"numeInstitutieInvatamant"}/>
                    <span className={"text-nowrap"}>Nume institutie invatamant</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Nume institutie invatamant..."}
                             name={`numeInstitutieInvatamant`} value={params[`numeInstitutieInvatamant`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"judet"}/>
                    <span className={"text-nowrap"}>Judet</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Judet..."}
                             name={`judet`} value={params[`judet`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"oras"}/>
                    <span className={"text-nowrap"}>Oras</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Oras..."}
                             name={`oras`} value={params[`oras`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"comuna"}/>
                    <span className={"text-nowrap"}>Comuna</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Comuna..."}
                             name={`comuna`} value={params[`comuna`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"sat"}/>
                    <span className={"text-nowrap"}>Sat</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Sat..."}
                             name={`sat`} value={params[`sat`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"strada"}/>
                    <span className={"text-nowrap"}>Strada</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Strada..."}
                             name={`strada`} value={params[`strada`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"numar"}/>
                    <span className={"text-nowrap"}>Numar</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Numar..."}
                             name={`numar`} value={params[`numar`] || ""}/>
            </HeadCell>

            <HeadCell colSpan={1}>
                <div className="flex items-center space-y-1 py-2">
                    <SortButton handleChange={changeParams} disabled={actionsDisabled}
                                value={params["sort"] || ""} name={"codPostal"}/>
                    <span className={"text-nowrap"}>Cod postal</span>
                </div>
                <SearchInput handleChange={changeParams} disabled={actionsDisabled}
                             placeholder={"Cod postal..."}
                             name={`codPostal`} value={params[`codPostal`] || ""}/>
            </HeadCell>
        </HeadRow>
        </thead>
    )
}
TableHeaders.propTypes = {
    actionsDisabled: PropTypes.bool.isRequired,
    params: PropTypes.object.isRequired,
    changeParams: PropTypes.func.isRequired
}