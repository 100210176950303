import FieldWrap from "../../../forms/wraps/field-wrap.jsx";
import InputWrap from "../../../forms/wraps/input-wrap.jsx";
import {getQuizPlaceholder} from "../../../../utils.js";
import {Button} from "../../../schadcn-ui/button.jsx";
import PropTypes from "prop-types";

export default function PresentationSlide({quizForm, quizAnswers, quizType, saveAndMoveToAvailableSlide}) {

    return (
        <div className={"w-full h-full flex flex-col items-center"}>
            <div className={"grid grid-cols-1 sm:grid-cols-2 h-full"}>
                <div className={"flex flex-col col-span-1 h-full justify-center"}>
                    <FieldWrap customStyles={"w-auto animate-fade"}>
                        <InputWrap customStyles={"w-auto p-4"}>
                            <span className={"font-semibold text-large text-center"}>{quizForm.title}</span>
                        </InputWrap>
                    </FieldWrap>

                    <FieldWrap customStyles={"w-auto animate-fade"}>
                        <InputWrap customStyles={"w-auto p-4"}>
                            <span className={"text-[16px]"}>{quizForm.description}</span>
                        </InputWrap>
                    </FieldWrap>
                </div>
                <div className={"col-span-1 flex flex-col h-full justify-start sm:justify-center"}>
                    <FieldWrap customStyles={"w-auto animate-fade"}>
                        <InputWrap customStyles={"w-auto p-0"}>
                            <img src={quizForm.image} alt={""} className={"rounded-md"}/>
                        </InputWrap>
                    </FieldWrap>
                </div>
                <div className={"flex w-full justify-center col-span-1 sm:col-span-2 p-2 animate-fade"}>
                    <Button className={" max-w-md"} onClick={() => saveAndMoveToAvailableSlide()}>
                        <span className={"text-wrap"}>
                            {quizAnswers.length === 0 ? `Incepe ${getQuizPlaceholder(quizType, 3)}` : `Continua ${getQuizPlaceholder(quizType, 3)}`}
                        </span>
                    </Button>
                </div>
            </div>

        </div>
    )
}
PresentationSlide.propTypes = {
    quizForm: PropTypes.object.isRequired,
    quizType: PropTypes.string.isRequired,
    quizAnswers: PropTypes.array.isRequired,
    saveAndMoveToAvailableSlide: PropTypes.func.isRequired,
}